import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export var state = {
  businesses: [],
  businessCountry: null,
  businessType: null,
  businessSubscriptionStatus: null,
  businessesStartDate: null,
  businessesEndDate: null,
  businessesListFiltersApplied: false,
  businessesSearchText: null,
  businessTypesList: [{
    value: 0,
    title: 'All'
  }, {
    value: 1,
    title: 'Farmer'
  }, {
    value: 2,
    title: 'Contractor'
  }],
  businessSubscriptionStatusTypes: [{
    value: 0,
    title: 'Trialing'
  }, {
    value: 1,
    title: 'Active'
  }, {
    value: 2,
    title: 'Unpaid'
  }, {
    value: 3,
    title: 'Cancellation Requested'
  }, {
    value: 4,
    title: 'Cancelled'
  }, {
    value: 5,
    title: 'Trial Expired'
  }],
  businessesDetails: {
    name: null,
    ownerName: null,
    type: null,
    address: null,
    teamMembers: {
      total: 0,
      active: 0,
      archived: 0
    },
    subscriptions: [],
    jobs: {
      active: 0,
      completed: 0,
      invoiced: 0,
      cancelled: 0,
      archived: 0,
      total: 0
    },
    connectedBusinesses: {
      total: 0,
      internal: 0,
      external: 0
    },
    activeInvoicingService: null,
    totalInvoicesCount: 0,
    totalFieldsCount: 0,
    totalVehiclesCount: 0,
    totalImplementsCount: 0,
    totalProductsCount: 0
  }
};
export var getters = {
  businesses: function businesses(state) {
    return state.businesses;
  },
  businessesStartDate: function businessesStartDate(state) {
    return state.businessesStartDate;
  },
  businessesEndDate: function businessesEndDate(state) {
    return state.businessesEndDate;
  },
  businessesListFiltersApplied: function businessesListFiltersApplied(state) {
    return state.businessesListFiltersApplied;
  },
  businessesSearchText: function businessesSearchText(state) {
    return state.businessesSearchText;
  },
  businessCountry: function businessCountry(state) {
    return state.businessCountry;
  },
  businessType: function businessType(state) {
    return state.businessType;
  },
  businessSubscriptionStatus: function businessSubscriptionStatus(state) {
    return state.businessSubscriptionStatus;
  },
  businessTypesList: function businessTypesList(state) {
    return state.businessTypesList;
  },
  businessSubscriptionStatusTypes: function businessSubscriptionStatusTypes(state) {
    return state.businessSubscriptionStatusTypes;
  },
  businessesDetails: function businessesDetails(state) {
    return state.businessesDetails;
  }
};
export var mutations = {
  setBusinessesList: function setBusinessesList(state, data) {
    data === null || data === void 0 ? void 0 : data.value.forEach(function (user) {
      return state.businesses.push(user);
    });
  },
  resetBusinessesFilter: function resetBusinessesFilter(state) {
    state.businessesStartDate = null;
    state.businessesEndDate = null;
    state.businessesListFiltersApplied = false;
    state.businessesSearchText = null;
  },
  setBusinessesDetails: function setBusinessesDetails(state, data) {
    if (data) {
      state.businessesDetails = data;
    }
  }
};