import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { listContainsItem } from "@/utils/helpers";
export var state = {
  contacts: [],
  account: {
    "type": null,
    "accountId": null,
    "accountName": null,
    "accessToken": null,
    "accessTokenExpiry": null,
    "refreshToken": null,
    "refreshTokenExpiry": null,
    "connectedOn": null
  },
  contactSearchText: '',
  customerLoader: true,
  integrationType: null,
  integrationsAuthenticationInProgress: false,
  //integration services
  quickbookAccessState: 0,
  quickBookAccess: {
    0: "NoAction",
    1: "NoQuickbookAccess",
    2: "QuickbookAccess"
  },
  xeroAccessState: 0,
  xeroAccess: {
    0: "NoAction",
    1: "NoXeroAccess",
    2: "XeroAccess"
  },
  sageAccessState: 0,
  sageAccess: {
    0: "NoAction",
    1: "NoSageAccess",
    2: "SageAccess"
  },
  //customer list
  intergrationHasData: false,
  fetchingintergrationDataProgress: false,
  loadingIntegration: false,
  externalContactIdToLink: null,
  enumImportStatus: {
    0: "Completed",
    1: "Pending",
    2: "Failed",
    3: "Declined"
  },
  loadedContacts: 0,
  allCustomersLoaded: false,
  loadingIntegrationBuffer: false,
  tempIntegratedContacts: [],
  showIntegrationPrompt: false
};
export var getters = {
  contacts: function contacts(state) {
    return state.contacts;
  },
  account: function account(state) {
    return state.account;
  },
  contactSearchText: function contactSearchText(state) {
    return state.contactSearchText;
  },
  customerLoader: function customerLoader(state) {
    return state.customerLoader;
  },
  integrationType: function integrationType(state) {
    return state.integrationType;
  },
  integrationsAuthenticationInProgress: function integrationsAuthenticationInProgress(state) {
    return state.integrationsAuthenticationInProgress;
  },
  quickBookAccess: function quickBookAccess(state) {
    return state.quickBookAccess;
  },
  quickbookAccessState: function quickbookAccessState(state) {
    return state.quickbookAccessState;
  },
  xeroAccessState: function xeroAccessState(state) {
    return state.xeroAccessState;
  },
  xeroAccess: function xeroAccess(state) {
    return state.xeroAccess;
  },
  sageAccessState: function sageAccessState(state) {
    return state.sageAccessState;
  },
  sageAccess: function sageAccess(state) {
    return state.sageAccess;
  },
  fetchingintergrationDataProgress: function fetchingintergrationDataProgress(state) {
    return state.fetchingintergrationDataProgress;
  },
  intergrationHasData: function intergrationHasData(state) {
    return state.intergrationHasData;
  },
  loadingIntegration: function loadingIntegration(state) {
    return state.loadingIntegration;
  },
  externalContactIdToLink: function externalContactIdToLink(state) {
    return state.externalContactIdToLink;
  },
  enumImportStatus: function enumImportStatus(state) {
    return state.enumImportStatus;
  },
  loadedContacts: function loadedContacts(state) {
    return state.loadedContacts;
  },
  allCustomersLoaded: function allCustomersLoaded(state) {
    return state.allCustomersLoaded;
  },
  loadingIntegrationBuffer: function loadingIntegrationBuffer(state) {
    return state.loadingIntegrationBuffer;
  },
  tempIntegratedContacts: function tempIntegratedContacts(state) {
    return state.tempIntegratedContacts;
  },
  showIntegrationPrompt: function showIntegrationPrompt(state) {
    return state.showIntegrationPrompt;
  }
};
export var mutations = {
  setExternalInvoicingServiceContacts: function setExternalInvoicingServiceContacts(state, contacts) {
    contacts.map(function (contactDto) {
      var contact = {
        "customer": contactDto.customer,
        "name": contactDto.name,
        "emailAddress": contactDto.emailAddress,
        "companyName": contactDto.companyName,
        "phoneNumber": contactDto.phoneNumber,
        "address": contactDto.address != null ? {
          "id": contactDto.address.id != null ? contactDto.address.id : "",
          "type": contactDto.address.type != null ? contactDto.address.type : "",
          "title": contactDto.address.title != null ? contactDto.address.title : "",
          "addressLine1": contactDto.address.addressLine1 != null ? contactDto.address.addressLine1 : "",
          "addressLine2": contactDto.address.addressLine2 != null ? contactDto.address.addressLine2 : "",
          "city": contactDto.address.city != null ? contactDto.address.city : "",
          "country": contactDto.address.country != null ? contactDto.address.country : "",
          "postalCode": contactDto.address.postalCode != null ? contactDto.address.postalCode : "",
          "coordinate": contactDto.address.coordinate
        } : contactDto.address,
        "id": contactDto.id != null ? contactDto.id : ""
      };
      if (state.contacts && state.contacts.length) {
        if (!listContainsItem(state.contacts, ['id'], contact.id)) {
          state.contacts.push(contact);
        }
      } else {
        state.contacts.push(contact);
      }
    });
  },
  setIntegrationAccount: function setIntegrationAccount(state, data) {
    state.account = {
      "type": data.type,
      "accountId": data.accountId,
      "accountName": data.accountName,
      "accessToken": data.accessToken,
      "accessTokenExpiry": data.accessTokenExpiry,
      "refreshToken": data.refreshToken,
      "refreshTokenExpiry": data.refreshTokenExpiry,
      "connectedOn": data.connectedOn
    };
  },
  setClearIntegrations: function setClearIntegrations(state) {
    state.contacts = [];
    state.account = {
      "type": null,
      "accountId": null,
      "accountName": null,
      "accessToken": null,
      "accessTokenExpiry": null,
      "refreshToken": null,
      "refreshTokenExpiry": null,
      "connectedOn": null
    };
    state.quickbookAccessState = 0;
    state.xeroAccessState = 0;
    state.sageAccessState = 0;
    state.loadingIntegration = false;
  }
};