import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import $ from "jquery";
import { clearDateTimePicker, notify, openOverlay, parseFloatDecimal } from "@/utils/helpers";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import { apiInvoices, routeAccount, routeInvoices, routeInvoicesAdd } from "@/utils/endpoints";
import router from "@/router/index";
import moment from "moment";
import invoicesComputedMixin from "@/components/invoices/invoices-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import { AccountTabType } from '@/enum/accountTabType';
import StringConstants from '@/plugins/stringConstants';
import { excelParser } from "@/utils/helpers/excel-parser-helpers";
import { ExcelExportModuleType } from "@/enum/excelExportModuleType";
import PaginationModule from "@/store/modules/pagination";
import LoadMoreItem from "@/components/shared/load-more-item.vue";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import { InvoiceEmailStatusType } from '@/enum/invoiceStatusType';
import { defaultDateFormat } from "@/utils/constants";
export default {
  name: "InvoicesListView",
  mixins: [permissionsMixin, scrollerMixin, invoicesComputedMixin, requestsCancellationMixin],
  components: {
    Button: Button,
    DeleteItemDialog: DeleteItemDialog,
    List: List,
    LoadMoreItem: LoadMoreItem,
    DatePicker: DatePicker
  },
  computed: {
    invoiceFilterEmailStatus: function invoiceFilterEmailStatus() {
      return InvoiceEmailStatusType;
    },
    invoiceFilterEmailStatusOptions: function invoiceFilterEmailStatusOptions() {
      return ['All'].concat(_toConsumableArray(Object.keys(InvoiceEmailStatusType).filter(function (status) {
        return isNaN(Number(status));
      })));
    },
    datesAppliedOnFilter: function datesAppliedOnFilter() {
      if (!this.invoicesListFilterStartDate || !this.invoicesListFilterEndDate) return [];
      return [new Date(this.invoicesListFilterStartDate), new Date(this.invoicesListFilterEndDate)];
    }
  },
  data: function data() {
    return {
      deleteDialog: false,
      deleteInvoiceId: "",
      dateInput: {
        placeholder: StringConstants.selectDatePlaceholder
      },
      format: defaultDateFormat,
      sameDateFormat: {
        from: defaultDateFormat,
        to: defaultDateFormat
      },
      deleteInvoice: false,
      showExportDialog: false
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.isPageRelated) {
      this.clearAndResetInvoicesPagingStates();
    }
    if (this.$store.hasModule(this.$stringConstants('invoicesListFilterCustomersPaginationModule'))) {
      this.$store.unregisterModule(this.$stringConstants('invoicesListFilterCustomersPaginationModule'));
    }
  },
  created: function created() {
    if (!this.$store.hasModule(this.$stringConstants('invoicesListFilterCustomersPaginationModule'))) {
      this.$store.registerModule(this.$stringConstants('invoicesListFilterCustomersPaginationModule'), PaginationModule);
    }
  },
  mounted: function mounted() {
    if (!this.isPageRelated || this.invoicesList.length === 0) {
      this.invoicesList = [];
      this.invoiceRefs = [];
      this.clearInvoiceFilterOptions();
      this.invoiceJobsPreviewLoader = false;
      this.invoiceFilterStatusSelected = this.invoiceFilterStatus[0].value;
      this.invoiceFilterEmailStatusSelected = this.invoiceFilterEmailStatusOptions[0];
      this.clearAndResetSearchFilters();
      this.invoicesListFilterCustomerValue = null;
      this.invoicesListFilterCustomersList = [];
      this.clearInvoicesDateFilter();
      this.setFilterOptionsAndGetInvoices();
    }
    var dInputStartDate = document.getElementsByClassName("vdpr-datepicker__calendar-input-date-elem")[0];
    if (dInputStartDate) {
      dInputStartDate.placeholder = "DD/MM/YYYY";
    }
    var dInputEndDate = document.getElementsByClassName("vdpr-datepicker__calendar-input-date-elem")[1];
    if (dInputEndDate) {
      dInputEndDate.placeholder = "DD/MM/YYYY";
    }
    var dInputStartTime = document.getElementsByClassName("vdpr-datepicker__calendar-input-time-elem")[0];
    if (dInputStartTime) {
      dInputStartTime.placeholder = "HH:MM";
    }
    var dInputEndTime = document.getElementsByClassName("vdpr-datepicker__calendar-input-time-elem")[1];
    if (dInputEndTime) {
      dInputEndTime.placeholder = "HH:MM";
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearInvoiceDetailsState: "setClearInvoiceData",
    clearInvoiceFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    openAddInvoice: function openAddInvoice() {
      var _this = this;
      this.$store.state.scrollToItem = null;
      this.$store.dispatch("getInvoiceConfiguration").then(function (res) {
        var _a, _b, _c;
        if (((_c = (_b = (_a = res === null || res === void 0 ? void 0 : res.businessProfile) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.salesTaxRates) === null || _c === void 0 ? void 0 : _c.length) === 0) {
          notify(_this.$stringConstants('invoiceNoTaxRatesWarning'), 'fail');
          router.push(routeAccount);
          setTimeout(function () {
            _this.accountTab = AccountTabType.Business;
          }, ConstantValues.defaultTabSelectionTimeout);
          setTimeout(function () {
            document.getElementById('account-business').scrollBy(0, 700);
          }, ConstantValues.defaultScrollToPositionTimeout);
        } else {
          _this.clearInvoiceDetailsState();
          _this.$store.state.jobsInvoicesModule.map = null;
          router.push(routeInvoicesAdd);
        }
      });
    },
    applySearchInvoicesFilter: function applySearchInvoicesFilter() {
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
      this.invoicesList = [];
      this.filtersApplied = true;
      this.setFilterOptionsAndGetInvoices();
    },
    promtDeleteInvoice: function promtDeleteInvoice(invoiceId) {
      this.deleteDialog = true;
      this.deleteInvoiceId = invoiceId;
    },
    proceedInvoiceDeletion: function proceedInvoiceDeletion() {
      this.deleteDialog = false;
      this.$store.dispatch("deleteInvoice", this.deleteInvoiceId);
    },
    cancelInvoiceDeletion: function cancelInvoiceDeletion() {
      this.deleteDialog = false;
      this.deleteInvoiceId = "";
    },
    clearAndResetInvoicesPagingStates: function clearAndResetInvoicesPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.invoicesModule.invoicesList = [];
      this.clearInvoiceDetailsState();
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
      this.filtersApplied = false;
    },
    getLocalFormat: function getLocalFormat(date) {
      var formattedDate = moment.utc(date).local().format("DD MMM YYYY");
      return formattedDate;
    },
    openInvoiceDetails: function openInvoiceDetails(invoice) {
      this.$store.state.jobsInvoicesModule.map = null;
      this.$store.dispatch("getInvoiceDetails", invoice.id);
      this.saveListItemID(invoice.id);
      router.push(routeInvoices + "/" + invoice.id);
      this.invoiceSelectedTab = 3;
    },
    filterInvoicesByStatus: function filterInvoicesByStatus() {
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
      this.invoicesList = [];
      this.filtersApplied = true;
      this.setFilterOptionsAndGetInvoices();
    },
    filterInvoicesByEmailStatus: function filterInvoicesByEmailStatus(value) {
      this.invoiceFilterEmailStatusSelected = value;
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
      this.invoicesList = [];
      this.filtersApplied = true;
      this.setFilterOptionsAndGetInvoices();
    },
    openJobsPreview: function openJobsPreview(jobIds) {
      openOverlay("invoiceJobsPreviewFlyout");
      this.$store.dispatch("getJobsListForPreview", jobIds.join());
    },
    getInvoiceCost: function getInvoiceCost(invoiceCost) {
      if (invoiceCost) {
        return parseFloatDecimal(invoiceCost);
      }
      return "N/A";
    },
    resetPagingForModule: function resetPagingForModule() {
      var $state = this.$store.state.invoicesModule;
      $state.offset = ConstantValues.defaultPageOffsetSize;
      $state.limit = ConstantValues.defaultPageLimitSize;
      $state.listCount = ConstantValues.defaultPageListCount;
      $state.noData = false;
      this.resetPaging();
    },
    setFilterOptionsAndGetInvoices: function setFilterOptionsAndGetInvoices() {
      var _this2 = this;
      var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      this.$store.dispatch('fnSetInvoicesFilter', filters).then(function () {
        _this2.$store.dispatch('getInvoicesList');
        if (_this2.invoiceSearchText == "") {
          _this2.filtersApplied = false;
        }
      });
    },
    saveInvoicesDataToExcel: function saveInvoicesDataToExcel() {
      this.$store.commit('removeIdsFromExportableData');
      excelParser().exportDataFromJSON(this.exportData, null, ConstantValues.defaultListExportFormat);
    },
    getMoreInvoicesForExport: function getMoreInvoicesForExport() {
      var _this3 = this;
      this.$store.dispatch("getDataListForExport", {
        apiUrl: apiInvoices,
        moduleType: ExcelExportModuleType.Invoices
      }).then(function () {
        if (_this3.exportDataLoaded < _this3.exportDataTotal) {
          _this3.getMoreInvoicesForExport();
        } else {
          if (_this3.exportData.length > 0) {
            _this3.showExportDialog = false;
            _this3.saveInvoicesDataToExcel();
          }
        }
      });
    },
    getInvoicesDataForDownload: function getInvoicesDataForDownload() {
      var _this4 = this;
      this.$store.dispatch("setExportDataParameterFilters", [{
        searchText: this.invoiceSearchText
      }, {
        statuses: this.invoiceFilterStatusSelected
      }, {
        customerId: this.invoicesListFilterCustomerValue
      }, {
        start: this.invoicesListFilterStartDate
      }, {
        end: this.invoicesListFilterEndDate
      }]).then(function () {
        _this4.$store.dispatch("getDataListForExport", {
          apiUrl: apiInvoices,
          moduleType: ExcelExportModuleType.Invoices
        }).then(function () {
          _this4.getMoreInvoicesForExport();
        });
      });
    },
    initiateInvoicesExport: function initiateInvoicesExport() {
      this.$store.commit('resetExportData');
      this.clearInvoiceFilterOptions();
      this.resetPaging();
      this.showExportDialog = true;
      this.getInvoicesDataForDownload();
    },
    applyInvoicesListCustomersFilterSearchTimer: function applyInvoicesListCustomersFilterSearchTimer() {
      var _this5 = this;
      if (this.searchTimerCustomersFilter) {
        clearTimeout(this.searchTimerCustomersFilter);
        this.searchTimerCustomersFilter = null;
      }
      this.searchTimerCustomersFilter = setTimeout(function () {
        _this5.searchCustomersByText();
      }, ConstantValues.defaultDropdownSearchTimeout);
    },
    searchCustomersByText: function searchCustomersByText() {
      this.invoicesListFilterCustomersList = [];
      this.clearInvoiceFilterOptions();
      this.appendMoreCustomers();
    },
    appendMoreCustomers: function appendMoreCustomers() {
      var _this6 = this;
      var paginationModule = this.$stringConstants('invoicesListFilterCustomersPaginationModule');
      this.$store.dispatch('setCustomersForInvoicesSearchFilters');
      this.$store.dispatch('getCustomersForInvoicesSearch').then(function (response) {
        return _this6.$store.dispatch("".concat(paginationModule, "/updateStates"), response);
      });
    },
    getInvoicesByCustomer: function getInvoicesByCustomer() {
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
      this.invoicesList = [];
      this.filtersApplied = true;
      this.setFilterOptionsAndGetInvoices();
    },
    filterInvoicesByDateRange: function filterInvoicesByDateRange(start, end) {
      this.invoicesListFilterStartDate = moment(start).utc().format();
      this.invoicesListFilterEndDate = moment(end).utc().format();
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
      this.invoicesList = [];
      this.filtersApplied = true;
      this.setFilterOptionsAndGetInvoices();
    },
    clearInvoicesDateFilter: function clearInvoicesDateFilter() {
      $(".vdpr-datepicker input[type=text]").first().val("");
      clearDateTimePicker(this.$refs.datePicker.$el);
      this.invoicesListFilterStartDate = null;
      this.invoicesListFilterStartDateLocal = null;
      this.invoicesListFilterEndDate = null;
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
      this.invoicesList = [];
      this.filtersApplied = true;
      this.setFilterOptionsAndGetInvoices();
    },
    clearAndResetSearchFilters: function clearAndResetSearchFilters() {
      this.invoiceSearchText = '';
      this.applySearchInvoicesFilter();
    },
    saveListItemID: function saveListItemID(id) {
      this.$store.state.scrollToItem = id;
    }
  })
};