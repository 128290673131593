import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import { Address } from "@/data/models/Address";
export var state = {
  farmersFilterList: [],
  pendingFarmersList: [],
  farmersLoader: false,
  farmerSearchText: '',
  farmerFilterType: 0,
  farmerType: null,
  addFarmerLoader: false,
  farmerDetails: {
    id: null,
    name: null,
    customer: null,
    emailAddress: null,
    companyName: null,
    address: new Address(),
    phoneNumber: null
  },
  farmerConReqList: [],
  farmerSendReqList: [],
  isShowConReq: true,
  updateFormEnabled: false,
  integrationContactSearchText: '',
  importIntegratedCustomerLoader: false,
  customerLoaderImport: true,
  fetchingintergrationDataProgressCustomer: false,
  contactsIntegration: [],
  loadedContactsCustomer: 0,
  intergrationHasDataCustomer: false,
  allIntegrationCustomersLoaded: false,
  tempIntegratedCustomers: [],
  integratedCustomerImportInProgress: false,
  farmerStatus: 0
};
export var getters = {
  farmersLoader: function farmersLoader(state) {
    return state.farmersLoader;
  },
  farmersFilterList: function farmersFilterList(state) {
    return state.farmersFilterList;
  },
  pendingFarmersList: function pendingFarmersList(state) {
    return state.pendingFarmersList;
  },
  farmerSearchText: function farmerSearchText(state) {
    return state.farmerSearchText;
  },
  farmerDetails: function farmerDetails(state) {
    return state.farmerDetails;
  },
  farmerFilterType: function farmerFilterType(state) {
    return state.farmerFilterType;
  },
  addFarmerLoader: function addFarmerLoader(state) {
    return state.addFarmerLoader;
  },
  farmerType: function farmerType(state) {
    return state.farmerType;
  },
  farmerConReqList: function farmerConReqList(state) {
    return state.farmerConReqList;
  },
  farmerSendReqList: function farmerSendReqList(state) {
    return state.farmerSendReqList;
  },
  isShowConReq: function isShowConReq(state) {
    return state.isShowConReq;
  },
  updateFormEnabled: function updateFormEnabled(state) {
    return state.updateFormEnabled;
  },
  integrationContactSearchText: function integrationContactSearchText(state) {
    return state.integrationContactSearchText;
  },
  importIntegratedCustomerLoader: function importIntegratedCustomerLoader(state) {
    return state.importIntegratedCustomerLoader;
  },
  customerLoaderImport: function customerLoaderImport(state) {
    return state.customerLoaderImport;
  },
  fetchingintergrationDataProgressCustomer: function fetchingintergrationDataProgressCustomer(state) {
    return state.fetchingintergrationDataProgressCustomer;
  },
  contactsIntegration: function contactsIntegration(state) {
    return state.contactsIntegration;
  },
  loadedContactsCustomer: function loadedContactsCustomer(state) {
    return state.loadedContactsCustomer;
  },
  intergrationHasDataCustomer: function intergrationHasDataCustomer(state) {
    return state.intergrationHasDataCustomer;
  },
  allIntegrationCustomersLoaded: function allIntegrationCustomersLoaded(state) {
    return state.allIntegrationCustomersLoaded;
  },
  tempIntegratedCustomers: function tempIntegratedCustomers(state) {
    return state.tempIntegratedCustomers;
  },
  integratedCustomerImportInProgress: function integratedCustomerImportInProgress(state) {
    return state.integratedCustomerImportInProgress;
  },
  farmerStatus: function farmerStatus(state) {
    return state.farmerStatus;
  }
};
export var mutations = {
  setFarmersFilteredList: function setFarmersFilteredList(state, data) {
    data.map(function (data) {
      return state.farmersFilterList.push(data);
    });
  },
  setPendingFarmersFilteredList: function setPendingFarmersFilteredList(state, data) {
    data.map(function (x) {
      var farmer = {
        "code": x.code,
        "inviterName": x.inviterName,
        "inviteeFirstName": x.inviteeFirstName,
        "inviteeLastName": x.inviteeLastName,
        "inviteeEmail": x.inviteeEmail,
        "inviteeRole": x.inviteeRole,
        "timestamp": x.timestamp,
        "expiry": x.expiry
      };
      state.pendingFarmersList.push(farmer);
    });
  },
  resetFarmerDetails: function resetFarmerDetails(state) {
    state.farmerDetails.id = null;
    state.farmerDetails.name = null;
    state.farmerDetails.customer = null;
    state.farmerDetails.emailAddress = null;
    state.farmerDetails.companyName = null;
    state.farmerDetails.phoneNumber = null;
    state.farmerDetails.address = new Address();
  },
  setFarmerDetails: function setFarmerDetails(state, data) {
    state.farmerDetails['id'] = data.id;
    state.farmerDetails['name'] = data.name;
    state.farmerDetails['customer'] = data.customer;
    state.farmerDetails['emailAddress'] = data.emailAddress;
    state.farmerDetails['companyName'] = data.companyName;
    state.farmerDetails['phoneNumber'] = data.phoneNumber;
    if (data.address != null) {
      state.farmerDetails['address'] = data.address;
    }
  },
  setFarmerConReqList: function setFarmerConReqList(state, data) {
    state.farmerConReqList = data;
  },
  setFarmerSendReqList: function setFarmerSendReqList(state, data) {
    state.farmerSendReqList = data;
  },
  setIntegrationContacts: function setIntegrationContacts(state, contacts) {
    contacts.map(function (contactDto) {
      var contact = {
        "customer": contactDto.customer,
        "name": contactDto.name,
        "emailAddress": contactDto.emailAddress,
        "companyName": contactDto.companyName,
        "phoneNumber": contactDto.phoneNumber,
        "address": contactDto.address != null ? {
          "id": contactDto.address.id != null ? contactDto.address.id : "",
          "type": contactDto.address.type != null ? contactDto.address.type : "",
          "title": contactDto.address.title != null ? contactDto.address.title : "",
          "addressLine1": contactDto.address.addressLine1 != null ? contactDto.address.addressLine1 : "",
          "addressLine2": contactDto.address.addressLine2 != null ? contactDto.address.addressLine2 : "",
          "city": contactDto.address.city != null ? contactDto.address.city : "",
          "country": contactDto.address.country != null ? contactDto.address.country : "",
          "postalCode": contactDto.address.postalCode != null ? contactDto.address.postalCode : "",
          "coordinate": contactDto.address.coordinate
        } : contactDto.address,
        "id": contactDto.id != null ? contactDto.id : ""
      };
      state.contactsIntegration.push(contact);
    });
  },
  updateProfileInFarmersList: function updateProfileInFarmersList(state, profile) {
    var profileIndex = state.farmersFilterList.findIndex(function (farmer) {
      return farmer.id === profile.id;
    });
    if (profileIndex > -1) {
      state.farmersFilterList.splice(profileIndex, 1, profile);
    } else {
      state.farmersFilterList.push(profile);
    }
  },
  clearFarmersList: function clearFarmersList(state) {
    state.farmersFilterList = [];
  }
};