import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import { JobInvoicingStatusType } from '@/enum/jobInvoicingStatusType';
import { UserRoleType } from '@/enum/userRoleType';
import ConstantValues from '@/plugins/constantValues';
import { isScheduledQuery } from '@/utils/constants';
export var state = {
  teamJobsList: [],
  teamJobsListSearchText: '',
  teamJobsListFilterStartDate: null,
  teamJobsListFilterStartDateLocal: null,
  teamJobsListFilterAssignedUsers: null,
  teamJobsListFilterEndDate: null,
  teamJobsFilterStatusSelected: null,
  teamJobsFiltersStatusList: [{
    title: 'All',
    value: ''
  }, {
    title: 'Pending',
    value: '0'
  }, {
    title: 'Scheduled',
    value: "1&".concat(isScheduledQuery, "=true")
  }, {
    title: 'Unscheduled',
    value: "1&".concat(isScheduledQuery, "=false")
  }, {
    title: 'In-progress',
    value: '2'
  }, {
    title: 'Awaiting completion',
    value: '3'
  }, {
    title: 'Partly completed',
    value: '4'
  }, {
    title: 'Completed',
    value: '5'
  }, {
    title: 'Cancelled',
    value: '6'
  }],
  teamJobsFiltersInvoiceStatusList: [{
    title: 'All',
    value: null
  }, {
    title: 'Pending',
    value: '&InvoicingStatus=' + JobInvoicingStatusType.Pending
  }, {
    title: 'Approved',
    value: '&InvoicingStatus=' + JobInvoicingStatusType.Approved
  }, {
    title: 'Invoiced',
    value: '&InvoicingStatus=' + JobInvoicingStatusType.Invoiced
  }],
  teamJobsFilterCustomersList: [],
  teamJobsFilterOperationsList: [],
  teamJobsFilterTeamMembersList: [],
  teamJobsFilterCustomerSearchText: '',
  teamJobsFilterOperationSearchText: '',
  teamJobsFilterTeamMemberSearchText: '',
  teamJobsFilterFieldsSearchText: '',
  teamJobsFilterVehiclesSearchText: '',
  teamJobsFilterImplementsSearchText: '',
  teamJobsFilterProductsSearchText: '',
  teamJobsFilterInvoiceStatusValue: null,
  teamJobsFilterCustomerValue: null,
  teamJobsFilterOperationValue: null,
  teamJobsFilterTeamMemberValue: null,
  teamJobsFilterFieldValue: null,
  teamJobsFilterVehicleValue: null,
  teamJobsFilterImplementValue: null,
  teamJobsFilterProductValue: null,
  teamJobsDateFilterApplied: false,
  teamJobsCustomersDropdownLoader: false,
  teamJobsCustomersDropdownTotalCount: 0,
  teamJobsTeamMembersDropdownLoader: false,
  teamJobsFieldsDropdownLoader: false,
  teamJobsVehiclesDropdownLoader: false,
  teamJobsImplementsDropdownLoader: false,
  teamJobsTeamMembersDropdownTotalCount: false,
  teamJobsFilterCustomersListSize: 0,
  teamJobsFilterTeammembersListSize: 0,
  teamJobsFilterFieldsList: [],
  teamJobsFilterFieldsListSize: 0,
  teamJobsFilterVehiclesList: [],
  teamJobsFilterVehiclesListSize: 0,
  teamJobsFilterImplementsList: [],
  teamJobsFilterImplementsListSize: 0,
  teamJobsFilterProductsList: [],
  teamJobsFilterProductsListSize: 0,
  routedFromJobDetails: false,
  calendarViewJobs: [],
  calendarViewJobEvents: [],
  jobViewType: 'List',
  focusMonth: '',
  calendarStartDate: '',
  calendarEndDate: ''
};
export var getters = {
  teamJobsList: function teamJobsList(state) {
    return state.teamJobsList;
  },
  teamJobsListSearchText: function teamJobsListSearchText(state) {
    return state.teamJobsListSearchText;
  },
  teamJobsListFilterStartDate: function teamJobsListFilterStartDate(state) {
    return state.teamJobsListFilterStartDate;
  },
  teamJobsListFilterStartDateLocal: function teamJobsListFilterStartDateLocal(state) {
    return state.teamJobsListFilterStartDateLocal;
  },
  teamJobsListFilterEndDate: function teamJobsListFilterEndDate(state) {
    return state.teamJobsListFilterEndDate;
  },
  teamJobsFiltersStatusList: function teamJobsFiltersStatusList(state) {
    return state.teamJobsFiltersStatusList;
  },
  teamJobsFilterStatusSelected: function teamJobsFilterStatusSelected(state) {
    return state.teamJobsFilterStatusSelected;
  },
  teamJobsDateFilterApplied: function teamJobsDateFilterApplied(state) {
    return state.teamJobsDateFilterApplied;
  },
  teamJobsListFilterAssignedUsers: function teamJobsListFilterAssignedUsers(state) {
    return state.teamJobsListFilterAssignedUsers;
  },
  jobInvoiceStatus: function jobInvoiceStatus(state) {
    return state.jobInvoiceStatus;
  },
  teamJobsFiltersInvoiceStatusList: function teamJobsFiltersInvoiceStatusList(state) {
    return state.teamJobsFiltersInvoiceStatusList;
  },
  teamJobsFilterCustomersList: function teamJobsFilterCustomersList(state) {
    return state.teamJobsFilterCustomersList;
  },
  teamJobsFilterOperationsList: function teamJobsFilterOperationsList(state) {
    return state.teamJobsFilterOperationsList;
  },
  teamJobsFilterTeamMembersList: function teamJobsFilterTeamMembersList(state) {
    return state.teamJobsFilterTeamMembersList;
  },
  teamJobsFilterFieldsList: function teamJobsFilterFieldsList(state) {
    return state.teamJobsFilterFieldsList;
  },
  teamJobsFilterVehiclesList: function teamJobsFilterVehiclesList(state) {
    return state.teamJobsFilterVehiclesList;
  },
  teamJobsFilterImplementsList: function teamJobsFilterImplementsList(state) {
    return state.teamJobsFilterImplementsList;
  },
  teamJobsFilterProductsList: function teamJobsFilterProductsList(state) {
    return state.teamJobsFilterProductsList;
  },
  teamJobsFilterInvoiceStatusValue: function teamJobsFilterInvoiceStatusValue(state) {
    return state.teamJobsFilterInvoiceStatusValue;
  },
  teamJobsFilterCustomerValue: function teamJobsFilterCustomerValue(state) {
    return state.teamJobsFilterCustomerValue;
  },
  teamJobsFilterOperationValue: function teamJobsFilterOperationValue(state) {
    return state.teamJobsFilterOperationValue;
  },
  teamJobsFilterTeamMemberValue: function teamJobsFilterTeamMemberValue(state) {
    return state.teamJobsFilterTeamMemberValue;
  },
  teamJobsFilterFieldValue: function teamJobsFilterFieldValue(state) {
    return state.teamJobsFilterFieldValue;
  },
  teamJobsFilterVehicleValue: function teamJobsFilterVehicleValue(state) {
    return state.teamJobsFilterVehicleValue;
  },
  teamJobsFilterImplementValue: function teamJobsFilterImplementValue(state) {
    return state.teamJobsFilterImplementValue;
  },
  teamJobsFilterProductValue: function teamJobsFilterProductValue(state) {
    return state.teamJobsFilterProductValue;
  },
  teamJobsFilterCustomerSearchText: function teamJobsFilterCustomerSearchText(state) {
    return state.teamJobsFilterCustomerSearchText;
  },
  teamJobsFilterOperationSearchText: function teamJobsFilterOperationSearchText(state) {
    return state.teamJobsFilterOperationSearchText;
  },
  teamJobsFilterTeamMemberSearchText: function teamJobsFilterTeamMemberSearchText(state) {
    return state.teamJobsFilterTeamMemberSearchText;
  },
  teamJobsFilterFieldsSearchText: function teamJobsFilterFieldsSearchText(state) {
    return state.teamJobsFilterFieldsSearchText;
  },
  teamJobsFilterVehiclesSearchText: function teamJobsFilterVehiclesSearchText(state) {
    return state.teamJobsFilterVehiclesSearchText;
  },
  teamJobsFilterImplementsSearchText: function teamJobsFilterImplementsSearchText(state) {
    return state.teamJobsFilterImplementsSearchText;
  },
  teamJobsFilterProductsSearchText: function teamJobsFilterProductsSearchText(state) {
    return state.teamJobsFilterProductsSearchText;
  },
  teamJobsCustomersDropdownLoader: function teamJobsCustomersDropdownLoader(state) {
    return state.teamJobsCustomersDropdownLoader;
  },
  teamJobsTeamMembersDropdownLoader: function teamJobsTeamMembersDropdownLoader(state) {
    return state.teamJobsTeamMembersDropdownLoader;
  },
  teamJobsFieldsDropdownLoader: function teamJobsFieldsDropdownLoader(state) {
    return state.teamJobsFieldsDropdownLoader;
  },
  teamJobsVehiclesDropdownLoader: function teamJobsVehiclesDropdownLoader(state) {
    return state.teamJobsVehiclesDropdownLoader;
  },
  teamJobsImplementsDropdownLoader: function teamJobsImplementsDropdownLoader(state) {
    return state.teamJobsImplementsDropdownLoader;
  },
  teamJobsProductsDropdownLoader: function teamJobsProductsDropdownLoader(state) {
    return state.teamJobsProductsDropdownLoader;
  },
  teamJobsFilterProductsListSize: function teamJobsFilterProductsListSize(state) {
    return state.teamJobsFilterProductsListSize;
  },
  teamJobsFilterImplementsListSize: function teamJobsFilterImplementsListSize(state) {
    return state.teamJobsFilterImplementsListSize;
  },
  teamJobsFilterCustomersListSize: function teamJobsFilterCustomersListSize(state) {
    return state.teamJobsFilterCustomersListSize;
  },
  teamJobsFilterTeammembersListSize: function teamJobsFilterTeammembersListSize(state) {
    return state.teamJobsFilterTeammembersListSize;
  },
  teamJobsFilterFieldsListSize: function teamJobsFilterFieldsListSize(state) {
    return state.teamJobsFilterFieldsListSize;
  },
  teamJobsFilterVehiclesListSize: function teamJobsFilterVehiclesListSize(state) {
    return state.teamJobsFilterVehiclesListSize;
  },
  routedFromJobDetails: function routedFromJobDetails(state) {
    return state.routedFromJobDetails;
  },
  calendarViewJobs: function calendarViewJobs(state) {
    return state.calendarViewJobs;
  },
  calendarViewJobEvents: function calendarViewJobEvents(state) {
    return state.calendarViewJobEvents;
  },
  jobViewType: function jobViewType(state) {
    return state.jobViewType;
  },
  focusMonth: function focusMonth(state) {
    return state.focusMonth;
  },
  calendarStartDate: function calendarStartDate(state) {
    return state.calendarStartDate;
  },
  calendarEndDate: function calendarEndDate(state) {
    return state.calendarEndDate;
  }
};
export var mutations = {
  setJobsFilteredList: function setJobsFilteredList(state, data) {
    data.map(function (data) {
      return state.teamJobsList.push(data);
    });
  },
  setCustomersListForJobsSearch: function setCustomersListForJobsSearch(state, data) {
    if (state.teamJobsFilterCustomersList.length == 0) {
      if (parseInt(localStorage.getItem('userRole')) === UserRoleType.Manager) {
        state.teamJobsFilterCustomersList.push({
          name: localStorage.getItem('fullOwnerName') ? localStorage.getItem('fullOwnerName') + ' (owner)' : 'Owner',
          id: localStorage.getItem(ConstantValues.ownerId)
        });
      } else {
        state.teamJobsFilterCustomersList.push({
          name: localStorage.getItem('fullOwnerBusinessName') ? localStorage.getItem('fullOwnerBusinessName') + ' (You)' : 'You',
          id: localStorage.getItem(ConstantValues.ownerId)
        });
      }
    }
    data.map(function (customer) {
      if (customer) {
        var member = {
          name: customer.businessProfile.title ? customer.businessProfile.title : customer.firstName + (customer.lastName ? ' ' + customer.lastName : ''),
          id: customer.id
        };
        state.teamJobsFilterCustomersList.push(member);
      }
    });
  },
  setCustomersListSizeForJobsSearch: function setCustomersListSizeForJobsSearch(state, data) {
    state.teamJobsFilterCustomersListSize = data;
  },
  setTeammembersListForJobsSearch: function setTeammembersListForJobsSearch(state, data) {
    if (state.teamJobsFilterTeamMembersList.length == 0) {
      if (localStorage.getItem('userRole') == '2') {
        state.teamJobsFilterTeamMembersList.push({
          name: localStorage.getItem('fullOwnerName') ? localStorage.getItem('fullOwnerName') + ' (owner)' : 'Owner',
          id: localStorage.getItem(ConstantValues.ownerId)
        });
      } else {
        state.teamJobsFilterTeamMembersList.push({
          name: 'You',
          id: localStorage.getItem(ConstantValues.ownerId)
        });
      }
    }
    data.map(function (teamMember) {
      if (teamMember) {
        var member = {
          name: teamMember.firstName + (teamMember.id == localStorage.getItem('userId') ? ' (You)' : ''),
          id: teamMember.id
        };
        state.teamJobsFilterTeamMembersList.push(member);
      }
    });
  },
  setFieldsListForJobsSearch: function setFieldsListForJobsSearch(state, data) {
    data.map(function (field) {
      if (field) {
        var fieldObj = {
          name: field.title,
          id: field.id
        };
        state.teamJobsFilterFieldsList.push(fieldObj);
      }
    });
  },
  setFieldsListSizeForJobsSearch: function setFieldsListSizeForJobsSearch(state, data) {
    state.teamJobsFilterFieldsListSize = data;
  },
  setVehiclesListForJobsSearch: function setVehiclesListForJobsSearch(state, data) {
    data.map(function (vehicle) {
      if (vehicle) {
        var vehicleObj = {
          name: vehicle.title,
          id: vehicle.id
        };
        state.teamJobsFilterVehiclesList.push(vehicleObj);
      }
    });
  },
  setImplementsListForJobsSearch: function setImplementsListForJobsSearch(state, data) {
    data.map(function (implement) {
      if (implement) {
        var implementObj = {
          name: implement.title,
          id: implement.id
        };
        state.teamJobsFilterImplementsList.push(implementObj);
      }
    });
  },
  setProductsListForJobsSearch: function setProductsListForJobsSearch(state, data) {
    data.map(function (product) {
      if (product) {
        var productObj = {
          name: product.title,
          id: product.id
        };
        state.teamJobsFilterProductsList.push(productObj);
      }
    });
  },
  setProductsListSizeForJobsSearch: function setProductsListSizeForJobsSearch(state, data) {
    state.teamJobsFilterProductsListSize = data;
  },
  setVehiclesListSizeForJobsSearch: function setVehiclesListSizeForJobsSearch(state, data) {
    state.teamJobsFilterVehiclesListSize = data;
  },
  setTeammembersListSizeForJobsSearch: function setTeammembersListSizeForJobsSearch(state, data) {
    state.teamJobsFilterTeammembersListSize = data;
  },
  setImplementsListSizeForJobsSearch: function setImplementsListSizeForJobsSearch(state, data) {
    state.teamJobsFilterImplementsListSize = data;
  },
  setOperationsListForJobsSearch: function setOperationsListForJobsSearch(state, data) {
    data.map(function (data) {
      if (data.operations.length) {
        data.operations.map(function (opr) {
          return state.teamJobsFilterOperationsList.push(opr);
        });
      }
    });
  },
  setRoutedFromJobDetails: function setRoutedFromJobDetails(state, data) {
    state.routedFromJobDetails = data;
  },
  updateJobInList: function updateJobInList(state, updatedJob) {
    var jobIndex = state.teamJobsList.findIndex(function (job) {
      return job.id === updatedJob.id;
    });
    if (jobIndex > -1) {
      state.teamJobsList.splice(jobIndex, 1, updatedJob);
    }
  },
  removeJobFromList: function removeJobFromList(state, jobIndex) {
    state.teamJobsList.splice(jobIndex, 1);
  },
  resetTeamJobsList: function resetTeamJobsList(state) {
    state.teamJobsList = [];
  }
};