import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.updateFormEnabled
      ? _c("div", [
          _c(
            "div",
            { staticClass: "b-btm py-5 px-9" },
            [
              _c(
                VRow,
                {
                  staticClass: "fn-20",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(VCol, { staticClass: "oy-tl", attrs: { cols: "8" } }, [
                    _vm._v(" Import "),
                    _c("span", { staticClass: "text-capitalize" }, [
                      _vm._v(_vm._s(_vm.intgrationType)),
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm.$stringConstants("customersTitle")) + " "
                    ),
                  ]),
                  _c(
                    VCol,
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "oy-cl",
                          attrs: {
                            small: "",
                            id: "closeImportIntegrateCustomersForm",
                          },
                          on: { click: _vm.closeImportIntegrateCustomersForm },
                        },
                        [_vm._v(" mdi-close-thick ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "pt-6",
                class: [_vm.containerLeftPad, _vm.containerRightPad],
              },
              [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "px-0 py-1", attrs: { cols: "10" } },
                      [
                        _c(VTextField, {
                          staticClass: "agd-txtfield",
                          attrs: {
                            placeholder: _vm.$stringConstants(
                              "searchCustomerTextPlaceholder"
                            ),
                            "prepend-inner-icon": "mdi-magnify",
                            outlined: "",
                            dense: "",
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchIntegratedCustomerForInvite()
                            },
                          },
                          model: {
                            value: _vm.integrationContactSearchText,
                            callback: function ($$v) {
                              _vm.integrationContactSearchText = $$v
                            },
                            expression: "integrationContactSearchText",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      VCol,
                      { staticClass: "pr-0 py-1", attrs: { cols: "2" } },
                      [
                        _c("Button", {
                          staticClass: "float-right btn-gr f-nn enter-btn",
                          attrs: {
                            variant: "secondary",
                            outlined: "",
                            disabled: !_vm.integrationContactSearchText,
                            textValue: "Search",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.searchIntegratedCustomerForInvite()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.customerLoaderImport &&
                _vm.contactsIntegration.length > 0 &&
                !_vm.integrationContactSearchText
                  ? _c(
                      VRow,
                      { staticClass: "pb-3" },
                      [
                        _c(
                          VCol,
                          {
                            staticClass: "list-check px-0",
                            attrs: { cols: "8" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ib" },
                              [
                                _c(VCheckbox, {
                                  attrs: {
                                    indeterminate: _vm.someContactSelected,
                                    "input-value": _vm.allContactSelected,
                                  },
                                  on: { change: _vm.selectAllContact },
                                  model: {
                                    value: _vm.isSelectAllToggleChecked,
                                    callback: function ($$v) {
                                      _vm.isSelectAllToggleChecked = $$v
                                    },
                                    expression: "isSelectAllToggleChecked",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "ib v-a-t pl-2" }, [
                              _vm._v(_vm._s(_vm.$stringConstants("selectAll"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            !_vm.customerLoaderImport && _vm.contactsIntegration.length > 0
              ? _c(
                  "div",
                  [
                    _c(VVirtualScroll, {
                      attrs: {
                        bench: 0,
                        items: _vm.contactsIntegration,
                        height: "770",
                        "item-height": "70",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  VListItem,
                                  { key: item.id },
                                  [
                                    item.customer == null
                                      ? _c(
                                          VRow,
                                          {
                                            staticClass: "py-3 px-6 pt-0 pb-4",
                                            attrs: { "no-gutters": "" },
                                          },
                                          [
                                            _c(
                                              VCol,
                                              {
                                                staticClass: "list-check px-0",
                                                attrs: { cols: "8" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "ib" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.selectedContacts,
                                                          expression:
                                                            "selectedContacts",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        transform:
                                                          "scale(1.4) translateY(3px)",
                                                        "accent-color":
                                                          "#1d34ad",
                                                      },
                                                      attrs: {
                                                        type: "checkbox",
                                                        disabled:
                                                          _vm.checkUserValid(
                                                            item
                                                          ) ||
                                                          item.customer != null,
                                                      },
                                                      domProps: {
                                                        value: item.id,
                                                        checked: Array.isArray(
                                                          _vm.selectedContacts
                                                        )
                                                          ? _vm._i(
                                                              _vm.selectedContacts,
                                                              item.id
                                                            ) > -1
                                                          : _vm.selectedContacts,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.selectedContacts,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = item.id,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.selectedContacts =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.selectedContacts =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.selectedContacts =
                                                              $$c
                                                          }
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ib v-a-t pl-6",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "fw-5 rd-details",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.companyName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rd-label pt-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              VCol,
                                              { attrs: { cols: "4" } },
                                              [
                                                _vm.checkUserValid(item)
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pt-1 float-right fw-6 cursor-pointer",
                                                        staticStyle: {
                                                          color: "#1d34ad",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openCustomerUpdateForm(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$stringConstants(
                                                                "farmerCompleteInfoText"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(VDivider),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2716802522
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "btns-bottom-box px-8 py-4 text-center",
                staticStyle: { "max-width": "633px" },
              },
              [
                _c("ButtonActionPrimary", {
                  attrs: {
                    width: "100%",
                    height: 48,
                    textValue: "Import customers",
                    disabled: _vm.selectedContacts.length == 0,
                    loading: _vm.importCustomerLoader,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.proceedIntegratedImport()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          !_vm.customerLoaderImport && _vm.contactsIntegration.length == 0
            ? _c("div", { staticClass: "text-center pt-16 fw-7" }, [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("farmerNotFoundText")) + " "
                ),
              ])
            : _vm._e(),
          _vm.customerLoaderImport
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("ListLoader"),
                  !_vm.integrationContactSearchText
                    ? _c("div", { staticClass: "pt-6 fs-12" }, [
                        _c("span", [_vm._v("Fetching from ")]),
                        _vm._v(
                          " " + _vm._s(_vm.capitalizeText(_vm.integrationType))
                        ),
                        _c("span", [_vm._v(", please wait.")]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.updateFormEnabled
      ? _c(
          "div",
          [
            _c("CustomerEditForm", {
              attrs: { intgrationType: _vm.integrationType },
              on: {
                proceed: function ($event) {
                  return _vm.closeFarmerEditForm()
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }