import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { closeOverlayPermanent } from "@/utils/helpers";
import { formFieldPb, containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import { mapMutations } from "vuex";
import farmersComputedMixin from "@/components/farmers/farmers-computed";
import { BaseButton as Button, ButtonActionPrimary } from '@/components/shared/buttons/index';
import CustomerEditForm from "./farmers-import-edit-form.vue";
import ConstantValues from '@/plugins/constantValues';
export default {
  name: "IntegratedCustomerImport",
  mixins: [farmersComputedMixin],
  components: {
    ButtonActionPrimary: ButtonActionPrimary,
    ListLoader: ListLoader,
    CustomerEditForm: CustomerEditForm,
    Button: Button
  },
  props: ["intgrationType"],
  watch: {
    integrationContactSearchText: function integrationContactSearchText(searchValue) {
      if (!searchValue) {
        this.tempIntegratedCustomers = [];
        this.getContactsForImport();
      }
      this.isSelectAllToggleChecked = false;
    }
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      selectedContacts: [],
      isSelectAllToggleChecked: false,
      importCustomerLoader: false
    };
  },
  mounted: function mounted() {
    if (this.integrationContactSearchText) {
      this.integrationContactSearchText = '';
    }
  },
  computed: {
    someContactSelected: function someContactSelected() {
      return this.selectedContacts.length > 0 && !this.allContactSelected;
    },
    allContactSelected: function allContactSelected() {
      return this.selectedContacts.length == this.contactsIntegration.length;
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFarmersFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    closeImportIntegrateCustomersForm: function closeImportIntegrateCustomersForm() {
      closeOverlayPermanent("importIntegratedCustomerFormOverlay");
      this.integratedCustomerImportInProgress = false;
      this.clearFarmersFilterOptions();
      this.resetPaging();
    },
    getContactsForImport: function getContactsForImport() {
      var _this = this;
      this.clearFarmersFilterOptions();
      this.resetPaging();
      this.contactsIntegration = [];
      this.$store.dispatch("fnSetIntegrationContactsFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSizeCustomer]).then(function (res) {
        _this.loadedContactsCustomer = 0;
        _this.totalContacts = 0;
        _this.$store.dispatch("getIntegrationContacts", _this.integrationType);
        if (_this.integrationContactSearchText == "") {
          _this.clearFarmersFilterOptions();
        }
      });
    },
    searchIntegratedCustomerForInvite: function searchIntegratedCustomerForInvite() {
      this.tempIntegratedCustomers = [];
      this.getContactsForImport();
    },
    proceedIntegratedImport: function proceedIntegratedImport() {
      var _this2 = this;
      var _a;
      this.clearFarmersFilterOptions();
      this.resetPaging();
      if (!this.allContactSelected && ((_a = this.selectedContacts) === null || _a === void 0 ? void 0 : _a.length) > 0 || !this.isSelectAllToggleChecked) {
        this.selectedContacts.map(function (contact) {
          _this2.$store.commit("setFilterOptions", [ConstantValues.queryIdentifiers, contact]);
        });
      }
      this.importCustomerLoader = true;
      this.$store.dispatch("importContactsForIntegration").then(function (res) {
        _this2.selectedContacts = [];
        _this2.importCustomerLoader = false;
        _this2.closeImportIntegrateCustomersForm();
        _this2.farmersFilterList = [];
        _this2.getFarmersListView();
      });
    },
    checkUserValid: function checkUserValid(contact) {
      if (contact.name != null && contact.name.length != 0 && contact.companyName != null && contact.companyName.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    selectAllContact: function selectAllContact() {
      var _this3 = this;
      this.selectedContacts = [];
      if (this.isSelectAllToggleChecked) {
        this.contactsIntegration.forEach(function (contact) {
          if ((contact.name != null || contact.customer != null) && contact.name != null && contact.customer == null) {
            _this3.selectedContacts.push(contact.id);
          }
        });
      }
    },
    openCustomerUpdateForm: function openCustomerUpdateForm(customer) {
      this.$store.commit("resetFarmerDetails");
      this.$store.commit("setFarmerDetails", customer);
      this.updateFormEnabled = true;
    },
    closeFarmerEditForm: function closeFarmerEditForm() {
      this.updateFormEnabled = false;
    },
    capitalizeText: function capitalizeText(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
    }
  })
};