import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { checkFieldsValidity, getAddressAsSingleString } from '@/utils/helpers';
import Loader from '@/components/shared/loaders/list-loader.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';
export default {
  name: 'AccountFarmsAddress',
  mixins: [accountComputedMixin],
  components: {
    SectionLeftHeadings: SectionLeftHeadings,
    Loader: Loader,
    Button: Button
  },
  data: function data() {
    return {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      latitude: 0,
      longitude: 0,
      showFarmAddressAddFields: false
    };
  },
  computed: {
    isNewFarmFieldsValid: function isNewFarmFieldsValid() {
      if (checkFieldsValidity([this.addressLine1, this.postalCode])) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    removeSelectedAddress: function removeSelectedAddress(addressId) {
      var _this = this;
      this.$store.dispatch("deleteUserAddresses", addressId).then(function (res) {
        _this.$store.dispatch('getUserFarmAddress');
      });
    },
    Update: function Update(id) {
      var _this2 = this;
      var address = this.accountFarmAddresses.filter(function (x) {
        return x.id == id;
      })[0];
      this.$store.dispatch('updateUserAddresses', address).then(function (res) {
        _this2.$store.dispatch('getUserFarmAddress');
      });
    },
    displayAddFarmAddressFields: function displayAddFarmAddressFields() {
      this.showFarmAddressAddFields = true;
    },
    isFarmAddressValid: function isFarmAddressValid(address) {
      if (checkFieldsValidity([address.addressLine1, address.postalCode])) {
        return true;
      } else {
        return false;
      }
    },
    addNewAddress: function addNewAddress() {
      var _this3 = this;
      var newAddressObj = {};
      newAddressObj = {
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        city: this.city,
        state: this.state,
        postalCode: this.postalCode,
        country: this.country,
        coordinate: null
      };
      this.$store.dispatch('addUserAddresses', newAddressObj).then(function (res) {
        _this3.$store.dispatch('getUserFarmAddress');
        _this3.hideAddFarmAddressFields();
      });
    },
    hideAddFarmAddressFields: function hideAddFarmAddressFields() {
      this.addressLine1 = '';
      this.addressLine2 = '';
      this.city = '';
      this.state = '';
      this.postalCode = '';
      this.country = '';
      this.latitude = 0;
      this.longitude = 0;
      this.showFarmAddressAddFields = false;
    },
    getAddressInline: function getAddressInline(address) {
      return getAddressAsSingleString(address);
    }
  }
};