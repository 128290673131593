import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _slicedToArray from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.entries.js";
function generateComputed(stateName, moduleName) {
  return _defineProperty({}, stateName, {
    get: function get() {
      return this.$store.getters[stateName];
    },
    set: function set(newVal) {
      if (moduleName == 'root') {
        this.$store.state[stateName] = newVal;
      } else {
        this.$store.state[moduleName][stateName] = newVal;
      }
    }
  });
}
var dynamicComputedProperties = {};
function createDynamicProperties(computedProperties) {
  var _loop = function _loop() {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      key = _Object$entries$_i[0],
      value = _Object$entries$_i[1];
    var newValue = value;
    newValue.map(function (v) {
      var computedResult = generateComputed(v, key);
      dynamicComputedProperties = _objectSpread(_objectSpread({}, dynamicComputedProperties), computedResult);
    });
  };
  for (var _i = 0, _Object$entries = Object.entries(computedProperties); _i < _Object$entries.length; _i++) {
    _loop();
  }
  return dynamicComputedProperties;
}
export var getComputedProperties = function getComputedProperties(computedProperties) {
  createDynamicProperties(computedProperties);
  return _objectSpread({}, dynamicComputedProperties);
};