import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { Field } from '@/data/models';
import ConstantValues from '@/plugins/constantValues';
import { listContainsItem } from '@/utils/helpers';
export var state = {
  fieldLoader: false,
  fieldFilterList: [],
  fieldSearchText: '',
  fieldFarmerSearchText: '',
  fieldFarmerSearchTextInternal: '',
  fieldCordList: [],
  fieldOwnerList: [],
  fieldOwnerFilterList: [],
  fieldFarmList: [],
  fieldListOwnerId: null,
  yearList: [],
  fieldDetails: {
    id: null,
    title: null,
    name: null,
    address: null,
    addressId: null,
    ownerShip: null,
    colour: null,
    areaInSquareMeter: null,
    note: null,
    polygon: null,
    fieldYear: null,
    owner: null,
    ownerDetails: null,
    cropHistory: [],
    shapeIndex: null
  },
  map: null,
  areaInvalid: false,
  addFieldLoader: false,
  editFieldLoader: false,
  colorUpdateOnMap: false,
  polygonShape: null,
  polyPath: null,
  fieldReadonly: false,
  bulkUploadInProgress: false,
  fieldOwnerListForBulkUpload: [],
  bulkUploadOwner: null,
  compressedFile: null,
  areaInSquareMeter: null,
  drawingManager: null,
  polygon: null,
  convertedArea: 0,
  mountedGMaps: false,
  oldOwnerId: null,
  fieldsListMapOffset: null,
  fieldsListMapLoaded: null,
  fieldsListMapTotal: null,
  fieldsListMapLoader: false,
  fieldsListMapData: [],
  fieldsListMap: null,
  fieldsInfoWindowContent: null,
  fieldsListFieldShapes: [],
  fieldsListFieldShapesReadOny: [],
  fieldsListMapLabels: [],
  computedAreas: [],
  fieldsListForSearchReadOnly: [],
  fieldSearchTextReadOnly: '',
  fieldSearchResultsReadOnly: [],
  fieldsListMapLabelsReadOnly: [],
  highlightedField: [],
  showEditableFields: false,
  fieldsTotalCount: 0,
  fieldCropNameToAdd: '',
  fieldCropYearToAdd: null,
  fieldInProgressJobs: [],
  fieldStatus: 0,
  userIsFarmerOrFarmerManager: false,
  highlightedFieldsListMapData: [],
  fieldsCustomersFilterDropdownLoader: false,
  fieldOwnerListSize: 0,
  tempFieldPolygon: null,
  showFieldsList: true,
  totalFieldsCount: 0,
  ownersMapData: new Map(),
  infoWindowData: {
    fieldColor: "",
    fieldName: "",
    fieldArea: "",
    fieldCrop: "",
    fieldCustomer: "",
    fieldId: "",
    ownerId: "",
    fieldEditable: ""
  },
  addingField: false,
  editingField: false,
  editingAreaOnMap: false,
  fieldsMountedOnMap: null,
  mapAlertMessage: null,
  mapAlertListener: null,
  mapTriggeredEditFieldData: null,
  placesSearchResults: [],
  placesSearchQuery: null
};
export var getters = {
  fieldLoader: function fieldLoader(state) {
    return state.fieldLoader;
  },
  fieldSearchText: function fieldSearchText(state) {
    return state.fieldSearchText;
  },
  fieldFarmerSearchText: function fieldFarmerSearchText(state) {
    return state.fieldFarmerSearchText;
  },
  fieldFilterList: function fieldFilterList(state) {
    return state.fieldFilterList;
  },
  fieldDetails: function fieldDetails(state) {
    return state.fieldDetails;
  },
  fieldCordList: function fieldCordList(state) {
    return state.fieldCordList;
  },
  areaInvalid: function areaInvalid(state) {
    return state.areaInvalid;
  },
  fieldOwnerList: function fieldOwnerList(state) {
    return state.fieldOwnerList;
  },
  fieldOwnerFilterList: function fieldOwnerFilterList(state) {
    return state.fieldOwnerFilterList;
  },
  fieldFarmList: function fieldFarmList(state) {
    return state.fieldFarmList;
  },
  fieldListOwnerId: function fieldListOwnerId(state) {
    return state.fieldListOwnerId;
  },
  yearList: function yearList(state) {
    return state.yearList;
  },
  SearchEnter: function SearchEnter(state) {
    return state.SearchEnter;
  },
  fieldFarmerSearchTextInternal: function fieldFarmerSearchTextInternal(state) {
    return state.fieldFarmerSearchTextInternal;
  },
  addFieldLoader: function addFieldLoader(state) {
    return state.addFieldLoader;
  },
  editFieldLoader: function editFieldLoader(state) {
    return state.editFieldLoader;
  },
  polygonShape: function polygonShape(state) {
    return state.polygonShape;
  },
  polyPath: function polyPath(state) {
    return state.polyPath;
  },
  fieldReadonly: function fieldReadonly(state) {
    return state.fieldReadonly;
  },
  bulkUploadInProgress: function bulkUploadInProgress(state) {
    return state.bulkUploadInProgress;
  },
  fieldOwnerListForBulkUpload: function fieldOwnerListForBulkUpload(state) {
    return state.fieldOwnerListForBulkUpload;
  },
  bulkUploadOwner: function bulkUploadOwner(state) {
    return state.bulkUploadOwner;
  },
  compressedFile: function compressedFile(state) {
    return state.compressedFile;
  },
  areaInSquareMeter: function areaInSquareMeter(state) {
    return state.areaInSquareMeter;
  },
  drawingManager: function drawingManager(state) {
    return state.drawingManager;
  },
  polygon: function polygon(state) {
    return state.polygon;
  },
  convertedArea: function convertedArea(state) {
    return state.convertedArea;
  },
  mountedGMaps: function mountedGMaps(state) {
    return state.mountedGMaps;
  },
  oldOwnerId: function oldOwnerId(state) {
    return state.oldOwnerId;
  },
  fieldsListMapOffset: function fieldsListMapOffset(state) {
    return state.fieldsListMapOffset;
  },
  fieldsListMapLoaded: function fieldsListMapLoaded(state) {
    return state.fieldsListMapLoaded;
  },
  fieldsListMapTotal: function fieldsListMapTotal(state) {
    return state.fieldsListMapTotal;
  },
  fieldsListMapLoader: function fieldsListMapLoader(state) {
    return state.fieldsListMapLoader;
  },
  fieldsListMapData: function fieldsListMapData(state) {
    return state.fieldsListMapData;
  },
  fieldsListMap: function fieldsListMap(state) {
    return state.fieldsListMap;
  },
  fieldsInfoWindowContent: function fieldsInfoWindowContent(state) {
    return state.fieldsInfoWindowContent;
  },
  fieldsListFieldShapes: function fieldsListFieldShapes(state) {
    return state.fieldsListFieldShapes;
  },
  fieldsListMapLabels: function fieldsListMapLabels(state) {
    return state.fieldsListMapLabels;
  },
  computedAreas: function computedAreas(state) {
    return state.computedAreas;
  },
  fieldsListFieldShapesReadOny: function fieldsListFieldShapesReadOny(state) {
    return state.fieldsListFieldShapesReadOny;
  },
  fieldsListForSearchReadOnly: function fieldsListForSearchReadOnly(state) {
    return state.fieldsListForSearchReadOnly;
  },
  fieldSearchTextReadOnly: function fieldSearchTextReadOnly(state) {
    return state.fieldSearchTextReadOnly;
  },
  fieldSearchResultsReadOnly: function fieldSearchResultsReadOnly(state) {
    return state.fieldSearchResultsReadOnly;
  },
  fieldsListMapLabelsReadOnly: function fieldsListMapLabelsReadOnly(state) {
    return state.fieldsListMapLabelsReadOnly;
  },
  highlightedField: function highlightedField(state) {
    return state.highlightedField;
  },
  showEditableFields: function showEditableFields(state) {
    return state.showEditableFields;
  },
  fieldsTotalCount: function fieldsTotalCount(state) {
    return state.fieldsTotalCount;
  },
  fieldCropNameToAdd: function fieldCropNameToAdd(state) {
    return state.fieldCropNameToAdd;
  },
  fieldCropYearToAdd: function fieldCropYearToAdd(state) {
    return state.fieldCropYearToAdd;
  },
  fieldInProgressJobs: function fieldInProgressJobs(state) {
    return state.fieldInProgressJobs;
  },
  fieldStatus: function fieldStatus(state) {
    return state.fieldStatus;
  },
  userIsFarmerOrFarmerManager: function userIsFarmerOrFarmerManager(state) {
    return state.userIsFarmerOrFarmerManager;
  },
  highlightedFieldsListMapData: function highlightedFieldsListMapData(state) {
    return state.highlightedFieldsListMapData;
  },
  fieldsCustomersFilterDropdownLoader: function fieldsCustomersFilterDropdownLoader(state) {
    return state.fieldsCustomersFilterDropdownLoader;
  },
  fieldOwnerListSize: function fieldOwnerListSize(state) {
    return state.fieldOwnerListSize;
  },
  tempFieldPolygon: function tempFieldPolygon(state) {
    return state.tempFieldPolygon;
  },
  showFieldsList: function showFieldsList(state) {
    return state.showFieldsList;
  },
  totalFieldsCount: function totalFieldsCount(state) {
    return state.totalFieldsCount;
  },
  editingAreaOnMap: function editingAreaOnMap(state) {
    return state.editingAreaOnMap;
  },
  fieldsMountedOnMap: function fieldsMountedOnMap(state) {
    return state.fieldsMountedOnMap;
  },
  mapAlertMessage: function mapAlertMessage(state) {
    return state.mapAlertMessage;
  },
  mapTriggeredEditFieldData: function mapTriggeredEditFieldData(state) {
    return state.mapTriggeredEditFieldData;
  },
  placesSearchResults: function placesSearchResults(state) {
    return state.placesSearchResults;
  },
  placesSearchQuery: function placesSearchQuery(state) {
    return state.placesSearchQuery;
  }
};
export var mutations = {
  setFieldsListMapData: function setFieldsListMapData(state, data) {
    data.map(function (data) {
      var newObj = new Field(data);
      state.fieldsListMapData.push(newObj);
      if (!state.ownersMapData.has(newObj.owner.id)) {
        state.ownersMapData.set(newObj.owner.id, newObj.colour);
      }
    });
  },
  setFieldsLoadedCounter: function setFieldsLoadedCounter(state, _) {
    state.fieldsListMapLoaded = state.fieldsListMapData.length;
    state.fieldsListMapOffset = state.fieldsListMapLoaded;
  },
  setFieldFilteredList: function setFieldFilteredList(state, data) {
    data.forEach(function (field) {
      return state.fieldFilterList.push(new Field(field));
    });
  },
  appendFieldToStartOfFieldFilteredList: function appendFieldToStartOfFieldFilteredList(state, data) {
    var field = new Field(data);
    state.fieldFilterList.unshift(field);
  },
  updateFieldInFieldFilteredList: function updateFieldInFieldFilteredList(state, data) {
    var updatedField = new Field(data);
    if (listContainsItem(state.fieldFilterList, [ConstantValues.defaultObjectIdentifier], updatedField.id)) {
      var staleFieldIndex = state.fieldFilterList.findIndex(function (field) {
        return field.id === updatedField.id;
      });
      state.fieldFilterList.splice(staleFieldIndex, 1, updatedField);
    }
  },
  setFieldDetails: function setFieldDetails(state, data) {
    state.fieldDetails['id'] = data.id;
    state.fieldDetails['title'] = data.title;
    state.fieldDetails['name'] = data.crop != null ? data.crop.name : null;
    state.fieldDetails['fieldYear'] = data.crop != null ? data.crop.year : null;
    if (data.address != null) {
      state.fieldDetails['addressId'] = data.address.id;
    }
    state.fieldDetails['ownerShip'] = data.ownership;
    state.fieldDetails['note'] = data.note;
    state.fieldDetails['owner'] = data.owner.id;
    state.fieldDetails['ownerDetails'] = data.owner;
    state.fieldDetails['colour'] = data.colour;
    state.fieldDetails['areaInSquareMeter'] = data.areaInSquareMeter;
    state.fieldDetails['polygon'] = data.polygon;
    state.fieldDetails['cropHistory'] = data.cropHistory;
    state.fieldDetails['address'] = data.address;
    state.fieldDetails['shapeIndex'] = data === null || data === void 0 ? void 0 : data.shapeIndex;
    state.fieldCordList = data.polygon;
    state.oldOwnerId = data.owner.id;
  },
  setAddFieldCord: function setAddFieldCord(state, data) {
    if (data == null) {
      state.fieldCordList = [];
    } else {
      var Cord = {
        "latitude": data.latitude,
        "longitude": data.longitude
      };
      state.fieldCordList.push(Cord);
    }
  },
  setOwnerList: function setOwnerList(state, data) {
    data.map(function (data) {
      if (data.businessProfile != null) {
        var owner = {
          firstName: data.businessProfile.title,
          id: data.id,
          isInternal: data.isInternal,
          color: data.colorCode
        };
        if (!listContainsItem(state.fieldOwnerList, [ConstantValues.defaultObjectIdentifier], owner.id)) {
          state.fieldOwnerList.push(owner);
        }
        if (!listContainsItem(state.fieldOwnerFilterList, [ConstantValues.defaultObjectIdentifier], owner.id)) {
          state.fieldOwnerFilterList.push(owner);
        }
      }
    });
  },
  setOwnerListSize: function setOwnerListSize(state, data) {
    state.fieldOwnerListSize = data;
  },
  setOwnerListBulkUpload: function setOwnerListBulkUpload(state, data) {
    data.map(function (data) {
      if (data.businessProfile != null) {
        var owner = {
          firstName: data.businessProfile.title,
          id: data.id
        };
        state.fieldOwnerListForBulkUpload.push(owner);
      }
    });
  },
  setCurrentOwnerToOwnerList: function setCurrentOwnerToOwnerList(state, data) {
    if (data.businessProfile != null) {
      var owner = {
        firstName: data.businessProfile.title,
        id: data.id,
        isInternal: data.isInternal
      };
      state.fieldOwnerList.push(owner);
      state.fieldOwnerFilterList.push(owner);
    }
  },
  setDefaultOwnerList: function setDefaultOwnerList(state, data) {
    var owner = {
      firstName: (data ? data : localStorage.getItem('fullOwnerBusinessName') ? localStorage.getItem('fullOwnerBusinessName') : '') + " (You)",
      id: localStorage.getItem(ConstantValues.ownerId),
      color: localStorage.getItem(ConstantValues.ownerColor) !== "null" ? localStorage.getItem(ConstantValues.ownerColor) : ConstantValues.defaultPolygonStrokeColor
    };
    if (!listContainsItem(state.fieldOwnerList, [ConstantValues.defaultObjectIdentifier], owner.id)) {
      state.fieldOwnerList.push(owner);
    }
  },
  setDefaultOwnerListBulkUpload: function setDefaultOwnerListBulkUpload(state, data) {
    var owner = {
      firstName: (data ? data : localStorage.getItem('fullOwnerBusinessName') ? localStorage.getItem('fullOwnerBusinessName') : '') + " (You)",
      id: localStorage.getItem(ConstantValues.ownerId)
    };
    if (!listContainsItem(state.fieldOwnerListForBulkUpload, [ConstantValues.defaultObjectIdentifier], owner.id)) {
      state.fieldOwnerListForBulkUpload.push(owner);
    }
  },
  setDefaultOwnerFilterList: function setDefaultOwnerFilterList(state, data) {
    if (data) {
      var defItem = [{
        firstName: "All",
        id: "All"
      }, {
        firstName: data + " (You)",
        id: localStorage.getItem(ConstantValues.ownerId)
      }];
      state.fieldOwnerFilterList = defItem;
    } else {
      if (localStorage.getItem('ownerRole') == 'Contractor' && localStorage.getItem('userRole') == '2') {
        var _defItem = [{
          firstName: "All",
          id: "All"
        }, {
          firstName: localStorage.getItem('fullOwnerBusinessName'),
          id: localStorage.getItem(ConstantValues.ownerId)
        }];
        state.fieldOwnerFilterList = _defItem;
      }
    }
  },
  setFieldFarmList: function setFieldFarmList(state, data) {
    data.map(function (data) {
      return state.fieldFarmList.push(data);
    });
    var addr = {
      addressLine1: "None",
      id: null
    };
    state.fieldFarmList.push(addr);
  },
  clearFieldFarmList: function clearFieldFarmList(state) {
    state.fieldFarmList = [];
  },
  setYearList: function setYearList(state, data) {
    state.yearList.push(data);
  },
  setFieldsListForSearchReadOnly: function setFieldsListForSearchReadOnly(state, data) {
    state.fieldsListForSearchReadOnly = [];
    if (data && data.length > 0) {
      data.map(function (field) {
        if (field.field.polygon.length > 0) {
          state.fieldsListForSearchReadOnly.push({
            title: field.field.title ? field.field.title : '',
            customer: field.customer ? field.customer : '',
            field: field.field
          });
        }
      });
    }
  },
  setJobsByField: function setJobsByField(state, fieldJobs) {
    state.fieldInProgressJobs = fieldJobs;
  },
  clearFieldsPolygons: function clearFieldsPolygons(state) {
    var _a, _b, _c, _d;
    var fieldIndex = state.highlightedFieldsListMapData.findIndex(function (field) {
      return field.id === state.fieldDetails.id;
    });
    (_a = state.polyPath) === null || _a === void 0 ? void 0 : _a.setMap(null);
    (_b = state.polygon) === null || _b === void 0 ? void 0 : _b.setMap(null);
    (_c = state.tempFieldPolygon) === null || _c === void 0 ? void 0 : _c.setMap(null);
    state.tempFieldPolygon = null;
    (_d = state.highlightedField[fieldIndex]) === null || _d === void 0 ? void 0 : _d.setMap(null);
  },
  resetFieldDetails: function resetFieldDetails(state) {
    var _a, _b, _c;
    state.tempFieldPolygon = null;
    state.fieldDetails['id'] = null;
    state.fieldDetails['title'] = null;
    state.fieldDetails['name'] = null;
    state.fieldDetails['fieldYear'] = null;
    state.fieldDetails['addressId'] = null;
    state.fieldDetails['address'] = null;
    state.fieldDetails['ownerShip'] = null;
    state.fieldDetails['colour'] = null;
    state.fieldDetails['areaInSquareMeter'] = null;
    state.fieldDetails['polygon'] = null;
    state.fieldDetails['owner'] = null;
    state.fieldDetails['ownerDetails'] = null;
    state.fieldDetails['note'] = null;
    state.fieldDetails['cropHistory'] = [];
    state.fieldCordList = [];
    state.areaInSquareMeter = null;
    state.areaInvalid = false;
    state.addFieldLoader = false;
    state.editFieldLoader = false;
    state.colorUpdateOnMap = false;
    state.polygonShape = null;
    state.polyPath = null;
    state.bulkUploadInProgress = false;
    (_a = state.drawingManager) === null || _a === void 0 ? void 0 : _a.setDrawingMode(null);
    (_b = state.drawingManager) === null || _b === void 0 ? void 0 : _b.setMap(null);
    state.drawingManager = null;
    (_c = state.polygon) === null || _c === void 0 ? void 0 : _c.setEditable(false);
    state.polygon = null;
    state.convertedArea = 0;
    state.fieldsCustomersFilterDropdownLoader = false;
    state.placesSearchResults = [];
    state.placesSearchQuery = null;
  },
  setHighlightedFieldsListMapData: function setHighlightedFieldsListMapData(state, highlightedFields) {
    highlightedFields.map(function (field) {
      return state.highlightedFieldsListMapData.push(field);
    });
  },
  setInfoWindowData: function setInfoWindowData(state, field) {
    state.infoWindowData = field;
  },
  setShowFieldsList: function setShowFieldsList(state, value) {
    state.showFieldsList = value;
  },
  addingField: function addingField(state, value) {
    state.addingField = value;
  },
  editingField: function editingField(state, value) {
    state.editingField = value;
  },
  turnoffDrawingManager: function turnoffDrawingManager(state) {
    var _a, _b;
    (_a = state.drawingManager) === null || _a === void 0 ? void 0 : _a.setDrawingMode(null);
    (_b = state.drawingManager) === null || _b === void 0 ? void 0 : _b.setMap(null);
    state.drawingManager = null;
  },
  setTempPolygons: function setTempPolygons(state, fieldPolygon) {
    // state.tempFieldPolygon = fieldPolygon;
    state.polygon = fieldPolygon;
  },
  clearFieldsListMapData: function clearFieldsListMapData() {
    state.fieldsListMapData = [];
  },
  clearFieldsListFieldShapes: function clearFieldsListFieldShapes() {
    state.fieldsListFieldShapes.forEach(function (shape) {
      return shape.setMap(null);
    });
    state.fieldsListFieldShapes = [];
  },
  clearHighlightedFieldsListMapData: function clearHighlightedFieldsListMapData() {
    state.highlightedFieldsListMapData = [];
  },
  setMapAlertMessage: function setMapAlertMessage(state, message) {
    state.mapAlertMessage = message;
  },
  clearMapAlertMessage: function clearMapAlertMessage(state) {
    state.mapAlertMessage = null;
  },
  setMapAlertListener: function setMapAlertListener(state, listener) {
    state.mapAlertListener = listener;
  },
  clearAlertMessageListener: function clearAlertMessageListener(state) {
    var _window = window,
      google = _window.google;
    google.maps.event.removeListener(state.mapAlertListener);
  },
  setMapTriggeredEditFieldData: function setMapTriggeredEditFieldData(state, field) {
    state.mapTriggeredEditFieldData = field;
  }
};