import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import $ from 'jquery';
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobAddressesFlyoutForm from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout.vue';
import { openOverlay, closeOverlay } from '@/utils/helpers';
import { TeamJobAddressSelectionType } from '@/enum/jobAddressSelectionType';
import { TeamJobAddress } from '@/data/models/TeamJobAddress';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
export default {
  name: "TeamJobsLocations",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    TeamJobAddressesFlyoutForm: TeamJobAddressesFlyoutForm
  },
  mounted: function mounted() {
    $('body').on('click', '#overlay', function () {
      closeOverlay("jobAddressesFormOverlay");
    });
  },
  methods: {
    resetFarmAddressStates: function resetFarmAddressStates() {
      this.teamJobFarmAddressesSelected = [];
      this.teamJobAddressInstructions = null;
      this.teamJobAddressSelectionType = TeamJobAddressSelectionType.FarmAddress;
      this.teamJobCustomAddress = new TeamJobAddress();
    },
    openAddAddressesForm: function openAddAddressesForm() {
      this.resetFarmAddressStates();
      openOverlay('jobAddressesFormOverlay');
    },
    removeAddress: function removeAddress(addressIndex) {
      this.enableTeamJobDiscard();
      this.teamJobAddresses = this.teamJobAddresses.filter(function (_, jIndex) {
        return jIndex !== addressIndex;
      });
    },
    getAddressTitle: function getAddressTitle(address) {
      var line1 = address.addressLine1 ? address.addressLine1 : "";
      var line2 = " " + (address.addressLine2 ? address.addressLine2 : "");
      var city = " " + (address.city ? address.city : "");
      var country = " " + (address.country ? address.country : "");
      var postalCode = " " + (address.postalCode ? address.postalCode : "");
      var addressArray = [line1, line2, city, country, postalCode];
      return addressArray.join();
    }
  }
};