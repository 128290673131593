import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { generateMonthlyDataByYear } from '@/utils/helpers';
import { GChart } from 'vue-google-charts';
import businessesComputedMixin from "./su-businesses-computed";
import { initialMonthlySubscriptionsChartData } from '@/utils/constants';
export default {
  name: "BusinessesDetailsTable",
  mixins: [businessesComputedMixin],
  data: function data() {
    return {
      invoiceAmountList: [],
      selectedYear: new Date().getFullYear(),
      selectedYearListData: [],
      chartData: initialMonthlySubscriptionsChartData
    };
  },
  props: ['rowData'],
  components: {
    GChart: GChart
  },
  computed: {
    subscriptions: function subscriptions() {
      return this.businessesDetails.subscriptions;
    },
    yearsList: function yearsList() {
      var _a, _b, _c, _d, _e, _f;
      if (((_a = this.subscriptions) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((_d = (_c = (_b = this.subscriptions) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.invoices) === null || _d === void 0 ? void 0 : _d.length) > 0) {
        var invoicesList = (_f = (_e = this.subscriptions) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.invoices;
        var filteredList = new Map();
        invoicesList.map(function (item) {
          if (filteredList.has(item.year)) {
            filteredList.get(item.year).push(item);
          } else {
            filteredList.set(item.year, [item]);
          }
        });
        var yearsListMap = filteredList.keys();
        var yearsList = Array.from(filteredList.keys());
        return yearsList;
      } else {
        return [];
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    var businessId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    this.$store.dispatch('getBusinessesDetails', businessId).then(function () {
      _this.getChartData();
    });
  },
  methods: {
    getChartData: function getChartData() {
      var _a, _b, _c, _d, _e, _f;
      if (((_a = this.subscriptions) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((_d = (_c = (_b = this.subscriptions) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.invoices) === null || _d === void 0 ? void 0 : _d.length) > 0) {
        this.mapInvoicesListFromSubscriptions((_f = (_e = this.subscriptions) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.invoices);
        this.chartData = generateMonthlyDataByYear(this.selectedYearListData);
      } else {
        return this.selectedYearListData;
      }
    },
    mapInvoicesListFromSubscriptions: function mapInvoicesListFromSubscriptions(invoicesList) {
      var year = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.selectedYear;
      var filteredList = new Map();
      invoicesList.map(function (item) {
        if (filteredList.has(item.year)) {
          filteredList.get(item.year).push(item);
        } else {
          filteredList.set(item.year, [item]);
        }
      });
      this.selectedYearListData = filteredList.get(this.selectedYear);
    },
    updateChartByYear: function updateChartByYear() {
      this.getChartData();
    }
  }
};