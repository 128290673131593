import _toConsumableArray from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import moment from 'moment';
import ConstantValues from '@/plugins/constantValues';
import { listContainsItem } from '@/utils/helpers';
import { Address } from '@/data/models/Address';
export var state = {
  supplierNameForInvoice: null,
  supplierEmailForInvoice: null,
  supplierPhoneNumberForInvoice: null,
  supplierPaymentInstructionsForInvoice: null,
  supplierBusinessProfileForInvoice: {
    title: null,
    address: new Address(),
    registrationNumber: null,
    vatNumber: null
  },
  customerNameForInvoice: null,
  customerEmailForInvoice: null,
  customerPhoneNumberForInvoice: null,
  customerBusinessProfileForInvoice: {
    title: null,
    address: new Address(),
    registrationNumber: null,
    vatNumber: null
  },
  customersListForInvoice: [],
  customersSearchText: '',
  invoiceLoader: false,
  selectedCustomerIdForInvoice: null,
  jobsListForInvoice: [],
  selectedJobsForInvoice: [],
  taxValuesForInvoice: [],
  dueDateForInvoice: null,
  invoiceDraftPreviewDetails: null,
  invoicesList: [],
  invoiceSearchText: '',
  canOpenInvoice: false,
  draftedInvoiceIdToOpen: null,
  isInvoiceOpened: false,
  invoiceSelectedTab: 1,
  invoiceDate: null,
  invoiceRefs: [],
  invoiceLineItemJobNumbersDistinct: [],
  invoiceFilterStatus: [{
    title: 'All',
    value: ''
  }, {
    title: 'Draft',
    value: '0'
  }, {
    title: 'Open',
    value: '1'
  }, {
    title: 'Paid',
    value: '2'
  }],
  invoiceFilterStatusSelected: null,
  invoiceFilterEmailStatusSelected: null,
  invoiceTabTexts: ['Internal', 'Quickbooks/Xero'],
  selectedInvoiceTabTextExternal: '',
  invoiceJobsForPreview: [],
  invoiceJobsPreviewLoader: false,
  invoiceLineItemsJobIds: [],
  invoiceSend: false,
  invoiceShouldSendLater: false,
  invoicesListOffset: ConstantValues.defaultPageOffsetSize,
  invoicesListLimit: ConstantValues.defaultPageLimitSize,
  noListData: false,
  invoicesListCount: ConstantValues.defaultPageListCount,
  customersListForInvoiceLoader: false,
  customersListForInvoiceSize: 0,
  noData: false,
  listCount: ConstantValues.defaultPageListCount,
  totalRowCount: ConstantValues.defaultPageTotalRowCount,
  offset: ConstantValues.defaultPageOffsetSize,
  limit: ConstantValues.defaultPageLimitSize,
  invoicesListFilterCustomerValue: '',
  invoicesListFilterCustomersList: [],
  invoicesListFilterCustomerSearchText: '',
  invoicesListFilterCustomersDropdownLoader: false,
  invoicesListFilterStartDate: null,
  invoicesListFilterStartDateLocal: null,
  invoicesListFilterEndDate: null,
  invoicesListFilterEndDateLocal: null,
  invoicesListFilterDateApplied: false,
  showAddLineitemsForInternalInvoice: [],
  newProductForInternalInvoice: null,
  newLineItemsInitialsForInternal: {
    date: null,
    title: null,
    quantity: 0,
    rate: 0,
    unit: null,
    type: 1,
    amount: 0,
    description: null,
    reference: null,
    currency: null,
    taxAmounts: [{
      amount: 0,
      salesTaxName: '',
      salesTaxRateInPercentage: 0
    }]
  },
  invoiceInternalLineItemSearchText: null,
  productListForInternalInvoice: [],
  productFeatchingLoader: false
};
export var getters = {
  supplierBusinessProfileForInvoice: function supplierBusinessProfileForInvoice(state) {
    return state.supplierBusinessProfileForInvoice;
  },
  supplierNameForInvoice: function supplierNameForInvoice(state) {
    return state.supplierNameForInvoice;
  },
  supplierEmailForInvoice: function supplierEmailForInvoice(state) {
    return state.supplierEmailForInvoice;
  },
  supplierPhoneNumberForInvoice: function supplierPhoneNumberForInvoice(state) {
    return state.supplierPhoneNumberForInvoice;
  },
  supplierPaymentInstructionsForInvoice: function supplierPaymentInstructionsForInvoice(state) {
    return state.supplierPaymentInstructionsForInvoice;
  },
  customerBusinessProfileForInvoice: function customerBusinessProfileForInvoice(state) {
    return state.customerBusinessProfileForInvoice;
  },
  customerNameForInvoice: function customerNameForInvoice(state) {
    return state.customerNameForInvoice;
  },
  customerEmailForInvoice: function customerEmailForInvoice(state) {
    return state.customerEmailForInvoice;
  },
  customerPhoneNumberForInvoice: function customerPhoneNumberForInvoice(state) {
    return state.customerPhoneNumberForInvoice;
  },
  customersListForInvoice: function customersListForInvoice(state) {
    return state.customersListForInvoice;
  },
  customersSearchText: function customersSearchText(state) {
    return state.customersSearchText;
  },
  invoiceLoader: function invoiceLoader(state) {
    return state.invoiceLoader;
  },
  selectedCustomerIdForInvoice: function selectedCustomerIdForInvoice(state) {
    return state.selectedCustomerIdForInvoice;
  },
  jobsListForInvoice: function jobsListForInvoice(state) {
    return state.jobsListForInvoice;
  },
  selectedJobsForInvoice: function selectedJobsForInvoice(state) {
    return state.selectedJobsForInvoice;
  },
  taxValuesForInvoice: function taxValuesForInvoice(state) {
    return state.taxValuesForInvoice;
  },
  dueDateForInvoice: function dueDateForInvoice(state) {
    return state.dueDateForInvoice;
  },
  invoiceDraftPreviewDetails: function invoiceDraftPreviewDetails(state) {
    return state.invoiceDraftPreviewDetails;
  },
  invoicesList: function invoicesList(state) {
    return state.invoicesList;
  },
  invoiceSearchText: function invoiceSearchText(state) {
    return state.invoiceSearchText;
  },
  canOpenInvoice: function canOpenInvoice(state) {
    return state.canOpenInvoice;
  },
  draftedInvoiceIdToOpen: function draftedInvoiceIdToOpen(state) {
    return state.draftedInvoiceIdToOpen;
  },
  isInvoiceOpened: function isInvoiceOpened(state) {
    return state.isInvoiceOpened;
  },
  invoiceSelectedTab: function invoiceSelectedTab(state) {
    return state.invoiceSelectedTab;
  },
  invoiceDate: function invoiceDate(state) {
    return state.invoiceDate;
  },
  invoiceRefs: function invoiceRefs(state) {
    return state.invoiceRefs;
  },
  invoiceLineItemJobNumbersDistinct: function invoiceLineItemJobNumbersDistinct(state) {
    return state.invoiceLineItemJobNumbersDistinct;
  },
  invoiceFilterStatus: function invoiceFilterStatus(state) {
    return state.invoiceFilterStatus;
  },
  invoiceFilterStatusSelected: function invoiceFilterStatusSelected(state) {
    return state.invoiceFilterStatusSelected;
  },
  invoiceFilterEmailStatusSelected: function invoiceFilterEmailStatusSelected(state) {
    return state.invoiceFilterEmailStatusSelected;
  },
  invoiceTabTexts: function invoiceTabTexts(state) {
    return state.invoiceTabTexts;
  },
  selectedInvoiceTabTextExternal: function selectedInvoiceTabTextExternal(state) {
    return state.selectedInvoiceTabTextExternal;
  },
  invoiceJobsForPreview: function invoiceJobsForPreview(state) {
    return state.invoiceJobsForPreview;
  },
  invoiceJobsPreviewLoader: function invoiceJobsPreviewLoader(state) {
    return state.invoiceJobsPreviewLoader;
  },
  invoiceLineItemsJobIds: function invoiceLineItemsJobIds(state) {
    return state.invoiceLineItemsJobIds;
  },
  invoiceSend: function invoiceSend(state) {
    return state.invoiceSend;
  },
  invoiceShouldSendLater: function invoiceShouldSendLater(state) {
    return state.invoiceShouldSendLater;
  },
  invoicesListOffset: function invoicesListOffset(state) {
    return state.invoicesListOffset;
  },
  invoicesListLimit: function invoicesListLimit(state) {
    return state.invoicesListLimit;
  },
  noListData: function noListData(state) {
    return state.noListData;
  },
  invoicesListCount: function invoicesListCount(state) {
    return state.invoicesListCount;
  },
  customersListForInvoiceLoader: function customersListForInvoiceLoader(state) {
    return state.customersListForInvoiceLoader;
  },
  customersListForInvoiceSize: function customersListForInvoiceSize(state) {
    return state.customersListForInvoiceSize;
  },
  invoicesListFilterCustomerValue: function invoicesListFilterCustomerValue(state) {
    return state.invoicesListFilterCustomerValue;
  },
  invoicesListFilterCustomersList: function invoicesListFilterCustomersList(state) {
    return state.invoicesListFilterCustomersList;
  },
  invoicesListFilterCustomerSearchText: function invoicesListFilterCustomerSearchText(state) {
    return state.invoicesListFilterCustomerSearchText;
  },
  invoicesListFilterCustomersDropdownLoader: function invoicesListFilterCustomersDropdownLoader(state) {
    return state.invoicesListFilterCustomersDropdownLoader;
  },
  invoicesListFilterStartDate: function invoicesListFilterStartDate(state) {
    return state.invoicesListFilterStartDate;
  },
  invoicesListFilterStartDateLocal: function invoicesListFilterStartDateLocal(state) {
    return state.invoicesListFilterStartDateLocal;
  },
  invoicesListFilterEndDate: function invoicesListFilterEndDate(state) {
    return state.invoicesListFilterEndDate;
  },
  invoicesListFilterEndDateLocal: function invoicesListFilterEndDateLocal(state) {
    return state.invoicesListFilterEndDateLocal;
  },
  invoicesListFilterDateApplied: function invoicesListFilterDateApplied(state) {
    return state.invoicesListFilterDateApplied;
  },
  showAddLineitemsForInternalInvoice: function showAddLineitemsForInternalInvoice(state) {
    return state.showAddLineitemsForInternalInvoice;
  },
  newProductForInternalInvoice: function newProductForInternalInvoice(state) {
    return state.newProductForInternalInvoice;
  },
  newLineItemsInitialsForInternal: function newLineItemsInitialsForInternal(state) {
    return state.newLineItemsInitialsForInternal;
  },
  invoiceInternalLineItemSearchText: function invoiceInternalLineItemSearchText(state) {
    return state.invoiceInternalLineItemSearchText;
  },
  productListForInternalInvoice: function productListForInternalInvoice(state) {
    return state.productListForInternalInvoice;
  },
  productFeatchingLoader: function productFeatchingLoader(state) {
    return state.productFeatchingLoader;
  }
};
export var mutations = {
  setBusinessProfileForInvoice: function setBusinessProfileForInvoice(state, data) {
    state.supplierNameForInvoice = data.businessProfile.title ? data.businessProfile.title : '';
    state.supplierEmailForInvoice = data.email;
    state.supplierPhoneNumberForInvoice = data.primaryContactNumber;
    state.supplierPaymentInstructionsForInvoice = data.businessProfile.paymentInstructions;
    if (data.businessProfile != null) {
      var businessProfile = data.businessProfile;
      var address = businessProfile.address;
      state.supplierBusinessProfileForInvoice['title'] = businessProfile.title;
      state.supplierBusinessProfileForInvoice['address'] = new Address().setResponseAddress(address);
      state.supplierBusinessProfileForInvoice['registrationNumber'] = businessProfile.registrationNumber;
      state.supplierBusinessProfileForInvoice['vatNumber'] = businessProfile.vatNumber;
    } else {
      state.supplierBusinessProfileForInvoice['title'] = null;
      state.supplierBusinessProfileForInvoice['address'] = new Address();
      state.supplierBusinessProfileForInvoice['registrationNumber'] = null;
      state.supplierBusinessProfileForInvoice['vatNumber'] = null;
    }
  },
  setCustomerProfileForInvoice: function setCustomerProfileForInvoice(state, data) {
    state.customerNameForInvoice = data.businessProfile.title ? data.businessProfile.title : '';
    state.customerEmailForInvoice = data.email;
    state.customerPhoneNumberForInvoice = data.primaryContactNumber;
    if (data.businessProfile != null) {
      var businessProfile = data.businessProfile;
      var address = businessProfile.address;
      state.customerBusinessProfileForInvoice['title'] = businessProfile.title;
      state.customerBusinessProfileForInvoice['address'] = new Address().setResponseAddress(address);
      state.customerBusinessProfileForInvoice['registrationNumber'] = businessProfile.registrationNumber;
      state.customerBusinessProfileForInvoice['vatNumber'] = businessProfile.vatNumber;
    }
  },
  setCustomersListForInvoice: function setCustomersListForInvoice(state, data) {
    data.map(function (customer) {
      return state.customersListForInvoice.push(customer);
    });
    state.customersListForInvoice.map(function (customer) {
      if (customer.businessProfile && customer.businessProfile.title) {
        customer['displayName'] = customer.businessProfile.title;
      } else {
        customer['displayName'] = customer.firstName;
      }
    });
  },
  setJobsListForInvoice: function setJobsListForInvoice(state, data) {
    data.map(function (job) {
      return state.jobsListForInvoice.push(job);
    });
  },
  setTaxValues: function setTaxValues(state, data) {
    state.taxValuesForInvoice = [];
    data.map(function (taxValue) {
      return state.taxValuesForInvoice.push(taxValue);
    });
  },
  setInvoicesList: function setInvoicesList(state, data) {
    data.map(function (invoice) {
      if (!listContainsItem(state.invoicesList, [ConstantValues.defaultObjectIdentifier], invoice.id)) {
        state.invoicesList.push(invoice);
        invoice.jobIds.map(function (id) {
          state.invoiceRefs.push({
            jobId: id,
            refText: null,
            jobOwnerId: invoice.customerId
          });
        });
        invoice.references.map(function (ref, refIndex) {
          state.invoiceRefs[refIndex].refText = ref;
        });
      }
    });
  },
  setJobsListForPreview: function setJobsListForPreview(state, data) {
    data.map(function (job) {
      state.invoiceJobsForPreview.push(job);
    });
  },
  setInvoicePreviewDetails: function setInvoicePreviewDetails(state, data) {
    state.invoiceDraftPreviewDetails = data;
    state.invoiceLineItemsJobIds = data.jobIds;
    if (state.invoiceDraftPreviewDetails.lineItems.length > 0) {
      state.invoiceDraftPreviewDetails.lineItems.map(function (item) {
        if (item.taxAmounts.length == 0) {
          item.taxAmounts.push({
            amount: 0,
            salesTaxName: '',
            salesTaxRateInPercentage: 0
          });
        }
      });
      state.invoiceLineItemJobNumbersDistinct = _toConsumableArray(new Set(state.invoiceDraftPreviewDetails.lineItems.map(function (item) {
        return item.reference;
      })));
      state.invoiceDraftPreviewDetails.lineItems.sort(function (a, b) {
        return a.reference < b.reference ? -1 : a.reference > b.reference ? 1 : 0;
      });
      state.showAddLineitemsForInternalInvoice = [];
      state.invoiceLineItemJobNumbersDistinct.forEach(function () {
        state.showAddLineitemsForInternalInvoice.push(false);
      });
    }
    if (data.dueDate != null) {
      state.invoiceDate = moment.utc(data.dueDate).local().format('YYYY-MM-DD');
    }
    state.draftedInvoiceIdToOpen = state.invoiceDraftPreviewDetails.id;
    state.newLineItemsInitialsForInternal.currency = state.invoiceDraftPreviewDetails.currency;
  },
  setClearInvoiceData: function setClearInvoiceData(state) {
    state.supplierBusinessProfileForInvoice['title'] = null;
    state.supplierBusinessProfileForInvoice['address'] = new Address();
    state.supplierBusinessProfileForInvoice['registrationNumber'] = null;
    state.supplierBusinessProfileForInvoice['vatNumber'] = null;
    state.supplierNameForInvoice = null;
    state.supplierEmailForInvoice = null;
    state.supplierPhoneNumberForInvoice = null;
    state.customerBusinessProfileForInvoice['title'] = null;
    state.customerBusinessProfileForInvoice['address'] = new Address();
    state.customerBusinessProfileForInvoice['registrationNumber'] = null;
    state.customerBusinessProfileForInvoice['vatNumber'] = null;
    state.customerNameForInvoice = null;
    state.customerEmailForInvoice = null;
    state.customerPhoneNumberForInvoice = null;
    state.customersListForInvoice = [];
    state.customersSearchText = '';
    state.invoiceLoader = false;
    state.selectedCustomerIdForInvoice = null;
    state.jobsListForInvoice = [];
    state.selectedJobsForInvoice = [];
    state.taxValuesForInvoice = [];
    state.dueDateForInvoice = null;
    state.invoiceDraftPreviewDetails = null;
    state.canOpenInvoice = false;
    state.draftedInvoiceIdToOpen = null;
    state.isInvoiceOpened = false;
    state.invoiceSelectedTab = 1;
    state.invoiceLineItemJobNumbersDistinct = [];
    state.invoiceShouldSendLater = false;
  },
  resetNewTimeLineInitials: function resetNewTimeLineInitials(state) {
    state.newProductForInternalInvoice = null;
    state.newLineItemsInitialsForInternal = {
      date: null,
      title: null,
      quantity: 0,
      rate: 0,
      unit: null,
      type: 1,
      amount: 0,
      description: null,
      reference: null,
      currency: state.invoiceDraftPreviewDetails.currency,
      taxAmounts: [{
        amount: 0,
        salesTaxName: '',
        salesTaxRateInPercentage: 0
      }]
    };
  },
  setNoListDataStatus: function setNoListDataStatus(state, data) {
    state.noListData = data != null && data.length == 0;
    state.noData = (data === null || data === void 0 ? void 0 : data.length) == 0;
  },
  setCustomersListForInvoicesSearch: function setCustomersListForInvoicesSearch(state, data) {
    data.map(function (customer) {
      var _a;
      if (customer) {
        var member = {
          name: ((_a = customer.businessProfile) === null || _a === void 0 ? void 0 : _a.title) ? customer.businessProfile.title : customer.firstName + (customer.lastName ? ' ' + customer.lastName : ''),
          id: customer.id
        };
        state.invoicesListFilterCustomersList.push(member);
      }
    });
  },
  setCustomersListSizeForInvoicesSearch: function setCustomersListSizeForInvoicesSearch(state, data) {
    state.invoicesListFilterCustomersListSize = data;
  },
  setProductListForInternalInvoice: function setProductListForInternalInvoice(state, data) {
    data.map(function (product) {
      state.productListForInternalInvoice.push(product);
    });
  },
  updateInvoiceDetailsInInvoiceList: function updateInvoiceDetailsInInvoiceList(state, updatedInvoice) {
    var invoiceIndex = state.invoicesList.findIndex(function (invoice) {
      return invoice.id === updatedInvoice.id;
    });
    if (invoiceIndex > -1) {
      state.invoicesList.splice(invoiceIndex, 1, updatedInvoice);
    }
  },
  removeInvoiceFromList: function removeInvoiceFromList(state, invoiceId) {
    state.invoicesList.splice(invoiceId, 1);
  },
  updateInvoicePagingAfterListItemRemoval: function updateInvoicePagingAfterListItemRemoval(state) {
    state.listCount--;
    state.totalRowCount--;
    state.noData = state.invoicesList.length === 0;
  },
  resetInvoicesListPagingStates: function resetInvoicesListPagingStates(state) {
    state.invoicesList = [];
    state.listCount = ConstantValues.defaultPageListCount;
    state.totalRowCount = ConstantValues.defaultPageTotalRowCount;
    state.offset = ConstantValues.defaultPageOffsetSize;
    state.limit = ConstantValues.defaultPageLimitSize;
  }
};