import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import moment from 'moment';
import { Timesheet } from '@/data/models/Timesheet';
import { TimesheetStatistics } from '@/data/models/TimesheetStatistics';
import { TimeSheetType } from '@/enum/timeSheetType';
import { UserRoleType } from '@/enum/userRoleType';
import { getZeroPadding, listContainsItem } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
export var state = {
  timesheetsList: new Array(),
  timesheetsMembersList: new Array(),
  timesheetsLoader: false,
  timesheetsSearchText: '',
  timesheetMembersSearchText: '',
  timesheetsTypeList: [{
    title: "Holiday",
    value: TimeSheetType.Holiday
  }, {
    title: "Sick",
    value: TimeSheetType.Sick
  }, {
    title: "Working",
    value: TimeSheetType.Working
  }],
  timesheetsFilterTypeList: [{
    title: "All",
    value: null
  }, {
    title: "Holiday",
    value: TimeSheetType.Holiday
  }, {
    title: "Sick",
    value: TimeSheetType.Sick
  }, {
    title: "Working",
    value: TimeSheetType.Working
  }],
  memberDefaultWorkStartTime: null,
  memberDefaultWorkEndTime: null,
  timesheetDetails: new Timesheet(),
  addTimesheetLoader: false,
  editTimesheetLoader: false,
  timesheetsStatistics: new TimesheetStatistics(),
  timesheetListFilterType: TimeSheetType.Working,
  timesheetsFilterStartDate: null,
  timesheetsFilterEndDate: null,
  timesheetDateFilterApplied: false,
  timesheetsSelectedMemberId: null,
  timesheetsFilterLoader: false,
  timesheetDataListOffset: 0,
  timesheetDataListTotalCount: 0,
  timesheetDataListLoadedCount: 0,
  timesheetDataListForExport: new Array()
};
export var getters = {
  timesheetsList: function timesheetsList(state) {
    return state.timesheetsList;
  },
  timesheetsLoader: function timesheetsLoader(state) {
    return state.timesheetsLoader;
  },
  timesheetsSearchText: function timesheetsSearchText(state) {
    return state.timesheetsSearchText;
  },
  timesheetMembersSearchText: function timesheetMembersSearchText(state) {
    return state.timesheetMembersSearchText;
  },
  timesheetsMembersList: function timesheetsMembersList(state) {
    return state.timesheetsMembersList;
  },
  timesheetsTypeList: function timesheetsTypeList(state) {
    return state.timesheetsTypeList;
  },
  timesheetDetails: function timesheetDetails(state) {
    return state.timesheetDetails;
  },
  memberDefaultWorkStartTime: function memberDefaultWorkStartTime(state) {
    return state.memberDefaultWorkStartTime;
  },
  memberDefaultWorkEndTime: function memberDefaultWorkEndTime(state) {
    return state.memberDefaultWorkEndTime;
  },
  addTimesheetLoader: function addTimesheetLoader(state) {
    return state.addTimesheetLoader;
  },
  editTimesheetLoader: function editTimesheetLoader(state) {
    return state.editTimesheetLoader;
  },
  timesheetsStatistics: function timesheetsStatistics(state) {
    return state.timesheetsStatistics;
  },
  timesheetListFilterType: function timesheetListFilterType(state) {
    return state.timesheetListFilterType;
  },
  timesheetsFilterTypeList: function timesheetsFilterTypeList(state) {
    return state.timesheetsFilterTypeList;
  },
  timesheetsFilterStartDate: function timesheetsFilterStartDate(state) {
    return state.timesheetsFilterStartDate;
  },
  timesheetsFilterEndDate: function timesheetsFilterEndDate(state) {
    return state.timesheetsFilterEndDate;
  },
  timesheetDateFilterApplied: function timesheetDateFilterApplied(state) {
    return state.timesheetDateFilterApplied;
  },
  timesheetsSelectedMemberId: function timesheetsSelectedMemberId(state) {
    return state.timesheetsSelectedMemberId;
  },
  timesheetsFilterLoader: function timesheetsFilterLoader(state) {
    return state.timesheetsFilterLoader;
  },
  timesheetDataListOffset: function timesheetDataListOffset(state) {
    return state.timesheetDataListOffset;
  },
  timesheetDataListTotalCount: function timesheetDataListTotalCount(state) {
    return state.timesheetDataListTotalCount;
  },
  timesheetDataListLoadedCount: function timesheetDataListLoadedCount(state) {
    return state.timesheetDataListLoadedCount;
  },
  timesheetDataListForExport: function timesheetDataListForExport(state) {
    return state.timesheetDataListForExport;
  }
};
export var mutations = {
  setTimesheetsList: function setTimesheetsList(state, data) {
    data.map(function (data) {
      return state.timesheetsList.push(data);
    });
  },
  setDefaultMember: function setDefaultMember(state, data) {
    state.timesheetsMembersList = [];
    var owner = null;
    if (data[1] == UserRoleType.Manager) {
      owner = {
        name: localStorage.getItem('fullOwnerBusinessName') ? localStorage.getItem('fullOwnerBusinessName') + ' (owner)' : 'Owner',
        id: localStorage.getItem(ConstantValues.ownerId)
      };
    } else {
      owner = {
        name: data[0] + '(You)',
        id: localStorage.getItem(ConstantValues.ownerId)
      };
    }
    state.timesheetsMembersList.push(owner);
  },
  setTimesheetsMembersList: function setTimesheetsMembersList(state, data) {
    data.map(function (timeSheetMember) {
      if (timeSheetMember) {
        var member = {
          name: localStorage.getItem('userId') == timeSheetMember.id ? timeSheetMember.firstName + ' (You)' : timeSheetMember.firstName + (timeSheetMember.lastName ? ' ' + timeSheetMember.lastName : ''),
          id: timeSheetMember.id
        };
        if (!listContainsItem(state.timesheetsMembersList, ['id'], member.id)) {
          state.timesheetsMembersList.push(member);
        }
      }
    });
  },
  setTimesheetsStatistics: function setTimesheetsStatistics(state, data) {
    state.timesheetsStatistics = new TimesheetStatistics().getTimesheetStatistics(data);
  },
  setUserWorkTimeForTimeSheet: function setUserWorkTimeForTimeSheet(state, data) {
    var crDate = moment().format('YYYY-MM-DD');
    var stTime = moment.utc(data.settings.workingStartTime).format("HH:mm:ss");
    var etTime = moment.utc(data.settings.workingEndTime).format("HH:mm:ss");
    state.memberDefaultWorkStartTime = moment.utc(crDate + " " + stTime).local().format("HH:mm");
    state.memberDefaultWorkEndTime = moment.utc(crDate + " " + etTime).local().format("HH:mm");
  },
  setTimesheetDetails: function setTimesheetDetails(state, data) {
    state.timesheetDetails = new Timesheet().getTimesheet(data);
  },
  setTimesheetDataListForExport: function setTimesheetDataListForExport(state, data) {
    function getHoursAndMinsRounded(durationInSeconds) {
      var durationInMinutes = durationInSeconds / 60;
      var hours = durationInMinutes / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var h = minutes > 52 ? hours === 23 ? 0 : ++hours : hours;
      var m = Math.round(minutes / 15) * 15 % 60;
      return "".concat(parseInt(h.toString()), ":").concat(m.toString());
    }
    function getHoursAndMinsRoundedPayroll(durationInSeconds) {
      function getMinutesPayroll(minutes) {
        var minutesInt = parseInt(minutes);
        var payrollMinsInt = 0;
        if (minutesInt > 0 && minutesInt <= 15) {
          payrollMinsInt = 25;
        } else if (minutesInt >= 16 && minutesInt <= 30) {
          payrollMinsInt = 50;
        } else if (minutesInt >= 31 && minutesInt <= 45) {
          payrollMinsInt = 75;
        }
        return payrollMinsInt;
      }
      var durationInMinutes = durationInSeconds / 60;
      var hours = durationInMinutes / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var h = minutes > 52 ? hours === 23 ? 0 : ++hours : hours;
      var m = Math.round(minutes / 15) * 15 % 60;
      var mPayroll = getMinutesPayroll(m);
      return "".concat(parseInt(h.toString()), ".").concat(mPayroll.toString());
    }
    function zeroPad(num, places) {
      return getZeroPadding(num, places);
    }
    function getHoursAndMinsActual(durationInSeconds) {
      var h = durationInSeconds / 60 / 60;
      var hours = parseInt(h.toString());
      var mins = (h - hours) * 60;
      return "".concat(parseInt(hours.toString()), ":").concat(zeroPad(Math.round(parseFloat(mins.toString())), 2));
    }
    function getDateTimeLocal(dateTime) {
      return dateTime ? moment.utc(dateTime).local().format('DD-MMM-YYYY H:mm') : '';
    }
    data.map(function (timesheet) {
      var reportObj = {
        name: timesheet.name,
        start_time: getDateTimeLocal(timesheet.startTime),
        end_time: getDateTimeLocal(timesheet.endTime),
        type: state.timesheetsTypeList[state.timesheetsTypeList.findIndex(function (ts) {
          return ts.value == timesheet.type;
        })].title,
        work_time_in_mins: timesheet.type == TimeSheetType.Working ? parseFloat(timesheet.workTimeInMinutes.toString()).toFixed(2) : '',
        work_time_in_hours: timesheet.type == TimeSheetType.Working ? getHoursAndMinsActual(timesheet.workTimeInMinutes * 60) : '',
        work_time_in_hours_rounded: timesheet.type == TimeSheetType.Working ? getHoursAndMinsRounded(timesheet.workTimeInMinutes * 60) : '',
        work_time_in_hours_payroll: timesheet.type == TimeSheetType.Working ? getHoursAndMinsRoundedPayroll(timesheet.workTimeInMinutes * 60) : '',
        break_time_in_mins: parseFloat(timesheet.breakTimeInMinutes.toString()).toFixed(2),
        break_time_in_hours: getHoursAndMinsActual(timesheet.breakTimeInMinutes * 60),
        break_time_in_hours_rounded: getHoursAndMinsRounded(timesheet.breakTimeInMinutes * 60),
        travel_time_in_mins: parseFloat(timesheet.travelTimeInMinutes.toString()).toFixed(2),
        travel_time_in_hours: getHoursAndMinsActual(timesheet.travelTimeInMinutes * 60),
        travel_time_in_hours_rounded: getHoursAndMinsRounded(timesheet.travelTimeInMinutes * 60),
        note: timesheet.note ? timesheet.note : ''
      };
      state.timesheetDataListForExport.push(reportObj);
    });
    state.timesheetDataListLoadedCount = state.timesheetDataListForExport.length;
    state.timesheetDataListOffset = state.timesheetDataListLoadedCount;
  },
  resetTimesheetExportData: function resetTimesheetExportData(state) {
    state.timesheetDataListOffset = 0;
    state.timesheetDataListTotalCount = 0;
    state.timesheetDataListLoadedCount = 0;
    state.timesheetDataListForExport = [];
  }
};