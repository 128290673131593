import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import $ from 'jquery';
import { mapMutations } from 'vuex';
import { defaultScrollViewHeight } from '@/utils/uiconstants';
import { openOverlay } from '@/utils/helpers';
import permissionsMixin from '@/mixins/permissions-mixin';
import scrollerMixin from "@/mixins/scroller-mixin";
import Avatar from '@/components/shared/avatars/avatar.vue';
import moment from 'moment';
import contractorsComputedMixin from '@/components/contractors/contractors-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import skeletonLoaderMixin from '@/mixins/skeleton-loader-mixin';
import List from "@/components/shared/list.vue";
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import Snackbar from "@/components/shared/snackbar/snackbar-listing.vue";
import { ActionListingDialog } from "@/components/shared/dialogs/index";
import { listAction } from '@/enum/listAction';
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
export default {
  name: "ContractorListView",
  mixins: [permissionsMixin, scrollerMixin, contractorsComputedMixin, skeletonLoaderMixin, Timers],
  components: {
    Avatar: Avatar,
    Button: Button,
    List: List,
    ActionListingDialog: ActionListingDialog,
    Snackbar: Snackbar,
    DeleteItemDialog: DeleteItemDialog
  },
  data: function data() {
    return {
      defaultScrollViewHeight: defaultScrollViewHeight,
      searchTimer: null,
      isChecked: false,
      deleteContractorId: null,
      dialogContractor: false,
      actionType: listAction.Archive,
      listAction: listAction
    };
  },
  watch: {
    selectedItems: function selectedItems(val) {
      if (this.selectedItems.length > 0) {
        this.snackbarShow = true;
      } else {
        this.snackbarShow = false;
        this.isChecked = false;
      }
    }
  },
  computed: {
    someContractorsSelected: function someContractorsSelected() {
      return this.selectedItems.length > 0 && !this.allContractorsSelected;
    },
    allContractorsSelected: function allContractorsSelected() {
      if (this.contractorSearchText) {
        return false;
      } else {
        return this.selectedItems.length == this.contractorFilterList.length;
      }
    }
  },
  beforeMount: function beforeMount() {
    this.clearAndResetContractorPagingStates();
  },
  beforeDestroy: function beforeDestroy() {
    this.clearAndResetContractorPagingStates();
  },
  mounted: function mounted() {
    if (!this.$store.state.isPageRelated) {
      localStorage.removeItem('contractorSearchQuery');
      localStorage.removeItem('contractorFilterType');
    }
    this.setFilterOptionsAndGetContractors([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearContractorFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging",
    setClearInviteeProfile: "setClearInviteeProfile",
    resetSnackbarDetails: "clearSnackbarDetails"
  })), {}, {
    applySearchContractorFilter: function applySearchContractorFilter() {
      var _this = this;
      this.clearContractorFilterOption();
      this.resetPaging();
      this.contractorFilterList = [];
      this.pendingContractorList = [];
      this.filtersApplied = true;
      this.$nextTick(function () {
        _this.setFilterOptionsAndGetContractors([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
      });
    },
    clearAndResetContractorPagingStates: function clearAndResetContractorPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.contractorsModule.contractorFilterList = [];
      this.clearContractorFilterOption();
      this.resetSnackbarDetails();
      this.resetPaging();
      this.contractorFilterType = 0;
      this.filtersApplied = false;
    },
    openAddContractorForm: function openAddContractorForm() {
      this.setClearInviteeProfile();
      openOverlay("addContractorFormOverlay");
    },
    openManageContractorForm: function openManageContractorForm() {
      this.getConnectionRequests();
      openOverlay("manageContractorFormOverlay");
    },
    getConnectionRequests: function getConnectionRequests() {
      this.$store.dispatch('getConnectionRequestsReceived');
      this.$store.dispatch('getConnectionRequestsSent');
    },
    getTimeDateFormate: function getTimeDateFormate(date) {
      return moment.utc(date).local().format('DD MMM YYYY');
    },
    getContactorListByStatus: function getContactorListByStatus() {
      this.applySearchContractorFilter();
      this.noAcceptedContractors = false;
      this.noPendingContractors = false;
    },
    getBusinessAddress: function getBusinessAddress(data) {
      if (data != null && data.address != null) {
        var addr = {
          add2: data.address.addressLine2 != null ? data.address.addressLine2 : '',
          city: data.address.city != null ? data.address.city : '',
          country: data.address.country != null ? data.address.country : ''
        };
        return data.title + ", " + data.address.addressLine1 + " " + addr.add2 + " " + addr.city + " " + addr.country;
      } else {
        if (data != null && data.address == null) {
          return data.title;
        }
      }
      return 'N/A';
    },
    setFilterOptionsAndGetContractors: function setFilterOptionsAndGetContractors(filter) {
      var _this2 = this;
      if (!this.contractorSearchText) {
        this.clearContractorFilterOption();
        this.filtersApplied = false;
      }
      this.$store.dispatch("setContractorFilter", filter).then(function (res) {
        if (_this2.contractorFilterType == 0) {
          _this2.$store.dispatch("getContractorFiltered");
        } else if (_this2.contractorFilterType == 1) {
          _this2.$store.dispatch("getPendingContractorFiltered");
        }
      });
    },
    selectAllContractors: function selectAllContractors() {
      var _this3 = this;
      this.selectedItems = [];
      this.allItemsDeleted = false;
      if (this.isChecked) {
        this.contractorFilterList.map(function (pro) {
          _this3.selectedItems.push(pro.id);
        });
      }
    },
    confirmDeleteContractor: function confirmDeleteContractor(id) {
      this.actionType = listAction.Delete;
      this.deleteContractorId = id;
      this.dialogContractor = true;
    },
    proceedContractorDeletion: function proceedContractorDeletion() {
      var _this4 = this;
      this.dialogContractor = false;
      this.contractorFilterList = [];
      switch (this.actionType) {
        case listAction.Delete:
        case listAction.Archive:
          this.$store.dispatch('deleteContractor', this.deleteContractorId).then(function () {
            _this4.setFilterOptionsAndGetContractors([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
          });
          break;
        default:
          return;
      }
    }
  })
};