import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import onboardComputedMixin from "@/components/onboard/_onboard-computed";
import OnboardMapSearch from "@/components/onboard/onbaord-map-search.vue";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import { mapActions } from "vuex";
import { devLogger } from '@/utils/helpers';
export default {
  mixins: [onboardComputedMixin],
  data: function data() {
    return {
      menu: false
    };
  },
  updated: function updated() {
    var _a, _b, _c, _d;
    if (((_a = this.globalTimeZonesList) === null || _a === void 0 ? void 0 : _a.length) > 0 && !this.userProfile.businessProfile.config.timezone) {
      var currentTimeZone = (_c = (_b = Intl === null || Intl === void 0 ? void 0 : Intl.DateTimeFormat()) === null || _b === void 0 ? void 0 : _b.resolvedOptions()) === null || _c === void 0 ? void 0 : _c.timeZone;
      devLogger().log("currentTimeZone: ".concat(currentTimeZone));
      if (currentTimeZone) {
        var matchedTimeZone = (_d = this.globalTimeZonesList.filter(function (tz) {
          return tz.id === currentTimeZone;
        })) === null || _d === void 0 ? void 0 : _d[0];
        devLogger().log("matchedTimeZone: ".concat(matchedTimeZone));
        if (matchedTimeZone) {
          this.$store.commit('setUserTimeZone', matchedTimeZone);
        }
      }
    }
  },
  mounted: function mounted() {
    this.getGoogleMapToSelectDefaultLocation();
  },
  components: {
    OnboardMapSearch: OnboardMapSearch,
    VueCtkDateTimePicker: VueCtkDateTimePicker
  },
  watch: {
    onboardConfigTempColor: function onboardConfigTempColor(value) {
      if (value.toString().match(/#[a-zA-Z0-9]{8}/)) {
        this.onboardConfigTempColor = value.substr(0, 7);
      }
    }
  },
  computed: {
    swatchStyle: function swatchStyle() {
      var onboardConfigTempColor = this.onboardConfigTempColor,
        menu = this.menu;
      return {
        backgroundColor: onboardConfigTempColor,
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: menu ? "50%" : "50px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  },
  methods: _objectSpread({}, mapActions({
    getGoogleMapToSelectDefaultLocation: "getGoogleMapToSelectDefaultLocation",
    clearMapLocationInfoWindow: "clearMapLocationInfoWindow"
  }))
};