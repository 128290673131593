import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import TeamJobsComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-computed";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import moment from "moment";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { getTimeDetailsFormatted } from "@/utils/helpers/time-details-helpers";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { getHoursAndMins, getMinutesFromSeconds as _getMinutesFromSeconds, getZeroPadding } from "@/utils/helpers";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { acceptableBillingUnitsForBillableQuantityUpdate } from '@/utils/constants';
export default {
  name: "TeamJobsMetadata",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker,
    VueTimepicker: VueTimepicker,
    Button: Button
  },
  computed: {
    isTimeRecordsSelectable: {
      get: function get() {
        return this.teamJobDetails && this.teamJobOperation && (this.teamJobDetails.status == TeamJobStatusType.AwaitingCompletion || this.teamJobDetails.status == TeamJobStatusType.Completed && !this.teamJobDetails.isApprovedForInvoicing || this.teamJobDetails.status == TeamJobStatusType.PartCompleted) && this.teamJobTimeDetailsList.length > 0 && acceptableBillingUnitsForBillableQuantityUpdate.includes(this.teamJobOperation.billingUnit);
      }
    },
    isAnyTimeRecordSelected: {
      get: function get() {
        return this.teamJobSelectedTimeRecords.length > 0;
      }
    }
  },
  watch: {
    isAllTimeRecordsSelected: {
      handler: function handler(val) {
        if (val) this.teamJobSelectedTimeRecords = this.teamJobTimeDetailsList.map(function (_, index) {
          return index;
        });else this.teamJobSelectedTimeRecords = [];
      }
    }
  },
  methods: {
    handleWorkTimingsManualUpdate: function handleWorkTimingsManualUpdate(idx) {
      if (this.teamJobsLoader) return;
      var tempTimeRecord = Object.assign({}, this.teamJobTempTimeDetailsList[idx]);
      var timeRecord = this.teamJobTimeDetailsList[idx];
      var startingTime = moment(moment(timeRecord.startTime).format("DD-MM-YYYY HH:mm:ss"), "DD-MM-YYYY hh:mm:ss");
      var endingTime = moment(moment(timeRecord.endTime).format("DD-MM-YYYY HH:mm:ss"), "DD-MM-YYYY hh:mm:ss");
      var startTimeEndTimeDifferenceInSeconds = endingTime.diff(startingTime, "seconds");
      var totaldownTimeInSeconds = parseInt(timeRecord.downTimeFormatted.HH) * 3600 + parseInt(timeRecord.downTimeFormatted.mm) * 60;
      var totalBreakTimeInSeconds = parseInt(timeRecord.breakTimeFormatted.HH) * 3600 + parseInt(timeRecord.breakTimeFormatted.mm) * 60;
      var startEndTimeDifferenceInSeconds = startTimeEndTimeDifferenceInSeconds - (totaldownTimeInSeconds + totalBreakTimeInSeconds);
      if (startEndTimeDifferenceInSeconds > 0) {
        this.teamJobTimeDetailsList[idx].workTimeFormatted = startTimeEndTimeDifferenceInSeconds - totalBreakTimeInSeconds - totaldownTimeInSeconds;
        this.teamJobTimeDetailsList[idx].totalTimeFormatted = startTimeEndTimeDifferenceInSeconds;
        this.teamJobTimeDetailsList[idx].workTimeInSeconds = startTimeEndTimeDifferenceInSeconds - totalBreakTimeInSeconds - totaldownTimeInSeconds;
      } else {
        this.teamJobTimeDetailsList[idx].startTime = tempTimeRecord.startTime;
        this.teamJobTimeDetailsList[idx].endTime = tempTimeRecord.endTime;
        this.teamJobTimeDetailsList[idx].travelTimeFormatted = getTimeDetailsFormatted(tempTimeRecord.travelTimeInSeconds);
        this.teamJobTimeDetailsList[idx].downTimeFormatted = getTimeDetailsFormatted(tempTimeRecord.downTimeInSeconds);
        this.teamJobTimeDetailsList[idx].breakTimeFormatted = getTimeDetailsFormatted(tempTimeRecord.breakTimeInSeconds);
        this.teamJobTimeDetailsList[idx].workTimeFormatted = tempTimeRecord.workTimeInSeconds;
        this.teamJobTimeDetailsList[idx].workTimeInSeconds = tempTimeRecord.workTimeInSeconds;
      }
      this.updateSelectedTimeRecordsTotalWorkHours();
    },
    onChangeStartEndTime: function onChangeStartEndTime(idx) {
      this.onChangeTimeRecords();
      this.handleWorkTimingsManualUpdate(idx);
    },
    onChangeWorkTime: function onChangeWorkTime(idx) {
      var tempTimeRecord = Object.assign({}, this.teamJobTempTimeDetailsList[idx]);
      if (this.teamJobTimeDetailsList[idx].workTimeFormatted != tempTimeRecord.workTimeFormatted) {
        this.enableTeamJobDiscard();
      }
    },
    onChangeTimeRecords: function onChangeTimeRecords() {
      this.updateSelectedTimeRecordsTotalWorkHours();
      this.enableTeamJobDiscard();
    },
    getTimeInHoursAndMins: function getTimeInHoursAndMins(timeDurationInSeconds) {
      var _getHoursAndMins = getHoursAndMins(timeDurationInSeconds),
        hours = _getHoursAndMins.hours,
        minutes = _getHoursAndMins.minutes;
      return "".concat(getZeroPadding(hours, 2), ":").concat(getZeroPadding(minutes, 2));
    },
    updateSelectedTimeRecordsTotalWorkHours: function updateSelectedTimeRecordsTotalWorkHours() {
      var _this = this;
      this.teamJobSelectedTimeRecordsHours = 0;
      if (this.teamJobSelectedTimeRecords) {
        this.teamJobSelectedTimeRecords.forEach(function (timeRecordIndex) {
          _this.teamJobSelectedTimeRecordsHours += _this.teamJobTimeDetailsList[timeRecordIndex].workTimeInSeconds;
        });
      }
    },
    toggleSelectedTimeRecordsTotalWorkHours: function toggleSelectedTimeRecordsTotalWorkHours() {
      var _this2 = this;
      this.$nextTick(function () {
        if (_this2.isAllTimeRecordsSelected) _this2.updateSelectedTimeRecordsTotalWorkHours();else _this2.teamJobSelectedTimeRecordsHours = 0;
      });
    },
    setWorkHoursAsBillableQuantity: function setWorkHoursAsBillableQuantity() {
      this.$store.dispatch('setWorkHoursAsBillableQuantity', this.teamJobSelectedTimeRecordsHours);
      this.enableTeamJobDiscard();
    },
    getMinutesFromSeconds: function getMinutesFromSeconds(seconds) {
      return _getMinutesFromSeconds(seconds);
    }
  }
};