import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  data: function data() {
    return {
      observer: null
    };
  },
  methods: {
    initializeResizeObserver: function initializeResizeObserver(target, callback) {
      var _a;
      this.observer = new ResizeObserver(function (entries) {
        entries.forEach(function (_ref) {
          var contentBoxSize = _ref.contentBoxSize;
          return contentBoxSize[0].inlineSize > 0 && callback();
        });
      });
      (_a = this.observer) === null || _a === void 0 ? void 0 : _a.observe(target);
    },
    initializeIntersectionObserver: function initializeIntersectionObserver(target, callback) {
      var _a;
      var options = {
        root: null,
        threshold: 0.5
      };
      this.observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (_ref2) {
          var isIntersecting = _ref2.isIntersecting;
          isIntersecting && callback();
        });
      }, options);
      (_a = this.observer) === null || _a === void 0 ? void 0 : _a.observe(target);
    }
  },
  beforeDestroy: function beforeDestroy() {
    var _a;
    (_a = this.observer) === null || _a === void 0 ? void 0 : _a.disconnect();
  }
};