import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { InvoiceStatusType } from "@/enum/invoiceStatusType";
import { JobAccessLevelType } from "@/enum/jobAccessLevelType";
import { ResourceType } from "@/enum/resourceType";
import { SubscriptionStatusType } from "@/enum/subscriptionStatusType";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import { devLogger, getConvertedAreaUnit } from "@/utils/helpers";
import moment from "moment";
import { getDateTimeFormat, getDateTimeFormattedLocal } from '@/utils/helpers';
import { getCancellationDate, getSubscriptionStatusEnum, getSusbcriptionAmountIfExists, getTrialDaysLeft } from "@/utils/helpers/subscription-helper";
var exportDataSeparator = ", ";
var exportDataDateTimeFormat = "DD MMM YYYY hh:mm A";
var exportDataWithEmptySpaces = true;
var newLine = "\n";
var initialState = function initialState() {
  return {
    exportDataOffset: 0,
    exportDataTotal: 0,
    exportDataLoaded: 0,
    exportData: [],
    exportDataLength: 0,
    exportDataTextFilters: ""
  };
};
function getAddressFormatted(address) {
  if (address.addressLine1 || address.addressLine2 || address.city || address.state || address.country || address.postalCode) {
    return (address.addressLine1 ? address.addressLine1 + exportDataSeparator : "") + (address.addressLine2 ? address.addressLine2 + exportDataSeparator : "") + (address.city ? address.city + exportDataSeparator : "") + (address.state ? address.state + exportDataSeparator : "") + (address.country ? address.country + " - " : "") + (address.postalCode ? address.postalCode : "");
  } else {
    if (address.coordinate) {
      return "Dropped Pin: (".concat(address.coordinate.latitude, ", ").concat(address.coordinate.longitude, ")");
    }
  }
}
function getLineItemFormatted(lineItem) {
  return "".concat(lineItem.title);
}
function getDateTime(dateTime) {
  return dateTime ? moment.utc(dateTime).local().format(exportDataDateTimeFormat) : "";
}
function getCurrencyFormatted(currency) {
  return "".concat(currency.id);
}
function showIfNotRepeated(key, value, invoiceId) {
  var items = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : state.exportData;
  if (exportDataWithEmptySpaces) {
    if (items.find(function (item) {
      return item[key] == value;
    }) && items.find(function (item) {
      return item.id === invoiceId;
    })) {
      return "";
    }
  }
  return value;
}
function getLineItemTaxRate(taxAmounts) {
  var _a;
  return ((_a = taxAmounts === null || taxAmounts === void 0 ? void 0 : taxAmounts[0]) === null || _a === void 0 ? void 0 : _a.salesTaxRateInPercentage) || 0;
}
function getLineItemTaxAmount(taxAmounts) {
  var _a;
  return ((_a = taxAmounts === null || taxAmounts === void 0 ? void 0 : taxAmounts[0]) === null || _a === void 0 ? void 0 : _a.amount) || 0;
}
export var state = initialState();
export var getters = {
  exportDataOffset: function exportDataOffset(state) {
    return state.exportDataOffset;
  },
  exportDataTotal: function exportDataTotal(state) {
    return state.exportDataTotal;
  },
  exportDataLoaded: function exportDataLoaded(state) {
    return state.exportDataLoaded;
  },
  exportData: function exportData(state) {
    return state.exportData;
  },
  exportDataLength: function exportDataLength(state) {
    return state.exportDataLength;
  },
  exportDataTextFilters: function exportDataTextFilters(state) {
    return state.exportDataTextFilters;
  }
};
export var mutations = {
  setInvoicesDataListForExport: function setInvoicesDataListForExport(state, invoicesData) {
    invoicesData.forEach(function (inv) {
      state.exportDataLength++;
      inv.references.forEach(function (jobReference) {
        if (inv === null || inv === void 0 ? void 0 : inv.lineItems.length) {
          var lineItems = inv.lineItems.filter(function (lineItem) {
            return jobReference.includes(lineItem.reference);
          });
          if (lineItems === null || lineItems === void 0 ? void 0 : lineItems.length) {
            lineItems.forEach(function (lineItem) {
              var obj = {
                id: inv.id,
                invoice_number: showIfNotRepeated("invoice_number", inv.number, inv.id),
                status: showIfNotRepeated("status", InvoiceStatusType[inv.status], inv.id),
                customer_name: showIfNotRepeated("customer_name", inv.customer.name, inv.id),
                customer_address: showIfNotRepeated("customer_address", getAddressFormatted(inv.customer.address), inv.id),
                email_address: showIfNotRepeated("email_address", inv.customer.emailAddress, inv.id),
                phone_number: showIfNotRepeated("phone_number", inv.customer.phoneNumber, inv.id),
                reference_number: showIfNotRepeated("reference_number", lineItem.reference, inv.id),
                line_item_date: getDateTime(lineItem.date),
                line_item: getLineItemFormatted(lineItem),
                line_item_rate: lineItem.rate,
                line_item_quantity: lineItem.quantity,
                line_item_unit: lineItem.unit,
                line_item_amount: lineItem.amount,
                line_item_tax_rate: getLineItemTaxRate(lineItem.taxAmounts),
                line_item_tax_amount: getLineItemTaxAmount(lineItem.taxAmounts),
                invoice_due_date: showIfNotRepeated("invoice_due_date", getDateTime(inv.dueDate), inv.id),
                invoice_subtotal: showIfNotRepeated("invoice_subtotal", inv.subTotal, inv.id),
                invoice_tax: showIfNotRepeated("invoice_tax", inv.tax, inv.id),
                invoice_total: showIfNotRepeated("invoice_total", inv.total, inv.id),
                invoice_currency: showIfNotRepeated("invoice_currency", getCurrencyFormatted(inv.currency), inv.id)
              };
              state.exportData.push(obj);
            });
          }
        }
      });
    });
    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },
  setJobsDataListForExport: function setJobsDataListForExport(state, jobsData) {
    var jobsDataSeparator = ", ";
    var jobsDataDateTimeFormat = "DD MMM YYYY hh:mm A";
    var jobsDataTrue = "TRUE";
    var jobsDataFalse = "FALSE";
    var newLine = "\n";
    function getUserName(customer) {
      return (customer.businessName ? customer.businessName : customer.name) || "";
    }
    function getDateTime(dateTime) {
      return dateTime ? moment.utc(dateTime).local().format(jobsDataDateTimeFormat) : "";
    }
    function getTimeDurationInMinutesWithActivityType(timeRecords, timeType) {
      var timeInSeconds = 0;
      if (timeRecords && timeRecords.length) {
        timeRecords.forEach(function (t) {
          timeInSeconds += t[timeType];
        });
      }
      return (timeInSeconds / 60).toFixed(3);
    }
    function getTimeDurationInMinutes() {
      var timeInSeconds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      return (timeInSeconds / 60).toFixed(3);
    }
    function getAddressFormatted(address) {
      if (address.addressLine1 || address.addressLine2 || address.city || address.state || address.country || address.postalCode) {
        return (address.addressLine1 ? address.addressLine1 + jobsDataSeparator : "") + (address.addressLine2 ? address.addressLine2 + jobsDataSeparator : "") + (address.city ? address.city + jobsDataSeparator : "") + (address.state ? address.state + jobsDataSeparator : "") + (address.country ? address.country + " - " : "") + (address.postalCode ? address.postalCode : "");
      } else {
        if (address.coordinate) {
          return "Dropped Pin: (".concat(address.coordinate.latitude, ", ").concat(address.coordinate.longitude, ")");
        }
      }
    }
    function getAddresses(addresses) {
      var tempAddresses = [];
      if (addresses && addresses.length) {
        addresses.forEach(function (a, i) {
          var addressString = getAddressFormatted(a);
          tempAddresses.push("ADDRESS ".concat(i + 1, ": ").concat(addressString));
        });
        return tempAddresses;
      }
    }
    function getBillingQuantity(operation) {
      if (operation && operation.billingQuantities && operation.billingQuantities.length) {
        return operation.billingQuantities[0].billingQuantity;
      }
      return "";
    }
    function getBillingUnit(operation) {
      if (operation && operation.billingUnit) {
        return operation.billingUnit;
      }
      return "";
    }
    function getMetaData(metaData) {
      var tempMetaData = [];
      if (metaData && metaData.length) {
        metaData.forEach(function (m) {
          var metaDataString = "".concat(m.title, ": ").concat(m.value ? m.value : 0, " ").concat(m.unit ? m.unit : "");
          tempMetaData.push(metaDataString);
        });
      }
      return tempMetaData;
    }
    function getOperators(operatorsList) {
      function getOperatorName(operator, operatorIndex) {
        var teamLeadString = operator.level == JobAccessLevelType.Primary ? "Team Lead" : "Operator";
        var operatorNameString = "OPERATOR ".concat(operatorIndex + 1, ": ").concat(operator.name ? operator.name : operator.businessName ? operator.businessName : "", " \n                                      (").concat(teamLeadString, ")");
        return operatorNameString;
      }
      function getOperatorMachine(operator, machineType) {
        var machineString = "None";
        switch (machineType) {
          case ResourceType.Vehicle:
            if (operator && operator.vehicle) {
              machineString = "".concat(operator.vehicle.title, " (").concat(operator.vehicle.make, ", ").concat(operator.vehicle.model, ")");
            }
            break;
          case ResourceType.Implement:
            if (operator && operator.implement) {
              machineString = "".concat(operator.implement.title, " (").concat(operator.implement.make, ", ").concat(operator.implement.model, ")");
            }
            break;
          default:
            devLogger().log("No machine assigned!");
        }
        return machineString;
      }
      var tempOperatorNames = [];
      if (operatorsList && operatorsList.length) {
        operatorsList.forEach(function (o, i) {
          var operatorName = getOperatorName(o, i);
          var operatorVehicle = getOperatorMachine(o, ResourceType.Vehicle);
          var operatorImplement = getOperatorMachine(o, ResourceType.Implement);
          var operatorString = "".concat(operatorName, ", Vehicle: ").concat(operatorVehicle, ", Implement: ").concat(operatorImplement, " ").concat(newLine);
          tempOperatorNames.push(operatorString);
        });
        return tempOperatorNames;
      }
    }
    function getFields(fieldsList) {
      var tempFields = [];
      if (fieldsList && fieldsList.length) {
        fieldsList.forEach(function (f) {
          var fieldString = "".concat(f.title, " (").concat(getConvertedAreaUnit(f.areaInSquareMeter), ")");
          tempFields.push(fieldString);
        });
      }
      return tempFields;
    }
    function getProducts(products) {
      var tempProducts = [];
      if (products && products.length) {
        products.forEach(function (p) {
          var productString = "".concat(p.title ? p.title : "", " - ").concat(p.usages && p.usages.length ? p.usages[0].usage : "", " ").concat(p.unit ? p.unit : "");
          tempProducts.push(productString);
        });
      }
      return tempProducts;
    }
    function getTimeRecords(timeRecords) {
      var timeRecordsList = [];
      if (timeRecords && timeRecords.length) {
        timeRecords.forEach(function (t, i) {
          var startTime = getDateTime(t.startedAt);
          var endTime = getDateTime(t.endedAt);
          var travelTime = getTimeDurationInMinutes(t.travelTimeInSeconds);
          var workTime = getTimeDurationInMinutes(t.workTimeInSeconds);
          var breakTime = getTimeDurationInMinutes(t.breakTimeInSeconds);
          var downTime = getTimeDurationInMinutes(t.downTimeInSeconds);
          var timeRecordString = "RECORD ".concat(i + 1, " Start time: ").concat(startTime, ", End time: ").concat(endTime, ", Travel time: ").concat(travelTime, ", Work time: ").concat(workTime, ", Break time: ").concat(breakTime, ", Down time: ").concat(downTime);
          timeRecordsList.push(timeRecordString + "\n\t");
        });
      }
      return timeRecordsList;
    }
    jobsData.forEach(function (j) {
      state.exportDataLength++;
      var reportObj = {
        number: j.number,
        customer: getUserName(j.customer),
        contractor: getUserName(j.contractor),
        scheduled_on: getDateTime(j.scheduledAt),
        created_on: getDateTime(j.createdOn),
        status: TeamJobStatusType[j.status],
        started_on: getDateTime(j.workStartedOn),
        completed_on: getDateTime(j.workCompletedOn),
        work_time_in_mins: getTimeDurationInMinutesWithActivityType(j.timeRecords, "workTimeInSeconds"),
        travel_time_in_mins: getTimeDurationInMinutesWithActivityType(j.timeRecords, "travelTimeInSeconds"),
        break_time_in_mins: getTimeDurationInMinutesWithActivityType(j.timeRecords, "breakTimeInSeconds"),
        down_time_in_mins: getTimeDurationInMinutesWithActivityType(j.timeRecords, "downTimeInSeconds"),
        operation: j.operation.title,
        addresses: j.addresses && j.addresses.length ? getAddresses(j.addresses).join(jobsDataSeparator) : "",
        notes: j.notes ? j.notes : "",
        billable_quantity: getBillingQuantity(j.operation),
        billing_unit: getBillingUnit(j.operation),
        extra_information: j.metaData && j.metaData.length ? getMetaData(j.metaData).join(jobsDataSeparator) : "",
        fields: j.fields && j.fields.length ? getFields(j.fields).join(jobsDataSeparator) : "",
        products: j.products && j.products.length ? getProducts(j.products).join(jobsDataSeparator) : "",
        operators: j.operators && j.operators.length ? getOperators(j.operators).join(jobsDataSeparator) : "",
        time_records: j.timeRecords && j.timeRecords.length ? getTimeRecords(j.timeRecords).join(jobsDataSeparator) : "",
        invoiced: j.invoiceNumber || j.invoiceUrl || j.isInvoiced ? jobsDataTrue : jobsDataFalse,
        approved_for_invoicing: j.isApprovedForInvoicing ? jobsDataTrue : jobsDataFalse
      };
      state.exportData.push(reportObj);
    });
    state.exportDataLoaded = state.exportData.length;
    state.exportDataOffset = state.exportDataLoaded;
  },
  removeIdsFromExportableData: function removeIdsFromExportableData(state) {
    var tempExportData = [];
    state.exportData.forEach(function (ed) {
      var edObj = ed;
      delete edObj.id;
      tempExportData.push(edObj);
    });
    state.exportData = tempExportData;
  },
  setUsersDataListForExport: function setUsersDataListForExport(state, usersData) {
    usersData.forEach(function (jb) {
      state.exportDataLength++;
      var obj = {
        name: jb.name,
        business_name: jb.businessName,
        email_address: jb.emailAddress,
        phone_number: jb.primaryPhoneNumber,
        onboarded_on: getDateTime(jb.createdOn),
        role: jb.roles.map(function (role) {
          return role + '';
        }).join(','),
        status: jb.isArchived ? 'Inactive' : 'Active',
        last_active: getDateTime(jb.lastActiveOn)
      };
      state.exportData.push(obj);
    });
    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },
  setBusinessesDataListForExport: function setBusinessesDataListForExport(state, businessesData) {
    function getSubscriptionText(business) {
      var subscriptionText = '';
      var unavailableText = 'NA';
      if (business.subscriptions.status !== null) {
        if (!business.subscriptions.cancellationScheduledOn) {
          if (business.subscriptions.status == SubscriptionStatusType.Trialing) {
            subscriptionText += getTrialDaysLeft(business.subscriptions.trialEnd);
          } else {
            if (business.subscriptions.status == SubscriptionStatusType.Active) {
              subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status) + ' ';
              subscriptionText += business.subscriptions.currentPeriodStart ? getDateTimeFormat(business.subscriptions.currentPeriodStart) : unavailableText;
              subscriptionText += ' - ';
              subscriptionText += business.subscriptions.currentPeriodEnd ? getDateTimeFormat(business.subscriptions.currentPeriodEnd) : unavailableText;
            } else if (business.subscriptions.status == SubscriptionStatusType.Cancelled) {
              subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status);
              subscriptionText += ' ';
              subscriptionText += getDateTimeFormattedLocal(business.subscriptions.cancelledAt);
            } else {
              subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status);
            }
          }
          if (business.subscriptions.status == SubscriptionStatusType.Trialing) {
            subscriptionText += ' ';
            subscriptionText += business.subscriptions.trialStart ? getDateTimeFormat(business.subscriptions.trialStart) : unavailableText;
            subscriptionText += ' - ';
            subscriptionText += business.subscriptions.trialEnd ? getDateTimeFormat(business.subscriptions.trialEnd) : unavailableText;
          }
        }
        subscriptionText += getCancellationDate(business.subscriptions);
      } else {
        if (business.subscriptions.trialStart || business.subscriptions.trialEnd) {
          subscriptionText += getTrialDaysLeft(business.subscriptions.trialEnd);
          subscriptionText += ' ';
          subscriptionText += business.subscriptions.trialStart ? getDateTimeFormat(business.subscriptions.trialStart) : unavailableText;
          subscriptionText += ' - ';
          subscriptionText += business.subscriptions.trialEnd ? getDateTimeFormat(business.subscriptions.trialEnd) : unavailableText;
        }
      }
      return subscriptionText;
    }
    businessesData.forEach(function (business) {
      state.exportDataLength++;
      var obj = {
        business_name: business.name,
        country: business.country,
        owner_name: business.ownerName,
        owner_contact: business.ownerContact,
        owner_type: business.businessType,
        onboarded_on: getDateTime(business.createdOn),
        jobs: business.totalJobs,
        active_members: "(".concat(business.teamMembers.active, "/").concat(business.teamMembers.total, ")"),
        subscriptions: getSubscriptionText(business),
        amount: getSusbcriptionAmountIfExists(business, false)
      };
      state.exportData.push(obj);
    });
    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },
  resetExportData: function resetExportData(state) {
    Object.assign(state, initialState());
  }
};