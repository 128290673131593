import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import TeamJobsComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-computed";
import { ButtonActionPrimary, HoverTextButton } from "@/components/shared/buttons/index";
import { routeInvoicesDetails, routeJobs } from "@/utils/endpoints";
import { getLoggedInUserRole, getOwnerId } from "@/utils/helpers/user-role-helpers";
import { getOperatorsWithChecklists } from "@/utils/helpers/jobs-helpers";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import router from "@/router/index";
import moment from "moment";
import { TeamJobTimeDetails } from "../../../data/models/TeamJobTimeDetails";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { ApprovalStatusType } from "@/enum/approvalStatusType";
import { DeleteItemDialog } from "@/components/shared/dialogs/index";
import ScrollerMixin from '@/mixins/scroller-mixin';
import { UserRoleType } from "@/enum/userRoleType";
import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
export default {
  name: "TeamJobsAdd",
  mixins: [TeamJobsComputed, TeamJobsCommon, ScrollerMixin],
  components: {
    ButtonActionPrimary: ButtonActionPrimary,
    DeleteItemDialog: DeleteItemDialog,
    HoverTextButton: HoverTextButton,
    SecondaryButton: SecondaryButton
  },
  data: function data() {
    return {
      dialogDeclineTeamJob: false,
      declineTeamJobReason: "",
      TeamJobStatusType: TeamJobStatusType,
      ApprovalStatusType: ApprovalStatusType,
      dialogForceFinish: false,
      UserRoleType: UserRoleType
    };
  },
  computed: {
    canCompleteJob: {
      get: function get() {
        if (this.teamJobUser) {
          var contractorOrItsManagerLoggedIn = getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn;
          var farmerOrItsManagerLoggedIn = getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn;
          var createdForSelf = getOwnerId() == this.teamJobUser.id;
          return contractorOrItsManagerLoggedIn || farmerOrItsManagerLoggedIn && createdForSelf;
        }
        return false;
      }
    },
    teamJobsResourcesLoading: function teamJobsResourcesLoading() {
      return this.teamJobsLoader || this.teamJobOperationsListLoader || this.teamJobUsersListLoader || this.teamJobProductsLoader || this.teamJobVehiclesListForOperatorsLoader || this.teamJobImplementsListForOperatorsLoader;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.routedFromJobDetails = true;
  },
  methods: {
    acceptTeamJob: function acceptTeamJob() {
      var _this = this;
      var resourceId = this.teamJobDetails.id;
      this.$store.dispatch("acceptTeamJobByContractor", resourceId).then(function (res) {
        if (res) {
          _this.resetTeamJobs();
          _this.mountTeamJobsDetails();
        }
      });
    },
    openDialogJobDecline: function openDialogJobDecline() {
      this.dialogDeclineTeamJob = true;
    },
    closeJobDecline: function closeJobDecline() {
      this.declineTeamJobReason = "";
      this.dialogDeclineTeamJob = false;
    },
    proceedToDeclineTeamJob: function proceedToDeclineTeamJob() {
      var _this2 = this;
      var obj = {
        resourceId: this.teamJobDetails.id,
        reason: {
          reason: this.declineTeamJobReason
        }
      };
      this.$store.dispatch("declineTeamJobByContractor", obj).then(function () {
        _this2.closeJobDecline();
        _this2.$router.back();
      });
    },
    openDialogToForceFinishTeamJob: function openDialogToForceFinishTeamJob() {
      this.dialogForceFinish = true;
    },
    isVisibleForceFinish: function isVisibleForceFinish() {
      if (this.teamJobDetails && this.teamJobDetails.status === TeamJobStatusType.InProgress) {
        if (this.teamJobOperators.length > 0) {
          var hasWorked = this.teamJobOperators.some(function (o) {
            return o.operatorDetails.workStatus !== null;
          });
          return hasWorked;
        }
      }
    },
    proceedToForceFinishTeamJob: function proceedToForceFinishTeamJob() {
      var _this3 = this;
      var resourceId = this.teamJobDetails.id;
      this.$store.dispatch("forceFinishTeamJob", resourceId).then(function (res) {
        if (res) {
          _this3.resetTeamJobs();
          _this3.mountTeamJobsDetails();
        }
      });
      this.dialogForceFinish = false;
    },
    getOperators: function getOperators() {
      return getOperatorsWithChecklists(this.teamJobOperation, this.teamJobOperators);
    },
    navigateToPreviousRoute: function navigateToPreviousRoute() {
      var _this4 = this;
      var jobsList = this.$store.state.teamJobsListModule.teamJobsList;
      var jobIndex = jobsList.findIndex(function (job) {
        return job.id === _this4.teamJobDetails.id;
      });
      if (jobIndex > -1) {
        this.$router.back();
      } else {
        this.$router.push(routeJobs);
      }
    },
    updateInProgressJob: function updateInProgressJob(actionType) {
      var _this5 = this;
      var timeRecords = [];
      var custId = null;
      if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
        custId = getOwnerId();
        if (this.teamJobUser) {
          this.teamJobContractorId = this.teamJobUser.id;
        } else {
          this.teamJobContractorId = getOwnerId();
        }
      } else if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
        this.teamJobContractorId = getOwnerId();
        custId = this.teamJobUser ? this.teamJobUser.id : getOwnerId();
      }
      var operatorsForTeamJob = this.teamJobOperators.length ? this.getOperators() : [];
      if (this.teamJobTimeDetailsList.length > 0) {
        this.teamJobTimeDetailsList.map(function (x) {
          timeRecords.push(new TeamJobTimeDetails().getTeamJobTimeDetailsRequest(x));
        });
      }
      var teamJobCompleteDto = {
        id: this.teamJobDetails.id,
        scheduledAt: this.teamJobScheduledDate != null ? moment.utc(moment(this.teamJobScheduledDate).utc()).format() : null,
        instructions: this.teamJobInstructions,
        customerId: custId,
        contractorId: this.teamJobContractorId,
        operation: {
          id: this.teamJobOperation ? this.teamJobOperation.id : null,
          billingQuantities: this.teamJobOperation ? this.teamJobOperation.billingQuantities : []
        },
        fields: this.teamJobSelectedFields.map(function (field) {
          return {
            id: field.id
          };
        }),
        operators: operatorsForTeamJob,
        metaData: this.teamJobMetaDataList,
        addresses: this.teamJobAddresses,
        products: this.teamJobProducts,
        attachments: this.teamJobAttachments,
        timeRecords: timeRecords,
        notes: this.teamJobNotes
      };
      this.$store.dispatch(actionType, teamJobCompleteDto).then(function (res) {
        if (res) {
          _this5.resetTeamJobs();
          _this5.mountTeamJobsDetails();
        }
      });
    },
    resetDetailsPage: function resetDetailsPage() {
      this.resetTeamJobs();
      this.mountTeamJobsDetails();
    },
    generateReport: function generateReport() {
      var _this6 = this;
      this.invoiceReportGenerationInProgress = true;
      this.generatedFromJobDetails = true;
      this.$store.dispatch("generateInvoiceReportUrls", this.teamJobDetails.id).then(function () {
        _this6.invoiceReportGenerationInProgress = false;
        _this6.generatedFromJobDetails = false;
      });
    },
    approveJobForInvoicing: function approveJobForInvoicing() {
      var _this7 = this;
      this.teamJobsLoader = true;
      this.$store.dispatch('approveJobForInvoicing', this.teamJobDetails.id).then(function () {
        _this7.resetTeamJobs();
        _this7.mountTeamJobsDetails();
      });
    },
    handleViewInvoice: function handleViewInvoice() {
      var _this$teamJobDetails$ = this.teamJobDetails.invoiceReference,
        service = _this$teamJobDetails$.service,
        id = _this$teamJobDetails$.id;
      if (service === 0) {
        this.$store.state.isRoutedFromReferencePage = true;
        router.push(routeInvoicesDetails + id);
      } else {
        this.$store.dispatch('getInvoiceUrl', {
          id: id,
          service: service
        }).then(function (response) {
          return response && window.open(response, '_blank');
        });
      }
    }
  }
};