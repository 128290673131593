import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import StringConstants from '@/plugins/stringConstants';
import { getDateDifferenceInDays } from '@/utils/helpers';
export var state = {
  cardsList: [],
  noCards: true,
  showAddCardSubscriptionDialog: false,
  showDefaultCardConfirmDialog: false,
  subscriptionMembersSize: 0,
  cardsLoader: false,
  subscriptionPlanLoader: false,
  cardSourceToken: null,
  stripePublishKey: null,
  cardIdToSelect: null,
  cardBrandToSelect: null,
  cardLastFourToSelect: null,
  subscriptionDetails: null,
  subscriptionStatus: null,
  defaultTrialPeriodInDays: 0,
  subscriptionPlanTypes: {
    0: {
      flatPrice: null,
      maxUnits: null,
      pricePerUnit: null,
      title: 'Small team'
    },
    1: {
      flatPrice: null,
      maxUnits: null,
      pricePerUnit: null,
      title: 'Medium team'
    },
    2: {
      flatPrice: null,
      maxUnits: null,
      pricePerUnit: null,
      title: 'Large team'
    },
    3: {
      flatPrice: null,
      maxUnits: null,
      pricePerUnit: null,
      title: 'Very large team'
    }
  },
  dialogCancelSubscription: false,
  cancellationScheduledOn: null,
  subscriptionPromocode: null,
  subscriptionPromocodeApplied: false,
  subscriptionSuccessDescription: StringConstants.subscriptionPromoCodeActivateSuccessText,
  promoCodeLoader: false,
  promotionalCodeId: null,
  pricingLoader: false,
  subscriptionInvoicesList: [],
  subscriptionCancelReason: null,
  enableCardAddButton: false
};
export var getters = {
  cardsList: function cardsList(state) {
    return state.cardsList;
  },
  noCards: function noCards(state) {
    return state.noCards;
  },
  showAddCardSubscriptionDialog: function showAddCardSubscriptionDialog(state) {
    return state.showAddCardSubscriptionDialog;
  },
  subscriptionMembersSize: function subscriptionMembersSize(state) {
    return state.subscriptionMembersSize;
  },
  cardsLoader: function cardsLoader(state) {
    return state.cardsLoader;
  },
  cardSourceToken: function cardSourceToken(state) {
    return state.cardSourceToken;
  },
  stripePublishKey: function stripePublishKey(state) {
    return state.stripePublishKey;
  },
  cardIdToSelect: function cardIdToSelect(state) {
    return state.cardIdToSelect;
  },
  cardBrandToSelect: function cardBrandToSelect(state) {
    return state.cardBrandToSelect;
  },
  cardLastFourToSelect: function cardLastFourToSelect(state) {
    return state.cardLastFourToSelect;
  },
  showDefaultCardConfirmDialog: function showDefaultCardConfirmDialog(state) {
    return state.showDefaultCardConfirmDialog;
  },
  subscriptionDetails: function subscriptionDetails(state) {
    return state.subscriptionDetails;
  },
  subscriptionPlanLoader: function subscriptionPlanLoader(state) {
    return state.subscriptionPlanLoader;
  },
  subscriptionStatus: function subscriptionStatus(state) {
    return state.subscriptionStatus;
  },
  defaultTrialPeriodInDays: function defaultTrialPeriodInDays(state) {
    return state.defaultTrialPeriodInDays;
  },
  subscriptionPlanTypes: function subscriptionPlanTypes(state) {
    return state.subscriptionPlanTypes;
  },
  dialogCancelSubscription: function dialogCancelSubscription(state) {
    return state.dialogCancelSubscription;
  },
  cancellationScheduledOn: function cancellationScheduledOn(state) {
    return state.cancellationScheduledOn;
  },
  subscriptionPromocode: function subscriptionPromocode(state) {
    return state.subscriptionPromocode;
  },
  subscriptionPromocodeApplied: function subscriptionPromocodeApplied(state) {
    return state.subscriptionPromocodeApplied;
  },
  promoCodeLoader: function promoCodeLoader(state) {
    return state.promoCodeLoader;
  },
  promotionalCodeId: function promotionalCodeId(state) {
    return state.promotionalCodeId;
  },
  subscriptionSuccessDescription: function subscriptionSuccessDescription(state) {
    return state.subscriptionSuccessDescription;
  },
  pricingLoader: function pricingLoader(state) {
    return state.pricingLoader;
  },
  subscriptionInvoicesList: function subscriptionInvoicesList(state) {
    return state.subscriptionInvoicesList;
  },
  subscriptionCancelReason: function subscriptionCancelReason(state) {
    return state.subscriptionCancelReason;
  },
  enableCardAddButton: function enableCardAddButton(state) {
    return state.enableCardAddButton;
  }
};
export var mutations = {
  setCardsList: function setCardsList(state, data) {
    state.cardsList = [];
    data.map(function (card) {
      return state.cardsList.push(card);
    });
    state.noCards = false;
  },
  setSubscriptionPricing: function setSubscriptionPricing(state, data) {
    state.defaultTrialPeriodInDays = data.defaultTrialPeriodInDays;
    data.pricingTiers.map(function (tier, index) {
      state.subscriptionPlanTypes[index].flatPrice = tier.flatPrice;
      state.subscriptionPlanTypes[index].pricePerUnit = tier.pricePerUnit;
      state.subscriptionPlanTypes[index].maxUnits = tier.maxUnits;
    });
  },
  setClearCardsList: function setClearCardsList(state) {
    state.cardsList = [];
  },
  setSubscriptionInvoicesList: function setSubscriptionInvoicesList(state, data) {
    state.subscriptionInvoicesList = data;
  },
  setNoCardsStatus: function setNoCardsStatus(state, cardsListLength) {
    state.noCards = cardsListLength > 0 ? false : true;
  },
  setClearUpdateSubscriptionDialog: function setClearUpdateSubscriptionDialog(state) {
    state.subscriptionMembersSize = 0;
  },
  setStripePublishKey: function setStripePublishKey(state, data) {
    state.stripePublishKey = data;
  },
  setSubscriptionDetails: function setSubscriptionDetails(state, data) {
    state.subscriptionDetails = data;
    state.subscriptionStatus = data.status;
    state.subscriptionMembersSize = data.quantity;
    state.cancellationScheduledOn = data.cancellationScheduledOn;
    localStorage.setItem('subscriptionStatus', data.status);
    localStorage.setItem('trialDaysRemaining', getDateDifferenceInDays(data.trailEndOn));
  },
  setPromoCodeDetails: function setPromoCodeDetails(state, data) {
    if (data.id) {
      state.promotionalCodeId = data.id;
      state.subscriptionPromocodeApplied = true;
    }
  },
  setClearSubscriptionDetails: function setClearSubscriptionDetails(state) {
    state.subscriptionPlanLoader = false;
    state.subscriptionDetails = null;
    state.subscriptionStatus = null;
    state.cardsList = [];
    state.subscriptionStatus = null;
    state.dialogCancelSubscription = false;
    state.cancellationScheduledOn = null;
    state.subscriptionCancelReason = null;
  },
  setClearPromoCodeInfo: function setClearPromoCodeInfo(state) {
    state.subscriptionPromocode = null;
    state.subscriptionPromocodeApplied = false;
    state.promoCodeLoader = false;
    state.promotionalCodeId = null;
    state.subscriptionSuccessDescription = StringConstants.subscriptionPromoCodeActivateSuccessText;
  }
};