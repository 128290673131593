import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "overlay" } }),
    _c(
      "div",
      { staticClass: "invoice-tabs h-percent-100 d-flex flex-column" },
      [
        _c(
          VTabs,
          {
            staticClass: "flex-grow-0",
            staticStyle: { "border-bottom": "2px solid #eee" },
            attrs: { "background-color": "#ffffff" },
            model: {
              value: _vm.activeInvoiceTab,
              callback: function ($$v) {
                _vm.activeInvoiceTab = $$v
              },
              expression: "activeInvoiceTab",
            },
          },
          [
            _c(VTab, { staticClass: "ml-9" }, [
              _vm._v(" " + _vm._s(_vm.$stringConstants("internalText")) + " "),
            ]),
            !_vm.loadingIntegration
              ? _c(VTab, { staticClass: "ml-9" }, [
                  _vm._v(
                    " " + _vm._s(_vm.selectedInvoiceTabTextExternal) + " "
                  ),
                ])
              : _vm._e(),
            _vm.loadingIntegration
              ? _c(
                  VTab,
                  { staticClass: "ml-9" },
                  [
                    _c(VProgressCircular, {
                      attrs: {
                        width: 2,
                        size: 25,
                        color: _vm.brandColor,
                        indeterminate: "",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          VTabsItems,
          {
            staticClass: "flex-grow-1",
            model: {
              value: _vm.activeInvoiceTab,
              callback: function ($$v) {
                _vm.activeInvoiceTab = $$v
              },
              expression: "activeInvoiceTab",
            },
          },
          _vm._l(_vm.invoiceTabTexts, function (item, index) {
            return _c(VTabItem, { key: index }, [
              index == 0
                ? _c("div", [_c("InvoicesListComponent")], 1)
                : _vm._e(),
              index == 1
                ? _c("div", [_c("InvoicesListExternalComponent")], 1)
                : _vm._e(),
            ])
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "position-relative sidebar-overlay invoiceJobsPreviewFlyout",
        staticStyle: { "min-width": "550px !important" },
        attrs: { id: "invoiceJobsPreviewFlyout" },
      },
      [_c("InvoiceJobsPreviewFlyout")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }