import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import ConstantValues from '@/plugins/constantValues';
import { BusinessProfile } from '@/data/models/BusinessProfile';
import { User } from '@/data/models';
export var state = {
  usersLoader: false,
  userProfile: new User(),
  userSignUpRoles: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4
  },
  vehiclesForUserProfileSearchText: null,
  vehiclesListForUserProfile: [],
  implementsForUserProfileSearchText: null,
  implementsListForUserProfile: [],
  inviteeProfile: {
    code: null,
    inviterName: null,
    inviteeName: null,
    inviteeEmail: null,
    inviteeRole: null,
    timestamp: null,
    expiry: null,
    inviter: null
  },
  userInviteeEmail: null,
  userInviteeRole: 4,
  userInviteeAcceptanceCode: null,
  invitationType: {
    invitation: "Invitation",
    verification: "Verification"
  },
  startingUserLatLngOnMap: null,
  map: null,
  startingLat: ConstantValues.defaultBusinessLatitude,
  startingLng: ConstantValues.defaultBusinessLongitude,
  businessProfilePrefixTemp: null,
  accountBusinessProfile: new BusinessProfile(),
  accountTabTexts: ['Profile', 'Business', 'Farms'],
  accountFarmAddresses: [],
  accountMapSearchValue: null,
  dialogInvitation: false,
  invitationId: null,
  connectionRequestType: null,
  dialogType: null,
  shouldCallOnboardUpdate: false
};
export var getters = {
  usersLoader: function usersLoader(state) {
    return state.usersLoader;
  },
  userProfile: function userProfile(state) {
    return state.userProfile;
  },
  userSignUpRoles: function userSignUpRoles(state) {
    return state.userSignUpRoles;
  },
  vehiclesForUserProfileSearchText: function vehiclesForUserProfileSearchText(state) {
    return state.vehiclesForUserProfileSearchText;
  },
  vehiclesListForUserProfile: function vehiclesListForUserProfile(state) {
    return state.vehiclesListForUserProfile;
  },
  implementsForUserProfileSearchText: function implementsForUserProfileSearchText(state) {
    return state.implementsForUserProfileSearchText;
  },
  implementsListForUserProfile: function implementsListForUserProfile(state) {
    return state.implementsListForUserProfile;
  },
  inviteeProfile: function inviteeProfile(state) {
    return state.inviteeProfile;
  },
  userInviteeEmail: function userInviteeEmail(state) {
    return state.userInviteeEmail;
  },
  userInviteeRole: function userInviteeRole(state) {
    return state.userInviteeRole;
  },
  userInviteeAcceptanceCode: function userInviteeAcceptanceCode(state) {
    return state.userInviteeAcceptanceCode;
  },
  invitationType: function invitationType(state) {
    return state.invitationType;
  },
  startingUserLatLngOnMap: function startingUserLatLngOnMap(state) {
    return state.startingUserLatLngOnMap;
  },
  map: function map(state) {
    return state.map;
  },
  startingLat: function startingLat(state) {
    return state.startingLat;
  },
  startingLng: function startingLng(state) {
    return state.startingLng;
  },
  businessProfilePrefixTemp: function businessProfilePrefixTemp(state) {
    return state.businessProfilePrefixTemp;
  },
  accountBusinessProfile: function accountBusinessProfile(state) {
    return state.accountBusinessProfile;
  },
  accountTabTexts: function accountTabTexts(state) {
    return state.accountTabTexts;
  },
  accountFarmAddresses: function accountFarmAddresses(state) {
    return state.accountFarmAddresses;
  },
  accountMapSearchValue: function accountMapSearchValue(state) {
    return state.accountMapSearchValue;
  },
  dialogInvitation: function dialogInvitation(state) {
    return state.dialogInvitation;
  },
  invitationId: function invitationId(state) {
    return state.invitationId;
  },
  connectionRequestType: function connectionRequestType(state) {
    return state.connectionRequestType;
  },
  dialogType: function dialogType(state) {
    return state.dialogType;
  },
  shouldCallOnboardUpdate: function shouldCallOnboardUpdate(state) {
    return state.shouldCallOnboardUpdate;
  }
};
export var mutations = {
  setUserProfile: function setUserProfile(state, data) {
    var _a, _b, _c;
    state.userProfile = new User().getUserProfileDetails(data);
    if (data.businessProfile != null) {
      if (data.businessProfile.config) {
        localStorage.setItem('defaultAreaMeasurementUnit', data.businessProfile.config.defaultAreaMeasurementUnit);
        state.startingLat = data.businessProfile.config.defaultLatitude == 0 ? ConstantValues.defaultBusinessLatitude : data.businessProfile.config.defaultLatitude;
        state.startingLng = data.businessProfile.config.defaultLongitude == 0 ? ConstantValues.defaultBusinessLongitude : data.businessProfile.config.defaultLongitude;
        if ((_b = (_a = data.businessProfile.config) === null || _a === void 0 ? void 0 : _a.currency) === null || _b === void 0 ? void 0 : _b.symbol) {
          localStorage.setItem('currencySymbol', data.businessProfile.config.currency.symbol);
        }
        if ((_c = data.businessProfile.address) === null || _c === void 0 ? void 0 : _c.country) {
          localStorage.setItem('country', data.businessProfile.address.country);
        }
        localStorage.setItem('defaultStartingLat', state.startingLat);
        localStorage.setItem('defaultStartingLng', state.startingLng);
      } else {
        state.startingLat = ConstantValues.defaultBusinessLatitude;
        state.startingLng = ConstantValues.defaultBusinessLongitude;
        localStorage.setItem('defaultStartingLat', state.startingLat);
        localStorage.setItem('defaultStartingLng', state.startingLng);
      }
    }
  },
  setClearUserProfile: function setClearUserProfile(state) {
    state.userProfile = new User();
  },
  setVehiclesListForUserProfile: function setVehiclesListForUserProfile(state, data) {
    data.map(function (x) {
      var hasVehicle = false;
      state.vehiclesListForUserProfile.map(function (y) {
        if (y.id == x.id) {
          hasVehicle = true;
        }
      });
      if (!hasVehicle) {
        state.vehiclesListForUserProfile.push(x);
      }
    });
  },
  setImplementsListForUserProfile: function setImplementsListForUserProfile(state, data) {
    data.map(function (x) {
      var hasImplement = false;
      state.implementsListForUserProfile.map(function (y) {
        if (y.id == x.id) {
          hasImplement = true;
        }
      });
      if (!hasImplement) {
        state.implementsListForUserProfile.push(x);
      }
    });
  },
  setInviteeProfile: function setInviteeProfile(state, data) {
    state.inviteeProfile.code = data.code;
    state.inviteeProfile.inviterName = data.inviterName;
    state.inviteeProfile.inviteeName = data.inviteeFirstName + " " + data.inviteeLastName;
    state.inviteeProfile.inviteeEmail = data.inviteeEmail;
    state.inviteeProfile.inviteeRole = data.inviteeRole;
    state.inviteeProfile.timestamp = data.timestamp;
    state.inviteeProfile.expiry = data.expiry;
    state.inviteeProfile.inviter = data.inviter;
  },
  setClearInviteeProfile: function setClearInviteeProfile(state, data) {
    state.userInviteeEmail = null;
    state.userInviteeRole = 4;
  },
  setAccountBusinessProfile: function setAccountBusinessProfile(state, data) {
    state.accountBusinessProfile = new BusinessProfile().getBusinessProfileDetails(data);
    if (data.config) {
      state.startingLat = data.config.defaultLatitude == 0 ? ConstantValues.defaultBusinessLatitude : data.config.defaultLatitude;
      state.startingLng = data.config.defaultLongitude == 0 ? ConstantValues.defaultBusinessLongitude : data.config.defaultLongitude;
      localStorage.setItem('currencySymbol', data.config.currency.symbol);
      localStorage.setItem('country', data.address.country);
      localStorage.setItem("defaultStartingLat", state.startingLat);
      localStorage.setItem("defaultStartingLng", state.startingLng);
    } else {
      state.startingLat = ConstantValues.defaultBusinessLatitude;
      state.startingLng = ConstantValues.defaultBusinessLongitude;
    }
  },
  setAccountFarmAddresses: function setAccountFarmAddresses(state, data) {
    state.accountFarmAddresses = [];
    state.accountFarmAddresses = data;
  },
  clearUserProfile: function clearUserProfile(state) {
    state.usersLoader = false;
    state.userProfile = new User();
  },
  setUserTimeZone: function setUserTimeZone(state, data) {
    state.userProfile.businessProfile.config.timezone = data || null;
  }
};