import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import $ from 'jquery';
import moment from 'moment';
import { notify, openOverlay } from '@/utils/helpers';
import { defaultScrollViewHeight } from '@/utils/uiconstants';
import { mapMutations } from 'vuex';
import permissionsMixin from '@/mixins/permissions-mixin';
import scrollerMixin from "@/mixins/scroller-mixin";
import filterParamsMixin from '@/mixins/filter-params-mixin';
import skeletonLoaderMixin from '@/mixins/skeleton-loader-mixin';
import Avatar from '@/components/shared/avatars/avatar.vue';
import teamMembersComputedMixin from '@/components/team-members/team-members-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { DeleteItemDialog as ActionItemDialog } from '@/components/shared/dialogs/index';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import { DeleteItemDialog as ItemDialog, ActionListingDialog } from "@/components/shared/dialogs/index";
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import { listAction } from '@/enum/listAction';
import Snackbar from "@/components/shared/snackbar/snackbar-listing.vue";
import { DeleteItemDialog as ChangeRoleDialog } from '@/components/shared/dialogs/index';
import { UserRoleType } from '@/enum/userRoleType';
export default {
  name: "TeamMembersListView",
  mixins: [permissionsMixin, scrollerMixin, filterParamsMixin, teamMembersComputedMixin, skeletonLoaderMixin, Timers],
  components: {
    Avatar: Avatar,
    Button: Button,
    ActionItemDialog: ActionItemDialog,
    ItemDialog: ItemDialog,
    ActionListingDialog: ActionListingDialog,
    List: List,
    Snackbar: Snackbar,
    ChangeRoleDialog: ChangeRoleDialog
  },
  data: function data() {
    return {
      selectedTeamMemberId: null,
      dialogTeamMember: false,
      defaultScrollViewHeight: defaultScrollViewHeight,
      archiveDialog: false,
      restoreDialog: false,
      searchTimer: null,
      actionType: listAction.Restore,
      isChecked: false,
      ViewPreferenceType: ViewPreferenceType,
      listAction: listAction,
      showChangeRole: false,
      selectedTeamMemberRole: UserRoleType.Manager
    };
  },
  watch: {
    selectedItems: function selectedItems(val) {
      if (this.selectedItems.length > 0) {
        this.snackbarShow = true;
      } else {
        this.snackbarShow = false;
        this.isChecked = false;
      }
    }
  },
  computed: {
    someTeamMembersSelected: function someTeamMembersSelected() {
      return this.selectedItems.length > 0 && !this.allTeamMembersSelected;
    },
    allTeamMembersSelected: function allTeamMembersSelected() {
      if (this.teamMemberSearchText) {
        return false;
      } else {
        return this.selectedItems.length == this.teamMembersList.length;
      }
    }
  },
  beforeMount: function beforeMount() {
    this.clearAndResetTeamMembersPagingStates();
  },
  beforeDestroy: function beforeDestroy() {
    this.clearAndResetTeamMembersPagingStates();
  },
  mounted: function mounted() {
    this.getTeamMembersList();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearTeamMemberDetailsState: "resetTeamMemberDetails",
    clearTeamMemberFilterOptions: "setClearFilterOptions",
    setClearInviteeProfile: "setClearInviteeProfile",
    resetPaging: "resetPaging",
    resetSnackbarDetails: "clearSnackbarDetails"
  })), {}, {
    getTeamMembersList: function getTeamMembersList() {
      if (!this.$store.state.isPageRelated) {
        localStorage.removeItem('memberSearchQuery');
        localStorage.removeItem('memberType');
      }
      this.getUrlParamsTeamMembersLocal();
      this.setFilterOptionsAndGetTeamMembers();
    },
    setUrlParamsTeamMembersLocal: function setUrlParamsTeamMembersLocal() {
      this.setLocalParamsMx(this.teamMemberSearchText, 'memberSearchQuery');
      this.setLocalParamsMx(this.memberFilterType, 'memberType');
    },
    getUrlParamsTeamMembersLocal: function getUrlParamsTeamMembersLocal() {
      this.teamMemberSearchText = this.getLocalParamsMx('memberSearchQuery', '');
      this.memberFilterType = parseInt(this.getLocalParamsMx('memberType', 0));
    },
    openAddTeamMemberForm: function openAddTeamMemberForm() {
      this.clearTeamMemberDetailsState();
      this.setClearInviteeProfile();
      openOverlay("addTeamMemberFormOverlay");
    },
    openEditTeamMemberForm: function openEditTeamMemberForm(teamMemberId) {
      this.clearTeamMemberDetailsState();
      this.$store.dispatch('getTeamMember', teamMemberId);
      openOverlay("editTeamMemberFormOverlay");
    },
    applySearchTeamMembersFilter: function applySearchTeamMembersFilter() {
      this.clearTeamMemberFilterOptions();
      this.resetPaging();
      this.teamMembersList = [];
      this.pendingTeamMemberList = [];
      this.filtersApplied = true;
      this.setUrlParamsTeamMembersLocal();
      this.setFilterOptionsAndGetTeamMembers();
    },
    promtArchiveTeamMember: function promtArchiveTeamMember(teamMemberId) {
      this.archiveDialog = true;
      this.selectedTeamMemberId = teamMemberId;
    },
    proceedTeamMemberArchiving: function proceedTeamMemberArchiving(id) {
      var _this = this;
      this.archiveDialog = false;
      this.$store.dispatch('archiveTeamMember', this.selectedTeamMemberId).then(function (res) {
        if (res) {
          _this.selectedTeamMemberId = '';
        }
      });
    },
    proceedTeamMemberRestoration: function proceedTeamMemberRestoration(id) {
      var _this2 = this;
      this.restoreDialog = false;
      this.$store.dispatch('restoreTeamMember', this.selectedTeamMemberId).then(function (res) {
        _this2.selectedTeamMemberId = '';
      });
    },
    cancelTeamMemberArchiving: function cancelTeamMemberArchiving() {
      this.archiveDialog = false;
      this.restoreDialog = false;
      this.selectedTeamMemberId = '';
    },
    getDateTimeFormat: function getDateTimeFormat(date) {
      return moment.utc(date).local().format('DD MMM YYYY');
    },
    getMembersListByStatus: function getMembersListByStatus() {
      var _this3 = this;
      this.applySearchTeamMembersFilter();
      this.setColumnWidths([]);
      this.$nextTick(function () {
        _this3.calculateColumnWidths(_this3.$refs.listHeader);
      });
    },
    promtRestoreTeamMember: function promtRestoreTeamMember(id) {
      this.restoreDialog = true;
      this.selectedTeamMemberId = id;
    },
    clearAndResetTeamMembersPagingStates: function clearAndResetTeamMembersPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.teamMembersModule.teamMembersList = [];
      this.$store.state.teamMembersModule.pendingTeamMemberList = [];
      this.clearTeamMemberDetailsState();
      this.clearTeamMemberFilterOptions();
      this.resetPaging();
      this.resetSnackbarDetails();
      this.memberFilterType = 0;
      this.filtersApplied = false;
    },
    getTimeInitials: function getTimeInitials(dateTime) {
      var crDate = moment().format('YYYY-MM-DD');
      var time = moment.utc(dateTime).format("HH:mm:ss");
      var shortTime = moment.utc(crDate + " " + time).local().format("LT");
      return shortTime;
    },
    deleteInviteConnection: function deleteInviteConnection(code) {
      var _this4 = this;
      this.$store.dispatch('deleteSentConReq', code).then(function (res) {
        if (res) {
          notify(_this4.$stringConstants('invitationRequestDeleted'));
          _this4.pendingTeamMemberList = [];
          _this4.$store.dispatch('fnSetTeamMembersFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (res) {
            _this4.$store.dispatch('getPendingTeamMembers');
          });
        }
      });
    },
    setFilterOptionsAndGetTeamMembers: function setFilterOptionsAndGetTeamMembers() {
      var _this5 = this;
      var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      if (!this.teamMemberSearchText) {
        this.clearTeamMemberFilterOptions();
        this.filtersApplied = false;
      }
      this.$store.dispatch('fnSetTeamMembersFilter', filters).then(function (res) {
        if (_this5.memberFilterType === 0 || _this5.memberFilterType === 1) {
          _this5.$store.dispatch('getTeamMembers');
        } else if (_this5.memberFilterType === 2) {
          _this5.$store.dispatch('getPendingTeamMembers');
        }
      });
    },
    fnGetHeaderString: function fnGetHeaderString(actionType) {
      switch (actionType) {
        case listAction.Delete:
          return this.$stringConstants('teamMemberDeletePromtTitle');
        case listAction.Archive:
          return this.$stringConstants('teamMemberArchivePromtTitle');
        case listAction.Restore:
          return this.$stringConstants('teamMemberRestorePromtTitle');
      }
    },
    fnGetBodyString: function fnGetBodyString(actionType) {
      switch (actionType) {
        case listAction.Delete:
          return this.$stringConstants('teamMemberDeletePromtSubtitle');
        case listAction.Archive:
          return this.$stringConstants('teamMemberArchivePromtSubtitle');
        case listAction.Restore:
          return this.$stringConstants('teamMemberRestorePromtSubtitle');
      }
    },
    selectAllTeamMembers: function selectAllTeamMembers() {
      var _this6 = this;
      this.selectedItems = [];
      this.allItemsDeleted = false;
      if (this.isChecked) {
        this.teamMembersList.map(function (pro) {
          _this6.selectedItems.push(pro.id);
        });
      }
    },
    cancelActionOnTeamMember: function cancelActionOnTeamMember(actionType) {
      this.dialogTeamMember = false;
      this.selectedTeamMemberId = "";
    },
    proceedActionOnTeamMember: function proceedActionOnTeamMember(actionType) {
      this.dialogTeamMember = false;
      this.resetSnackbarDetails();
      this.$store.dispatch("actionOnTeamMember", [actionType, this.deleteFarmerId]);
    },
    changeRole: function changeRole() {
      this.showChangeRole = true;
    },
    cancelChangeRoleDialog: function cancelChangeRoleDialog() {
      this.showChangeRole = false;
    },
    changeRoleAsync: function changeRoleAsync() {
      var _this7 = this;
      this.clearTeamMemberFilterOptions();
      this.showChangeRole = false;
      var $globalStore = this.$store;
      var resetSnackbarAndListStates = function resetSnackbarAndListStates() {
        _this7.resetSnackbarDetails();
        $globalStore.state.teamMembersModule.teamMembersList = [];
        _this7.clearAndResetPagingStates();
        _this7.getTeamMembersList();
        _this7.selectedTeamMemberRole = UserRoleType.Manager;
      };
      if (!this.allItemsDeleted) {
        this.selectedItems.map(function (item) {
          $globalStore.commit("setFilterOptions", [ConstantValues.queryIdentifiers, item]);
        });
      }
      $globalStore.dispatch("updateTeamMembersRole", {
        newRole: this.selectedTeamMemberRole,
        allSelected: this.allItemsDeleted
      }).then(function () {
        resetSnackbarAndListStates();
      });
    },
    clearAndResetPagingStates: function clearAndResetPagingStates() {
      this.clearTeamMemberFilterOptions();
      this.resetPaging();
      this.resetSnackbarDetails();
    }
  })
};