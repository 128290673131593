import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
export var state = {
  operationsLoader: false,
  operationTypesList: [],
  addOperationTypesList: [],
  requestOperationTypesList: [],
  operationsList: [],
  operationSearchText: '',
  selectedSubOperationId: null,
  selectedSubOperationName: null,
  showAddOperationFields: false,
  showAddLoadInfoFields: false,
  showAddCheckListItemFields: false,
  showAddOperationBtn: true,
  showAddLoadInfoBtn: true,
  showAddCheckListItemBtn: true,
  subOperationTypeId: '',
  subOperationName: '',
  subOperationBillingCost: '',
  subOperationBillingUnit: null,
  subOperationTaxPercentage: 0.00,
  subOperationIsTaxable: false,
  subOperationVehicles: [],
  subOperationImplements: [],
  subOperationProducts: [],
  subOperationLoadInfoList: [],
  subOperationDescription: '',
  subOperationCheckListItems: [],
  subOperationVehiclesSearchText: '',
  subOperationImplementsSearchText: '',
  subOperationProductsSearchText: '',
  subOperationVehiclesList: [],
  subOperationImplementsList: [],
  subOperationProductsList: [],
  subOperationIsHealthSafetyListMandatory: false,
  addSubOperationLoader: false,
  editSubOperationLoader: false,
  subOperationLoader: false,
  seedOperationsLoader: false,
  subOperationVehiclesDropdownLoader: false,
  subOperationImplementsDropdownLoader: false,
  subOperationProductsDropdownLoader: false,
  subOperationVehiclesListSize: 0,
  subOperationImplementsListSize: 0,
  subOperationProductsListSize: 0,
  subOperationTaxWarningVisible: false
};
export var getters = {
  operationsLoader: function operationsLoader(state) {
    return state.operationsLoader;
  },
  operationTypesList: function operationTypesList(state) {
    return state.operationTypesList;
  },
  operationsList: function operationsList(state) {
    return state.operationsList;
  },
  operationSearchText: function operationSearchText(state) {
    return state.operationSearchText;
  },
  operationsListColumnTitles: function operationsListColumnTitles(state) {
    return state.operationsListColumnTitles;
  },
  selectedSubOperationId: function selectedSubOperationId(state) {
    return state.selectedSubOperationId;
  },
  subOperationTypeId: function subOperationTypeId(state) {
    return state.subOperationTypeId;
  },
  selectedSubOperationName: function selectedSubOperationName(state) {
    return state.selectedSubOperationName;
  },
  showAddOperationFields: function showAddOperationFields(state) {
    return state.showAddOperationFields;
  },
  showAddLoadInfoFields: function showAddLoadInfoFields(state) {
    return state.showAddLoadInfoFields;
  },
  showAddCheckListItemFields: function showAddCheckListItemFields(state) {
    return state.showAddCheckListItemFields;
  },
  showAddOperationBtn: function showAddOperationBtn(state) {
    return state.showAddOperationBtn;
  },
  showAddLoadInfoBtn: function showAddLoadInfoBtn(state) {
    return state.showAddLoadInfoBtn;
  },
  showAddCheckListItemBtn: function showAddCheckListItemBtn(state) {
    return state.showAddCheckListItemBtn;
  },
  addOperationTypesList: function addOperationTypesList(state) {
    return state.addOperationTypesList;
  },
  requestOperationTypesList: function requestOperationTypesList(state) {
    return state.requestOperationTypesList;
  },
  subOperationName: function subOperationName(state) {
    return state.subOperationName;
  },
  subOperationBillingCost: function subOperationBillingCost(state) {
    return state.subOperationBillingCost;
  },
  subOperationBillingUnit: function subOperationBillingUnit(state) {
    return state.subOperationBillingUnit;
  },
  subOperationTaxPercentage: function subOperationTaxPercentage(state) {
    return state.subOperationTaxPercentage;
  },
  subOperationIsTaxable: function subOperationIsTaxable(state) {
    return state.subOperationIsTaxable;
  },
  subOperationVehicles: function subOperationVehicles(state) {
    return state.subOperationVehicles;
  },
  subOperationImplements: function subOperationImplements(state) {
    return state.subOperationImplements;
  },
  subOperationProducts: function subOperationProducts(state) {
    return state.subOperationProducts;
  },
  subOperationLoadInfoList: function subOperationLoadInfoList(state) {
    return state.subOperationLoadInfoList;
  },
  subOperationDescription: function subOperationDescription(state) {
    return state.subOperationDescription;
  },
  subOperationCheckListItems: function subOperationCheckListItems(state) {
    return state.subOperationCheckListItems;
  },
  subOperationVehiclesSearchText: function subOperationVehiclesSearchText(state) {
    return state.subOperationVehiclesSearchText;
  },
  subOperationImplementsSearchText: function subOperationImplementsSearchText(state) {
    return state.subOperationImplementsSearchText;
  },
  subOperationProductsSearchText: function subOperationProductsSearchText(state) {
    return state.subOperationProductsSearchText;
  },
  subOperationVehiclesList: function subOperationVehiclesList(state) {
    return state.subOperationVehiclesList;
  },
  subOperationImplementsList: function subOperationImplementsList(state) {
    return state.subOperationImplementsList;
  },
  subOperationProductsList: function subOperationProductsList(state) {
    return state.subOperationProductsList;
  },
  subOperationIsHealthSafetyListMandatory: function subOperationIsHealthSafetyListMandatory(state) {
    return state.subOperationIsHealthSafetyListMandatory;
  },
  addSubOperationLoader: function addSubOperationLoader(state) {
    return state.addSubOperationLoader;
  },
  editSubOperationLoader: function editSubOperationLoader(state) {
    return state.editSubOperationLoader;
  },
  subOperationLoader: function subOperationLoader(state) {
    return state.subOperationLoader;
  },
  seedOperationsLoader: function seedOperationsLoader(state) {
    return state.seedOperationsLoader;
  },
  subOperationVehiclesDropdownLoader: function subOperationVehiclesDropdownLoader(state) {
    return state.subOperationVehiclesDropdownLoader;
  },
  subOperationImplementsDropdownLoader: function subOperationImplementsDropdownLoader(state) {
    return state.subOperationImplementsDropdownLoader;
  },
  subOperationProductsDropdownLoader: function subOperationProductsDropdownLoader(state) {
    return state.subOperationProductsDropdownLoader;
  },
  subOperationVehiclesListSize: function subOperationVehiclesListSize(state) {
    return state.subOperationVehiclesListSize;
  },
  subOperationImplementsListSize: function subOperationImplementsListSize(state) {
    return state.subOperationImplementsListSize;
  },
  subOperationProductsListSize: function subOperationProductsListSize(state) {
    return state.subOperationProductsListSize;
  },
  subOperationTaxWarningVisible: function subOperationTaxWarningVisible(state) {
    return state.subOperationTaxWarningVisible;
  }
};
export var mutations = {
  setOperationTypesList: function setOperationTypesList(state, data) {
    state.operationTypesList = [];
    data.map(function (data) {
      return state.operationTypesList.push(data);
    });
  },
  setManageOperationsList: function setManageOperationsList(state, data) {
    state.requestOperationTypesList = [];
    data.map(function (data) {
      return state.requestOperationTypesList.push({
        title: data.title,
        description: data.description,
        isSelected: data.isSelected,
        isActive: !data.isArchived,
        id: data.id,
        isSelectable: data.operations.length == 0,
        operations: data.operations
      });
    });
  },
  setOperationsList: function setOperationsList(state, data) {
    data.map(function (data) {
      return state.operationsList.push(data);
    });
  },
  setSubOperation: function setSubOperation(state, data) {
    state.selectedSubOperationId = data.id;
    state.subOperationTypeId = data.type.id;
    state.subOperationName = data.title;
    state.subOperationDescription = data.description;
    state.subOperationBillingCost = data.billingCost;
    state.subOperationBillingUnit = data.billingUnit;
    state.subOperationIsTaxable = data.isTaxable;
    state.subOperationTaxPercentage = data.taxRateInPercentage;
    state.subOperationIsHealthSafetyListMandatory = data.isHealthSafetyListMandatory;
    state.subOperationLoadInfoList = data.loadInformationList;
    state.subOperationCheckListItems = data.healthSafetyList;
    state.subOperationVehicles = data.vehicles.map(function (vehicle) {
      return vehicle.id;
    });
    state.subOperationVehiclesList = data.vehicles.map(function (vehicle) {
      return {
        id: vehicle.id,
        title: vehicle.title + " ( " + vehicle.make + ", " + vehicle.model + " )"
      };
    });
    state.subOperationImplements = data.implements.map(function (implement) {
      return implement.id;
    });
    state.subOperationImplementsList = data.implements.map(function (implement) {
      return {
        id: implement.id,
        title: implement.title + " ( " + implement.make + ", " + implement.model + " )"
      };
    });
    state.subOperationProducts = data.products.map(function (product) {
      return product.id;
    });
    state.subOperationProductsList = data.products.map(function (product) {
      return {
        id: product.id,
        title: product.title
      };
    });
  },
  setSubOperationVehiclesList: function setSubOperationVehiclesList(state, data) {
    data.map(function (vehicle) {
      state.subOperationVehiclesList.push({
        id: vehicle.id,
        title: vehicle.title + " ( " + vehicle.make + ", " + vehicle.model + " )"
      });
    });
  },
  setSubOperationImplementsList: function setSubOperationImplementsList(state, data) {
    data.map(function (implement) {
      state.subOperationImplementsList.push({
        id: implement.id,
        title: implement.title + " ( " + implement.make + ", " + implement.model + " )"
      });
    });
  },
  setSubOperationProductsList: function setSubOperationProductsList(state, data) {
    data.map(function (product) {
      state.subOperationProductsList.push({
        id: product.id,
        title: product.title
      });
    });
  },
  setClearOperationTypesList: function setClearOperationTypesList(state) {
    state.operationTypesList = [];
  },
  setClearOperationsList: function setClearOperationsList(state) {
    state.operationsList = [];
  },
  setClearAddOperationTypesList: function setClearAddOperationTypesList(state) {
    state.addOperationTypesList = [];
    state.showAddOperationFields = false;
    state.showAddOperationBtn = true;
  },
  setClearSubOperationDetails: function setClearSubOperationDetails(state) {
    state.selectedSubOperationId = null;
    state.selectedSubOperationName = null;
    state.showAddOperationFields = false;
    state.showAddLoadInfoFields = false;
    state.showAddCheckListItemFields = false;
    state.showAddOperationBtn = true;
    state.showAddCheckListItemBtn = true;
    state.showAddLoadInfoBtn = true;
    state.subOperationTypeId = '';
    state.subOperationName = '';
    state.subOperationBillingCost = '';
    state.subOperationBillingUnit = null;
    state.subOperationTaxPercentage = 0.00;
    state.subOperationIsTaxable = false;
    state.subOperationVehicles = [];
    state.subOperationImplements = [];
    state.subOperationProducts = [];
    state.subOperationLoadInfoList = [];
    state.subOperationDescription = '';
    state.subOperationCheckListItems = [];
    state.subOperationVehiclesSearchText = '';
    state.subOperationImplementsSearchText = '';
    state.subOperationProductsSearchText = '';
    state.subOperationVehiclesList = [];
    state.subOperationImplementsList = [];
    state.subOperationProductsList = [];
    state.subOperationIsHealthSafetyListMandatory = false;
    state.addSubOperationLoader = false;
    state.editSubOperationLoader = false;
    state.subOperationVehiclesDropdownLoader = false;
    state.subOperationImplementsDropdownLoader = false;
    state.subOperationProductsDropdownLoader = false;
    state.subOperationVehiclesListSize = 0;
    state.subOperationImplementsListSize = 0;
    state.subOperationProductsListSize = 0;
    state.subOperationTaxWarningVisible = false;
  }
};