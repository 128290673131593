import StringConstants from "@/plugins/stringConstants";
import { routeJobs, routeFields, routeOperations, routeTimesheets, routeTeamMembers, routeContractors, routeCustomers, routeVehicles, routeImplements, routeProducts, routeIntegrations, routeInvoices } from "@/utils/endpoints";
export default [{
  route: routeJobs,
  icon: require("../assets/icons-fill-jobs.svg"),
  title: StringConstants.jobsTitle
}, {
  route: routeFields,
  icon: require("../assets/icons-fill-field.svg"),
  title: StringConstants.fieldsTitle
}, {
  route: routeOperations,
  icon: require("../assets/icons-fill-operation.svg"),
  title: StringConstants.operationsTitle
}, {
  route: routeTimesheets,
  icon: require("../assets/icons-fill-timesheets.svg"),
  title: StringConstants.timesheetsTitle
}, {
  route: routeTeamMembers,
  icon: require("../assets/icons-fill-teams.svg"),
  title: StringConstants.teamMembersTitle
}, {
  route: routeContractors,
  icon: require("../assets/icons-fill-contractor-2.svg"),
  title: StringConstants.contractorsTitle,
  renderCondition: {
    primaryRole: "Farmer",
    secondaryRole: "Manager"
  }
}, {
  route: routeCustomers,
  icon: require("../assets/icons-fill-customers.svg"),
  title: StringConstants.customersTitle,
  renderCondition: {
    primaryRole: "Contractor",
    secondaryRole: "Manager"
  }
}, {
  route: routeVehicles,
  icon: require("../assets/icons-fill-vehicle-sm.svg"),
  title: StringConstants.vehiclesTitle
}, {
  route: routeImplements,
  icon: require("../assets/icons-fill-implements.svg"),
  title: StringConstants.implementsTitle
}, {
  route: routeProducts,
  icon: require("../assets/icons-fill-product.svg"),
  title: StringConstants.productsTitle
}, {
  route: routeIntegrations,
  icon: require("../assets/icons-fill-integrations.svg"),
  title: StringConstants.integrationsTitle,
  renderCondition: {
    primaryRole: "Contractor"
  }
}, {
  route: routeInvoices,
  icon: require("../assets/icons-fill-invoice-2.svg"),
  title: StringConstants.invoicesTitle,
  renderCondition: {
    primaryRole: "Contractor"
  }
}];