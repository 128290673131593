import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { wysiwygEditorConfig } from '@/utils/uiconstants';
import { VueEditor } from "vue2-editor";
export default {
  name: "TeamJobsInstructions",
  data: function data() {
    return {
      customToolbar: wysiwygEditorConfig
    };
  },
  watch: {
    tempTeamJobInstructions: {
      handler: function handler(newValue) {
        if (newValue && newValue !== this.teamJobInstructions) {
          var parsedValue = this.autoLinkUrls(newValue);
          this.tempTeamJobInstructions = parsedValue;
          this.teamJobInstructions = parsedValue;
        }
      },
      immediate: true
    }
  },
  mixins: [TeamJobsComputed, TeamJobsCommon],
  methods: {
    onChangeInstruction: function onChangeInstruction() {
      this.enableTeamJobDiscard();
    }
  },
  components: {
    VueEditor: VueEditor
  }
};