import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { Machine } from '@/data/models/Machine';
export var state = {
  machineListFiltered: new Array(),
  machineDetails: new Machine(),
  machineSearchText: '',
  machineAddCheckListItems: [],
  showAddmachineCheckListItemBtn: true,
  showAddmachineCheckListItemFields: false,
  addmachineLoader: false,
  editmachineLoader: false,
  machinesFilterType: 0
};
export var getters = {
  machineListFiltered: function machineListFiltered(state) {
    return state.machineListFiltered;
  },
  machineDetails: function machineDetails(state) {
    return state.machineDetails;
  },
  machineSearchText: function machineSearchText(state) {
    return state.machineSearchText;
  },
  vehicleListColumnTitles: function vehicleListColumnTitles(state) {
    return state.vehicleListColumnTitles;
  },
  implementListColumnTitles: function implementListColumnTitles(state) {
    return state.implementListColumnTitles;
  },
  machineAddCheckListItems: function machineAddCheckListItems(state) {
    return state.machineAddCheckListItems;
  },
  showAddmachineCheckListItemBtn: function showAddmachineCheckListItemBtn(state) {
    return state.showAddmachineCheckListItemBtn;
  },
  showAddmachineCheckListItemFields: function showAddmachineCheckListItemFields(state) {
    return state.showAddmachineCheckListItemFields;
  },
  addmachineLoader: function addmachineLoader(state) {
    return state.addmachineLoader;
  },
  editmachineLoader: function editmachineLoader(state) {
    return state.editmachineLoader;
  },
  machinesFilterType: function machinesFilterType(state) {
    return state.machinesFilterType;
  }
};
export var mutations = {
  setMachineListFiltered: function setMachineListFiltered(state, data) {
    data.forEach(function (x) {
      return state.machineListFiltered.push(new Machine().getVehicle(x));
    });
  },
  setMachineDetails: function setMachineDetails(state, data) {
    state.machineDetails = new Machine();
    state.machineDetails.getVehicle(data);
  },
  addMachine: function addMachine(state, data) {
    state.machineListFiltered.unshift(data);
  },
  updateMachine: function updateMachine(state, data) {
    var index = state.machineListFiltered.findIndex(function (element, index) {
      if (element.id == data.id) {
        return true;
      }
    });
    if (index >= 0) {
      state.machineListFiltered[index] = data;
    }
  },
  clearMachine: function clearMachine(state) {
    state.machineDetails = new Machine();
  },
  resetMachineDetails: function resetMachineDetails(state) {
    this.commit('clearMachine');
    state.machineAddCheckListItems = [];
    state.showAddmachineCheckListItemBtn = true;
    state.showAddmachineCheckListItemFields = false;
    state.addmachineLoader = false;
    state.editmachineLoader = false;
  },
  setClearMachinesList: function setClearMachinesList(state) {
    state.machineListFiltered = [];
  }
};