import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseFlyout",
    _vm._g(
      _vm._b(
        {
          ref: "flyoutRef",
          staticClass: "unscheduled-jobs-flyout",
          attrs: {
            value: _vm.value,
            width: "500",
            flyoutClass: "d-flex flex-column",
          },
          scopedSlots: _vm._u([
            {
              key: "flyout-header",
              fn: function () {
                return [
                  _c("HeaderPrimary", {
                    attrs: { textValue: "Unscheduled Jobs" },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "flyout-bottom",
              fn: function () {
                return [
                  _c(VDivider, { attrs: { light: "" } }),
                  _c(
                    "div",
                    { staticClass: "w-100 px-8 py-3" },
                    [
                      _c("Button", {
                        attrs: {
                          variant: "primary",
                          color: "primary",
                          rightIcon: "mdi-arrow-right",
                          textValue: _vm.$stringConstants(
                            "submitUnscheduledJobs"
                          ),
                          loading: _vm.isSubmitInProgress,
                          disabled: _vm.isSubmitInProgress,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSubmit($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "BaseFlyout",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(VTextField, {
        staticClass: "rd-rt-no agd-txtfield mx-8 my-5 flex-grow-0",
        attrs: {
          placeholder: _vm.$stringConstants("searchJobs"),
          dense: "",
          outlined: "",
          clearable: "",
          "prepend-inner-icon": "mdi-magnify",
          "hide-details": "",
        },
        on: {
          input: function ($event) {
            return _vm.applySearchTimer(_vm.handleSearchForJobs)
          },
        },
        model: {
          value: _vm.jobsUnscheduledJobsSearchText,
          callback: function ($$v) {
            _vm.jobsUnscheduledJobsSearchText = $$v
          },
          expression: "jobsUnscheduledJobsSearchText",
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.jobsUnscheduledJobsData.length > 0,
              expression: "jobsUnscheduledJobsData.length > 0",
            },
          ],
          staticClass: "d-inline-flex px-8 align-center",
        },
        [
          _c(VCheckbox, {
            staticClass: "mr-1",
            attrs: {
              indeterminate: _vm.someUnscheduledJobsSelected,
              "input-value": _vm.allUnscheduledJobsSelected,
              disabled:
                _vm.isListLoading || !_vm.jobsUnscheduledJobsData.length,
            },
            on: { change: _vm.selectAllUnscheduledJobs },
            model: {
              value: _vm.isChecked,
              callback: function ($$v) {
                _vm.isChecked = $$v
              },
              expression: "isChecked",
            },
          }),
          _c("span", [_vm._v(_vm._s(_vm.$stringConstants("selectAll")))]),
        ],
        1
      ),
      _c(VDivider, {
        staticClass: "lt-vw",
        staticStyle: { "border-top": "none" },
      }),
      _vm.isListLoading
        ? _c(
            "div",
            { staticClass: "d-flex flex-column" },
            _vm._l(10, function (i) {
              return _c(
                "div",
                { key: i, staticClass: "d-flex mx-8 my-5" },
                [
                  _c(VSimpleCheckbox, {
                    staticClass: "mr-1",
                    attrs: { disabled: "", width: "16" },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column flex-grow-1" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { type: "text", width: "250", height: "20" },
                      }),
                      _c(VSkeletonLoader, {
                        attrs: { type: "text", width: "125", height: "10" },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex mt-4" },
                        [
                          _c(VSkeletonLoader, {
                            staticClass: "avatar",
                            attrs: {
                              type: "avatar",
                              width: "36",
                              height: "36",
                            },
                          }),
                          _c(VSkeletonLoader, {
                            staticClass: "avatar",
                            attrs: {
                              type: "avatar",
                              width: "36",
                              height: "36",
                            },
                          }),
                          _c(VSkeletonLoader, {
                            staticClass: "avatar",
                            attrs: {
                              type: "avatar",
                              width: "36",
                              height: "36",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(VSkeletonLoader, {
                    staticClass: "ml-auto",
                    attrs: { type: "button", width: "100", height: "36" },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _c("List", {
            staticClass: "flex-grow-1",
            attrs: {
              "no-header": "",
              type: "assignment",
              listItems: _vm.jobsUnscheduledJobsData,
              "module-namespace": _vm.$stringConstants(
                "unscheduledJobsListModule"
              ),
            },
            on: {
              setFilterOptionsAndGetListData: _vm.setFilterOptionsAndGetJobs,
            },
            scopedSlots: _vm._u([
              {
                key: "list-item",
                fn: function (ref) {
                  var listItem = ref.listItem
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center my-5 mx-8 w-100",
                        attrs: { id: listItem.id },
                      },
                      [
                        _c(VCheckbox, {
                          staticClass: "mr-1 mt-0",
                          attrs: {
                            value: listItem.id,
                            multiple: "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.selectedItems,
                            callback: function ($$v) {
                              _vm.selectedItems = $$v
                            },
                            expression: "selectedItems",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "job-details-link no-decoration black--text event-title fn-lg font-weight-bold mb-1",
                                attrs: {
                                  to: _vm.routeTeamJobsDetails + listItem.id,
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s(listItem.operation.title))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "event-number fn-xsm tertiary-darken4--text",
                              },
                              [_vm._v(_vm._s(listItem.number))]
                            ),
                            listItem.operators.length
                              ? _c(
                                  VTooltip,
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      bottom: "",
                                      "nudge-right": "-28",
                                      "content-class":
                                        "black rounded-0 px-4 pb-2",
                                      transition: "scroll-y-transition",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  {
                                                    staticClass: "d-flex mt-3",
                                                  },
                                                  on
                                                ),
                                                [
                                                  listItem.operators.length ===
                                                  3
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          listItem.operators,
                                                          function (
                                                            operator,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "Avatar",
                                                              {
                                                                key: index,
                                                                class:
                                                                  "job-operator " +
                                                                  (listItem
                                                                    .operators
                                                                    .length ===
                                                                  1
                                                                    ? "no-translate"
                                                                    : ""),
                                                                attrs: {
                                                                  width: "36px",
                                                                  height:
                                                                    "36px",
                                                                  isCircular:
                                                                    "false",
                                                                  contentClass:
                                                                    "fw-7",
                                                                  initials:
                                                                    operator.name,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        [
                                                          _vm._l(
                                                            listItem.operators.slice(
                                                              0,
                                                              2
                                                            ),
                                                            function (
                                                              operator,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "Avatar",
                                                                {
                                                                  key: index,
                                                                  class:
                                                                    "job-operator " +
                                                                    (listItem
                                                                      .operators
                                                                      .length ===
                                                                    1
                                                                      ? "no-translate"
                                                                      : ""),
                                                                  attrs: {
                                                                    width:
                                                                      "36px",
                                                                    height:
                                                                      "36px",
                                                                    isCircular:
                                                                      "false",
                                                                    contentClass:
                                                                      "fw-7",
                                                                    initials:
                                                                      operator.name,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          listItem.operators
                                                            .length > 2
                                                            ? _c(
                                                                "Avatar",
                                                                {
                                                                  staticClass:
                                                                    "job-operator remaining-operator--count",
                                                                  attrs: {
                                                                    width:
                                                                      "36px",
                                                                    height:
                                                                      "36px",
                                                                    isCircular:
                                                                      "false",
                                                                    contentClass:
                                                                      "fw-7",
                                                                    initials:
                                                                      listItem
                                                                        .operators[
                                                                        listItem
                                                                          .operators
                                                                          .length -
                                                                          1
                                                                      ].name,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " +" +
                                                                      _vm._s(
                                                                        listItem
                                                                          .operators
                                                                          .length -
                                                                          2
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      _vm._l(
                                        listItem.operators,
                                        function (operator, index) {
                                          return _c(
                                            "span",
                                            { key: index, staticClass: "py-1" },
                                            [_vm._v(_vm._s(operator.name))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "datepicker-trigger position-relative ml-auto",
                            attrs: { "data-id": listItem.id },
                          },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass:
                                  "fn-smm schedule-text primary--text text-none font-weight-regular px-2",
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDatepicker(
                                      $event,
                                      listItem.scheduledAt
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "20" },
                                  },
                                  [_vm._v("mdi-calendar")]
                                ),
                                listItem.scheduledAt
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getFormattedScheduledDate(
                                            listItem.scheduledAt
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$stringConstants("scheduleJobs")
                                        )
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "zero-state",
                fn: function () {
                  return [_vm._v(" No jobs found! ")]
                },
                proxy: true,
              },
              {
                key: "empty-search-state",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$stringConstants("errorForWrongSearch")) +
                        " "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
      _c("FlyoutSnackbar", {
        staticClass: "ma-0 white-text",
        attrs: {
          width: "500",
          height: "72",
          bottom: "",
          "content-class": "px-7",
          deleteApiType: "jobs",
          status: "unscheduledJobsStatus",
          allItemsSelected: _vm.allUnscheduledJobsSelected,
          someItemsSelected: _vm.someUnscheduledJobsSelected,
          label: _vm.selectedItemsLabel,
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex datepicker-trigger",
                    attrs: { id: "snackbar", "data-id": "snackbar" },
                  },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "font-weight-regular mr-2",
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.openDatepicker($event)
                          },
                        },
                      },
                      [
                        _c(VIcon, [_vm._v("mdi-calendar")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("scheduleJobs")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.showDatepicker
        ? _c("UnscheduledJobsDatepicker", {
            ref: "datepickerRef",
            attrs: {
              position: _vm.datepickerPosition,
              value: _vm.datepickerValue,
            },
            on: {
              cancel: _vm.closeDatepicker,
              update: _vm.handleScheduleDateChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }