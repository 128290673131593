import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FieldsComputedMixin from '@/components/fields/fields-computed';
import { mapActions } from 'vuex';
export default {
  mixins: [FieldsComputedMixin],
  methods: _objectSpread(_objectSpread({}, mapActions({
    locateFieldAsync: 'locateFieldReadOnly'
  })), {}, {
    searchFieldNames: function searchFieldNames() {
      var _this = this;
      this.fieldSearchResultsReadOnly = [];
      if (this.fieldSearchTextReadOnly == null || this.fieldSearchTextReadOnly != null && this.fieldSearchTextReadOnly.length == 0) {
        this.fieldSearchResultsReadOnly = [];
      } else {
        if (this.fieldsListForSearchReadOnly && this.fieldsListForSearchReadOnly.length > 0) {
          this.fieldsListForSearchReadOnly.map(function (field) {
            if (field.title && field.title.toLowerCase().includes(_this.fieldSearchTextReadOnly.toLowerCase())) {
              _this.fieldSearchResultsReadOnly.push(field);
            }
          });
        }
      }
    },
    searchFields: function searchFields() {
      if (this.fieldSearchTextReadOnly) {
        this.locateFieldAsync();
      }
    },
    navigateToField: function navigateToField(fieldId) {
      if (fieldId) {
        this.$store.dispatch('navigateToField', fieldId);
      }
    }
  })
};