import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import $ from "jquery";
import { clearDateTimePicker, openOverlay } from "@/utils/helpers";
import { defaultScrollViewHeight } from "@/utils/uiconstants";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import { routeInvoicesAddExternal } from "@/utils/endpoints";
import router from "@/router/index";
import moment from "moment";
import invoicesExternalComputedMixin from "@/components/invoices-external/invoices-external-computed";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";
import List from "@/components/shared/list.vue";
import ConstantValues from '@/plugins/constantValues';
import StringConstants from '@/plugins/stringConstants';
import { BaseButton as Button } from "@/components/shared/buttons/index";
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import { defaultDateFormat } from "@/utils/constants";
export default {
  name: "InvoicesListViewExternal",
  mixins: [permissionsMixin, scrollerMixin, invoicesExternalComputedMixin, requestsCancellationMixin],
  components: {
    DatePicker: DatePicker,
    List: List,
    Button: Button
  },
  data: function data() {
    return {
      defaultScrollViewHeight: defaultScrollViewHeight,
      dateInput: {
        placeholder: StringConstants.selectDatePlaceholder
      },
      format: defaultDateFormat,
      sameDateFormat: {
        from: defaultDateFormat,
        to: defaultDateFormat
      },
      showExternalInvoicingFilters: false
    };
  },
  beforeDestroy: function beforeDestroy() {
    this.clearAndResetInvoicesPagingStates();
  },
  mounted: function mounted() {
    this.fetchingLineItems = false;
    this.totalExternalInvoicesCount = 0;
    this.invoicesListExternal = [];
    this.customersFilterListForInvoicesList = [];
    this.invoiceJobsPreviewLoader = false;
    this.clearInvoiceFilterOptions();
    this.$store.dispatch('getCustomersListForInvoicesFilter');
    this.setFilterOptionsAndGetInvoices();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearInvoiceDetailsState: "setClearInvoiceDataExternal",
    clearInvoiceFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    openAddInvoice: function openAddInvoice(integrationType) {
      switch (integrationType) {
        case "xero":
          this.integrationType = "xero";
          break;
        case "quickbooks":
          this.integrationType = "quickbooks";
          break;
        case "sage":
          this.integrationType = "sage";
          break;
      }
      this.fetchingLineItems = false;
      this.externalContactRadioGroup = 1;
      this.clearInvoiceDetailsState();
      this.invoiceDraftPreviewDetailsExternalLineItems = [];
      this.$store.state.jobsInvoicesModule.map = null;
      router.push(routeInvoicesAddExternal);
    },
    filterInvoicesByDateRange: function filterInvoicesByDateRange(start, end) {
      this.invoiceDateFilterApplied = true;
      this.clearAndResetInvoicesPagingStates();
      this.setFilterOptionsAndGetInvoices();
    },
    clearInvoicesDateFilter: function clearInvoicesDateFilter() {
      $(".vdpr-datepicker input[type=text]").first().val("");
      clearDateTimePicker(this.$refs.datePicker.$el);
      this.invoiceDateFilterApplied = false;
      this.clearAndResetInvoicesPagingStates();
      this.setFilterOptionsAndGetInvoices();
    },
    onInvoicesSearchClearClicked: function onInvoicesSearchClearClicked() {
      this.clearAndResetInvoicesPagingStates();
      this.$store.dispatch("getInvoicesListExternal", this.integrationType);
    },
    applySearchExternalInvoicesFilter: function applySearchExternalInvoicesFilter() {
      this.clearAndResetInvoicesPagingStates();
      this.setFilterOptionsAndGetInvoices();
    },
    clearAndResetInvoicesPagingStates: function clearAndResetInvoicesPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.invoicesExternalModule.invoicesListExternal = [];
      this.clearInvoiceDetailsState();
      this.clearInvoiceFilterOptions();
      this.resetPagingForModule();
    },
    getLocalFormat: function getLocalFormat(date) {
      var formattedDate = moment.utc(date).local().format("DD MMM YYYY");
      return formattedDate;
    },
    openInvoiceDetails: function openInvoiceDetails(invoice) {
      window.open(invoice === null || invoice === void 0 ? void 0 : invoice.url, "_blank");
    },
    goToQuickbooks: function goToQuickbooks() {
      window.open(ConstantValues.qbInvoiceTarget, "_blank").focus();
    },
    goToXero: function goToXero() {
      window.open(ConstantValues.xeroInvoiceTarget, "_blank").focus();
    },
    goToSage: function goToSage() {
      window.open(ConstantValues.sageInvoiceTarget, "_blank").focus();
    },
    openJobsPreview: function openJobsPreview(jobIds) {
      openOverlay("invoiceJobsPreviewFlyout");
      this.$store.dispatch("getJobsListForPreview", jobIds.join());
    },
    resetPagingForModule: function resetPagingForModule() {
      var $state = this.$store.state.invoicesExternalModule;
      $state.offset = ConstantValues.defaultPageOffsetSize;
      $state.limit = ConstantValues.defaultPageLimitSize;
      $state.listCount = ConstantValues.defaultPageListCount;
      $state.noData = false;
      this.resetPaging();
    },
    searchCustomersListFilter: function searchCustomersListFilter() {
      this.$store.dispatch('getCustomersListForInvoicesFilter');
    },
    filterInvoicesListByCustomer: function filterInvoicesListByCustomer() {
      alert('to do filter');
    },
    setFilterOptionsAndGetInvoices: function setFilterOptionsAndGetInvoices() {
      var _this = this;
      var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      this.$store.dispatch("fnSetInvoicesFilterExternal", filters).then(function (res) {
        _this.$store.dispatch("getInvoicesListExternal", _this.integrationType);
      });
    }
  })
};