import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import { apiVehicles, apiImplements, apiProducts, apiFields, apiTimeSheets, apiFarmers, apiTeamMembers, apiContractors } from '@/utils/endpoints';
import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
import { MachineType } from '@/enum/machineType';
import { listAction } from '@/enum/listAction';
import { mapMutations } from 'vuex';
import ConstantValues from '@/plugins/constantValues';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
export default {
  name: 'ActionListingDialog',
  props: ['itemName', 'onClickEvent', 'actionType', 'itemsPreference'],
  data: function data() {
    return {
      listAction: listAction
    };
  },
  components: {
    SecondaryButton: SecondaryButton
  },
  computed: {
    allItemsDeleted: {
      get: function get() {
        return this.$store.getters.allItemsDeleted;
      },
      set: function set(newVal) {
        this.$store.state.allItemsDeleted = newVal;
      }
    },
    allItemsRestored: {
      get: function get() {
        return this.$store.getters.allItemsRestored;
      },
      set: function set(newVal) {
        this.$store.state.allItemsRestored = newVal;
      }
    },
    allItemsArchived: {
      get: function get() {
        return this.$store.getters.allItemsArchived;
      },
      set: function set(newVal) {
        this.$store.state.allItemsArchived = newVal;
      }
    },
    selectedItems: {
      get: function get() {
        return this.$store.getters.selectedItems;
      },
      set: function set(newVal) {
        this.$store.state.selectedItems = newVal;
      }
    },
    listActionType: {
      get: function get() {
        return this.$store.getters.listActionType;
      },
      set: function set(newVal) {
        this.$store.state.listActionType = newVal;
      }
    },
    totalRowCount: {
      get: function get() {
        return this.$store.getters.totalRowCount;
      },
      set: function set(newVal) {
        this.$store.state.totalRowCount = newVal;
      }
    },
    dialogSelectedRows: {
      get: function get() {
        return this.$store.getters.dialogSelectedRows;
      },
      set: function set(val) {
        this.$store.state.dialogSelectedRows = val;
      }
    },
    loginUserRole: {
      get: function get() {
        return this.$store.getters.loginUserRole;
      }
    },
    fieldsListMapData: {
      get: function get() {
        return this.$store.getters.fieldsListMapData;
      },
      set: function set(val) {
        this.$store.state.fieldsModule.fieldsListMapData = val;
      }
    },
    fieldListOwnerId: function fieldListOwnerId() {
      return this.$store.state.fieldsModule.fieldListOwnerId;
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFilterOption: 'setClearFilterOptions',
    resetPaging: 'resetPaging',
    resetSnackbarDetails: 'clearSnackbarDetails'
  })), {}, {
    clearAndResetPagingStates: function clearAndResetPagingStates() {
      this.clearFilterOption();
      this.resetPaging();
      this.resetSnackbarDetails();
    },
    fnAgreeTite: function fnAgreeTite(actionType) {
      switch (actionType) {
        case listAction.Delete:
          return this.$stringConstants('deletePromtAgree');
        case listAction.Restore:
          return this.$stringConstants('restorePromptAgree');
        case listAction.Archive:
          return this.$stringConstants('archivePromptAgree');
      }
    },
    fnDisagreeTite: function fnDisagreeTite(actionType) {
      switch (actionType) {
        case listAction.Delete:
          return this.$stringConstants('deletePromtDisagree');
        case listAction.Restore:
          return this.$stringConstants('restorePromptDisagree');
        case listAction.Archive:
          return this.$stringConstants('archivePromptDisagree');
      }
    },
    proccedToAction: function proccedToAction(listaction) {
      var _this = this;
      this.dialogSelectedRows = false;
      this.clearFilterOption();
      var state = this.$store;
      this.selectedItems.map(function (item) {
        state.commit("setFilterOptions", [ConstantValues.queryIdentifiers, item]);
      });
      state.commit("setFilterOptions", ['Preference', this.itemsPreference]);
      var path = localStorage.getItem(ConstantValues.ownerId);
      switch (this.onClickEvent) {
        case 'proccedToActionOnMachines':
          {
            if (this.$store.state.machineType == MachineType.Implement) {
              path = path + apiImplements;
            } else if (this.$store.state.machineType == MachineType.Vehicle) {
              path = path + apiVehicles;
            }
            this.$store.dispatch("actionOnMultilselectItems", [listaction, path, this.itemsPreference]).then(function () {
              _this.$store.state.machinesModule.machineListFiltered = [];
              _this.clearAndResetPagingStates();
              _this.$store.dispatch("fnSetMachineFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function () {
                _this.$store.dispatch("getMachineListFiltered");
              });
            });
            break;
          }
        case 'proccedToActionOnProducts':
          {
            this.$store.dispatch("actionOnMultilselectItems", [listaction, path + apiProducts, this.itemsPreference]).then(function () {
              _this.$store.state.productsModule.productsList = [];
              _this.clearAndResetPagingStates();
              _this.$store.dispatch("setProductsListFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function () {
                _this.$store.dispatch("getProductsListFiltered");
              });
            });
            break;
          }
        case 'proccedToActionOnFields':
          {
            var ownerId;
            if (this.checkIfUserIsFarmerOrFarmerManager()) {
              path = localStorage.getItem(ConstantValues.ownerId);
              ownerId = localStorage.getItem(ConstantValues.ownerId);
            } else {
              path = localStorage.getItem('fieldOwnerId');
              ownerId = localStorage.getItem('fieldOwnerId');
            }
            if (this.allItemsDeleted || this.allItemsRestored || this.allItemsArchived) {
              this.$store.dispatch('removeFieldsFromMapByIds', [this.fieldsListMapData.filter(function (field) {
                if (_this.checkIfUserIsFarmerOrFarmerManager()) {
                  return field.owner.id === ownerId;
                } else {
                  return field.owner.id === _this.fieldListOwnerId;
                }
              }).map(function (field) {
                return field.id;
              }), true]);
            } else {
              if (this.selectedItems.length) {
                this.$store.dispatch('removeFieldsFromMapByIds', [this.selectedItems], false);
              }
            }
            this.$store.dispatch("actionOnMultilselectItems", [listaction, path + apiFields, this.itemsPreference]).then(function () {
              _this.$store.state.fieldsModule.fieldFilterList = [];
              _this.clearAndResetPagingStates();
              _this.$store.dispatch("setFieldFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function () {
                _this.$store.dispatch("getFieldsListFiltered");
              });
            });
            break;
          }
        case 'proccedToActionOnTimesheets':
          {
            this.$store.dispatch("actionOnMultilselectItems", [listaction, path + apiTimeSheets, this.itemsPreference]).then(function () {
              _this.$store.state.timeSheetsModule.timesheetsList = [];
              _this.clearAndResetPagingStates();
              _this.$store.dispatch("setTimesheetsFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function () {
                _this.$store.dispatch("getTimesheetsList").then(function () {
                  _this.$store.dispatch('getTimesheetsStatistics');
                });
              });
            });
            break;
          }
        case 'proceedToActionOnTeamMembers':
          {
            this.$store.dispatch("actionOnMultilselectItems", [listaction, 'users/' + path + '/' + apiTeamMembers, this.itemsPreference]).then(function () {
              _this.$store.state.teamMembersModule.teamMembersList = [];
              _this.clearAndResetPagingStates();
              _this.$store.dispatch("fnSetTeamMembersFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function () {
                _this.$store.dispatch("getTeamMembers");
              });
            });
            break;
          }
        case 'proceedToActionOnFarmers':
          {
            if (listaction == listAction.Delete) {
              listaction = listAction.Archive;
            }
            this.$store.dispatch("actionOnMultilselectItems", [listaction, 'users/' + path + apiFarmers, this.itemsPreference]).then(function () {
              _this.$store.state.farmersModule.farmersFilterList = [];
              _this.clearAndResetPagingStates();
              _this.$store.dispatch("setFarmersFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function () {
                _this.$store.dispatch("getFarmersFiltered");
              });
            });
            break;
          }
        case 'proceedToActionOnContractors':
          {
            listaction = listAction.Delete;
            this.$store.dispatch("actionOnMultilselectItems", [listaction, 'users/' + path + apiContractors, this.itemsPreference]).then(function () {
              _this.$store.state.contractorsModule.contractorFilterList = [];
              _this.clearAndResetPagingStates();
              _this.$store.dispatch("setContractorFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function () {
                _this.$store.dispatch("getContractorFiltered");
              });
            });
            break;
          }
        default:
          break;
      }
      return;
    },
    checkIfUserIsFarmerOrFarmerManager: function checkIfUserIsFarmerOrFarmerManager() {
      return getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn;
    }
  })
};