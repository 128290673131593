import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'invoicesModule': ['invoiceDraftPreviewDetails', 'invoiceJobsPreviewLoader'],
  'invoicesExternalModule': ['invoiceLoaderExternal', 'jobsListForInvoiceExternal', 'noExternalListData', 'selectedJobsForInvoiceExternal', 'jobsForInvoiceSearchTextExternal', 'selectedCustomerIdForInvoiceExternal', 'customersListForInvoiceExternal', 'customersSearchTextExternal', 'invoiceDraftPreviewDetailsExternal', 'anyLineItemInvalid', 'dueDateForInvoiceExternal', 'invoiceSelectedTabExternal', 'draftedInvoiceIdToOpenExternal', 'invoiceDateExternal', 'invoiceGenerationInProgressExternal', 'invoiceSendExternal', 'invoiceExternalLineItems', 'invoiceLineItemJobNumbersDistinctExternal', 'invoiceLineItemsJobIdsExternal', 'invoiceContactCompanyName', 'invoiceExternalLineItemSearchText', 'invoicesListExternal', 'enableExternalInvoicingExternal', 'totalExternalInvoicesCount', 'invoicesExternalListCount', 'invoicesExternalShowAddLineItemView', 'invoicesExternalShowAddLineItemButton', 'invoicesExternalInitialLineItem', 'invoiceDraftPreviewDetailsExternalLineItems', 'fetchingLineItems', 'externalContactRadioGroup', 'invoiceSearchTextExternal', 'invoiceDateFilterApplied', 'tempJobId', 'customerForInvoicesList', 'customersFilterListForInvoicesList', 'customersFilterSearchText', 'customersListForInvoiceExternalSize', 'customersListForExternalInvoiceLoader', 'invoiceStatusEnum', 'supplierDetailsForExternalInvoice', 'customerDetailsForExternalInvoice'],
  'jobsInvoicesModule': ['invoiceJobReportUrls', 'invoiceReportGenerationInProgress', 'jobOperatorIds', 'jobOperatorLocations', 'invoiceReportUrlGenerationInProgress', 'generatedFromJobDetails'],
  'integrationsModule': ['xeroAccessState', 'xeroAccess', 'quickBookAccess', 'quickbookAccessState', 'sageAccessState', 'sageAccess', 'integrationType', 'linkedContactToExternalService', 'contacts', 'contactSearchText', 'loadedContacts', 'intergrationHasData', 'fetchingintergrationDataProgress', 'customerLoader', 'externalContactIdToLink', 'allCustomersLoaded', 'loadingIntegration', 'tempIntegratedContacts'],
  'root': ['noData', 'filtersApplied', 'maxCompanyNameLength', 'listCount']
};
export default {
  name: 'InvoicesExternalComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    getSenderId: function getSenderId() {
      return localStorage.getItem(ConstantValues.ownerId);
    },
    currencySymbol: function currencySymbol() {
      return localStorage.getItem("currencySymbol");
    }
  }),
  methods: {
    isPropertyValid: function isPropertyValid(property) {
      if (property) {
        if (property.id == null || property.id == "" || property.id == undefined || property.id.length == 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    checkLineItemsValidity: function checkLineItemsValidity() {
      var _this = this;
      var isAnyLineItemInvalid = false;
      if (this.invoiceDraftPreviewDetailsExternalLineItems.length) {
        this.invoiceDraftPreviewDetailsExternalLineItems.map(function (item) {
          if (item.item.id == null) {
            isAnyLineItemInvalid = true;
          }
          if (!_this.isPropertyValid(item.item.chartOfAccount)) {
            isAnyLineItemInvalid = true;
          }
          if (!_this.isPropertyValid(item.item.taxRate)) {
            isAnyLineItemInvalid = true;
          }
        });
      }
      if (this.invoiceDraftPreviewDetailsExternalLineItems.length === 0) isAnyLineItemInvalid = true;
      this.anyLineItemInvalid = isAnyLineItemInvalid;
    }
  }
};