import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export var state = {
  usersList: [],
  usersListStartDate: null,
  usersListEndDate: null,
  usersListRoles: null,
  usersFiltersApplied: false,
  usersSearchText: null
};
export var getters = {
  usersList: function usersList(state) {
    return state.usersList;
  },
  usersListStartDate: function usersListStartDate(state) {
    return state.usersListStartDate;
  },
  usersListEndDate: function usersListEndDate(state) {
    return state.usersListEndDate;
  },
  usersFiltersApplied: function usersFiltersApplied(state) {
    return state.usersFiltersApplied;
  },
  usersListRoles: function usersListRoles(state) {
    return state.usersListRoles;
  },
  usersSearchText: function usersSearchText(state) {
    return state.usersSearchText;
  }
};
export var mutations = {
  setSuUsersList: function setSuUsersList(state, data) {
    data === null || data === void 0 ? void 0 : data.value.forEach(function (user) {
      return state.usersList.push(user);
    });
  },
  resetUsersListFilter: function resetUsersListFilter(state) {
    state.usersListStartDate = null;
    state.usersListEndDate = null;
    state.usersFiltersApplied = false;
    state.usersSearchText = null;
  }
};