import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import { mapMutations } from "vuex";
import moment from "moment";
import invoicesComputedMixin from "@/components/invoices/invoices-computed";
import { getAssignedUserNameFromJob } from "@/utils/helpers/jobs-helpers";
import LoadMoreItem from "@/components/shared/load-more-item.vue";
import { getListLengthAbsolute } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
export default {
  name: "JobsListForInvoice",
  computed: {
    currentInvoiceId: {
      get: function get() {
        var invoiceId = window.location.pathname.split("/").pop();
        return invoiceId !== "add" ? invoiceId : null;
      }
    }
  },
  data: function data() {
    return {
      radioGroup: 1,
      tempCustomerId: ""
    };
  },
  components: {
    ListLoader: ListLoader,
    LoadMoreItem: LoadMoreItem
  },
  mixins: [invoicesComputedMixin],
  watch: {
    selectedCustomerIdForInvoice: function selectedCustomerIdForInvoice(newVal) {
      if (newVal == null) {
        this.tempCustomerId = "";
        this.selectedJobsForInvoice = [];
        this.tempJobId = "";
      } else {
        this.getJobsFromCustomer(newVal);
      }
    }
  },
  mounted: function mounted() {
    if (!this.currentInvoiceId) {
      this.$store.dispatch("getJobsListForInvoice");
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearCustomersFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    updateSelectedJobs: function updateSelectedJobs(selectedJobs) {
      var newVal = selectedJobs[0];
      if (newVal && newVal.length != 0 && newVal.customer.id != this.getSenderId && this.tempCustomerId != newVal.customer.id && this.selectedCustomerIdForInvoice == null) {
        var jobOwnerId = this.tempCustomerId = newVal.customer.id;
        if (jobOwnerId) {
          this.tempJobId = selectedJobs[0].id;
          this.$store.dispatch("getJobsListForInvoice", jobOwnerId);
          this.$store.dispatch("getCustomerDetailsForInvoice", jobOwnerId);
          var customer = newVal.customer;
          if (customer.businessName) {
            customer["displayName"] = customer.businessName;
          } else {
            customer["displayName"] = customer.name;
          }
          this.customersListForInvoice.push(customer);
          this.selectedCustomerIdForInvoice = jobOwnerId;
        }
      }
    },
    clearSelectedJobs: function clearSelectedJobs() {
      this.invoiceLoader = true;
      this.selectedJobsForInvoice = [];
      this.invoiceLoader = false;
    },
    getJobsListFromCustomerId: function getJobsListFromCustomerId() {
      var _this = this;
      if (this.selectedCustomerIdForInvoice) {
        this.$store.dispatch("getJobsListForInvoice", this.selectedCustomerIdForInvoice).then(function (res) {
          _this.clearSelectedJobs();
        });
      } else {
        this.$store.dispatch("getJobsListForInvoice").then(function (res) {
          _this.clearSelectedJobs();
        });
      }
    },
    applySearchCustomersFilter: function applySearchCustomersFilter() {
      var _this2 = this;
      if (this.searchTimerCustomers) {
        clearTimeout(this.searchTimerCustomers);
        this.searchTimerCustomers = null;
      }
      this.searchTimerCustomers = setTimeout(function () {
        _this2.applySearchFilters();
      }, ConstantValues.defaultDropdownSearchTimeout);
    },
    applySearchFilters: function applySearchFilters() {
      var _this3 = this;
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.$store.dispatch("setCustomersFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(function (res) {
        _this3.$store.dispatch("getCustomersListForInvoice");
        if (_this3.customersSearchText == "") {
          _this3.clearCustomersFilterOption();
        }
      });
    },
    getJobsFromCustomer: function getJobsFromCustomer(customerIdForJobs) {
      var _this4 = this;
      if (customerIdForJobs) {
        this.$store.dispatch("getJobsListForInvoice", customerIdForJobs).then(function (res) {
          _this4.clearSelectedJobs();
          if (_this4.jobsListForInvoice.length) {
            _this4.selectedJobsForInvoice = [];
            var previousSelectedJob = _this4.jobsListForInvoice.find(function (job) {
              return job.id === _this4.tempJobId;
            });
            if (previousSelectedJob) {
              _this4.selectedJobsForInvoice.push(previousSelectedJob);
            }
          }
        });
        this.$store.dispatch("getCustomerDetailsForInvoice", customerIdForJobs);
      }
    },
    getDateTimeFormat: function getDateTimeFormat(date) {
      return date ? moment.utc(date).local().format("DD MMM YYYY") : "-";
    },
    applySearchJobsListFilter: function applySearchJobsListFilter() {
      var _this5 = this;
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.jobsListForInvoice = [];
      this.$store.dispatch("setJobsForInvoiceFilter").then(function (res) {
        _this5.$store.dispatch("getJobsListForInvoice", _this5.selectedCustomerIdForInvoice).then(function (rs) {
          _this5.invoiceLoader = false;
        });
        if (_this5.selectedCustomerIdForInvoice) {
          _this5.$store.dispatch("getCustomerDetailsForInvoice", _this5.selectedCustomerIdForInvoice);
        }
        if (_this5.jobsForInvoiceSearchText == "") {
          _this5.clearCustomersFilterOption();
        }
      });
    },
    getAssignedUserName: function getAssignedUserName(job) {
      return getAssignedUserNameFromJob(job);
    },
    getJobNumberPrefix: function getJobNumberPrefix(jobNumber) {
      if (jobNumber.split("#").length < 2) {
        return "#" + jobNumber;
      }
      return jobNumber.split("#")[0];
    },
    getJobNumberSuffix: function getJobNumberSuffix(jobNumber) {
      if (jobNumber.split("#").length == 2) {
        return "#" + jobNumber.split("#")[1];
      }
    },
    appendMoreCustomers: function appendMoreCustomers() {
      this.customersSearchText = '';
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.$store.commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
      this.$store.commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(this.customersListForInvoice)]);
      this.$store.dispatch("getCustomersListForInvoice");
      if (this.customersSearchText == '') {
        this.clearCustomersFilterOption();
      }
    }
  })
};